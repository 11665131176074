import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, message, Row, Table, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { useBoards, useDeleteBoards } from 'core/query/community';
import { theme } from 'styles/theme';
import { boardCategoryMap, boardStatusMap, itemTypeMap } from 'shared/consts';
import {
  EBoardStatus,
  EDeleteItemType,
  IEntireBoardOrCommentHistoryState,
} from 'type/board';
import useShowMemberDetail from 'util/hooks/useShowMemberDetail';
import useShowReports from 'util/hooks/useShowReports';
import history from 'util/history';
import useDeleteItems from 'util/hooks/useDeleteItems';
import { EReportKind } from 'type/common';

const Container = styled.div`
  table tr.deleted {
    background-color: ${theme.colors.GRAY80};

    .deleted-board-title {
      text-decoration: line-through;
    }
  }
`;

const EntireBoardList = () => {
  const historyState = history.location
    .state as IEntireBoardOrCommentHistoryState | null;

  const [page, setPage] = useState(historyState?.boardListPage || 1);
  const [selectedBoardIds, setSelectedBoardIds] = useState<number[]>([]);
  const { boards, total } = useBoards({ page });
  const { deleteBoards } = useDeleteBoards();
  const showMemberDetail = useShowMemberDetail();
  const showReports = useShowReports();
  const deleteItems = useDeleteItems();

  useEffect(() => {
    history.replace('/community', { ...historyState, boardListPage: page });
  }, [page, historyState]);

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        전체 글
      </Typography.Title>
      <Row style={{ marginBottom: 8 }} justify="end">
        <Col>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              deleteItems({
                itemType: EDeleteItemType.BOARD,
                deleteMutate: () =>
                  deleteBoards(selectedBoardIds, {
                    onSuccess: () => {
                      message.success(
                        `${
                          itemTypeMap[EDeleteItemType.BOARD]
                        }이 삭제되었습니다.`,
                      );
                      if (setSelectedBoardIds) {
                        setSelectedBoardIds([]);
                      }
                    },
                  }),
                selectedItemIds: selectedBoardIds,
              });
            }}
          >
            선택 삭제
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={boards}
        pagination={{
          showSizeChanger: false,
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: total,
        }}
        rowSelection={{
          columnWidth: 60,
          selectedRowKeys: selectedBoardIds,
          onChange: (keys) =>
            setSelectedBoardIds(keys.map((key) => Number(key))),
          getCheckboxProps: ({ status }) => ({
            disabled: status !== EBoardStatus.NORMAL,
          }),
        }}
        columns={[
          {
            title: '제목',
            align: 'left',
            render: (
              _,
              { title, commentCount, category, boardType, boardId, status },
            ) => (
              <Link to={`/community/${boardType.toLowerCase()}/${boardId}`}>
                <Row gutter={8}>
                  <Col>
                    <Typography.Text
                      className={
                        status !== EBoardStatus.NORMAL
                          ? 'deleted-board-title'
                          : ''
                      }
                    >
                      {category && `[${boardCategoryMap[category]}]`} {title}
                    </Typography.Text>
                  </Col>
                  {commentCount !== 0 && (
                    <Col>
                      <Typography.Text style={{ color: theme.colors.PRIMARY }}>
                        {`[${commentCount}]`}
                      </Typography.Text>
                    </Col>
                  )}
                  {status !== EBoardStatus.NORMAL && (
                    <Col>
                      <Typography.Text style={{ color: theme.colors.RED50 }}>
                        {`(${boardStatusMap[status]})`}
                      </Typography.Text>
                    </Col>
                  )}
                </Row>
              </Link>
            ),
          },
          {
            title: '유저 아이디',
            align: 'center',
            render: (_, { username, memberId }) => (
              <Typography.Link onClick={() => showMemberDetail(memberId)}>
                {username}
              </Typography.Link>
            ),
            width: 120,
          },
          {
            title: '유저 닉네임',
            align: 'center',
            render: (_, { nickname, memberId }) => (
              <Typography.Link onClick={() => showMemberDetail(memberId)}>
                {nickname ? nickname : '-'}
              </Typography.Link>
            ),
            width: 120,
          },
          {
            title: '날짜',
            align: 'center',
            render: (_, { registerDt }) => registerDt.slice(0, 10),
            width: 120,
          },
          {
            title: '조회수',
            align: 'center',
            dataIndex: 'viewCount',
            width: 80,
          },
          {
            title: '신고수',
            align: 'center',
            render: (_, { reportCount, boardId, title }) => (
              <Button
                type="link"
                size="small"
                onClick={() =>
                  showReports({
                    id: boardId,
                    reportKind: EReportKind.BOARD,
                    title: title,
                  })
                }
              >
                {reportCount}
              </Button>
            ),
            width: 80,
          },
        ]}
        rowKey="boardId"
        onRow={({ status }) => ({
          className: status !== EBoardStatus.NORMAL ? 'deleted' : '',
        })}
      />
    </Container>
  );
};

export default EntireBoardList;
