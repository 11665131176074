import styled from '@emotion/styled';
import { Button, Col, Modal, Row, Table, Typography } from 'antd';
import { useState } from 'react';

import SearchInput from 'components/ui/SearchInput';
import { reportTypeMap } from 'shared/consts';
import { EReportKind, EReportStatus, EReportType } from 'type/common';
import { useBoardMemberReports, useWithdrawReport } from 'core/query/report';
import useShowMemberDetail from 'util/hooks/useShowMemberDetail';

const Container = styled.div`
  padding-top: 24px;
`;

const MemberReportList = () => {
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { memberReports } = useBoardMemberReports({ page, searchKeyword });
  const withdrawReport = useWithdrawReport();
  const showMemberDetail = useShowMemberDetail();
  const handleShowMember = (memberId: number) => {
    showMemberDetail(memberId);
  };
  const handleWithdraw = (reportId: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title style={{ fontSize: 18 }}>
            신고를 정말로 철회하시겠습니까?
          </Typography.Title>
          <Typography.Text>철회한 신고는 되돌릴 수 없습니다.</Typography.Text>
        </>
      ),
      okText: '철회',
      onOk: () => {
        withdrawReport({ reportId, reportKind: EReportKind.MEMBER });
      },
    });
  };
  return (
    <Container>
      <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
        <Col>
          <Typography.Title style={{ fontSize: 16 }}>
            유저 신고
          </Typography.Title>
        </Col>
        <Col>
          <SearchInput
            defaultValue={searchKeyword}
            onSearch={setSearchKeyword}
            placeholder="유저아이디 검색"
          />
        </Col>
      </Row>
      <Table
        pagination={{ current: page, onChange: setPage }}
        rowKey="reportId"
        columns={[
          {
            title: '유저 아이디',
            width: '52%',
            render: (_, { memberId, memberUsername }) => (
              <Button
                type="link"
                size="small"
                onClick={() => handleShowMember(memberId)}
              >
                {memberUsername}
              </Button>
            ),
          },
          {
            title: '신고 사유',
            align: 'center',
            width: '10%',
            render: (_, { reportType, reportReason }) =>
              reportType !== EReportType.ETC ? (
                <Typography.Text>{reportTypeMap[reportType]}</Typography.Text>
              ) : (
                <Button
                  onClick={() => {
                    Modal.success({ icon: null, content: reportReason });
                  }}
                >
                  사유 보기
                </Button>
              ),
          },
          {
            title: '신고자',
            align: 'center',
            width: '16%',
            render: (_, { reportMemberId, reportUsername }) => (
              <Button
                type="link"
                size="small"
                onClick={() => handleShowMember(reportMemberId)}
              >
                {reportUsername}
              </Button>
            ),
          },
          {
            title: '작성일',
            align: 'center',
            width: '8%',
            dataIndex: 'registerDt',
            render: (registerDt) => registerDt.slice(0, 10),
          },
          {
            width: '8%',
            align: 'center',
            render: (_, { reportId, withdrawDt, status }) =>
              status === EReportStatus.NORMAL ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleWithdraw(reportId)}
                >
                  신고 철회
                </Button>
              ) : (
                withdrawDt?.slice(0, 10)
              ),
          },
        ]}
        dataSource={memberReports}
      />
    </Container>
  );
};

export default MemberReportList;
