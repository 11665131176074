import { Rule, RuleObject } from 'antd/lib/form';
import {
  INVALID_EMAIL_FORMAT,
  INVALID_PHONE_NUMBER_FORMAT,
  REQUIRED_FIELD,
  SHOULD_EXCEPT_NUMBER,
} from './message';

/** NEW RULES (22.07.01~) */

export interface IGenerateFormRuleOptions {
  required?: boolean;
  exceptKorean?: boolean;
  exceptEnglish?: boolean;
  onlyNumber?: boolean;
  onlyKorean?: boolean;
  minLength?: number;
  maxLength?: number;
}

// TODO: 기존 validation 검증 로직(정규식)을 해당 함수 하나로 대체 예정
export const generateFormRules = (
  options: IGenerateFormRuleOptions,
): Rule[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validator = (_: RuleObject, value: any = '') => {
    let errorMessage = '';

    const appendErrorMessage = (message: string) =>
      (errorMessage += message + ', ');

    // 필수 (false || null || undefined || 빈 문자열 || 빈 배열)
    if (
      options.required &&
      (value === false ||
        value === undefined ||
        value === null ||
        value?.length === 0)
    ) {
      appendErrorMessage('필수 항목');

      return Promise.reject('필수 항목');
    }

    // 글자수
    if (
      typeof options.minLength === 'number' &&
      typeof options.maxLength === 'number' &&
      (value?.length < options.minLength || value?.length > options.maxLength)
    ) {
      appendErrorMessage(`${options.minLength}~${options.maxLength}자리`);
    } else if (
      typeof options.minLength === 'number' &&
      value?.length < options.minLength
    ) {
      appendErrorMessage(`최소 ${options.minLength}자`);
    } else if (
      typeof options.maxLength === 'number' &&
      value?.length > options.maxLength
    ) {
      if (options.onlyNumber) {
        appendErrorMessage(
          `최대 ${options.maxLength}자 (0 ~ ${
            10 ** options.maxLength - 1
          }까지)`,
        );
      } else {
        appendErrorMessage(`최대 ${options.maxLength}자`);
      }
    }

    // 한글 입력 불가
    if (options.exceptKorean && /[ㄱ-ㅎㅏ-ㅣ가-힣]+/g.test(value)) {
      appendErrorMessage('한글 입력 불가');
    }

    // 영문 입력 불가
    if (options.exceptEnglish && /[a-zA-Z]+/g.test(value)) {
      appendErrorMessage('영문 입력 불가');
    }

    // 오직 숫자
    if (options.onlyNumber && !/^[-]?\d+[.]?\d*$/.test(value)) {
      appendErrorMessage('숫자만 입력 가능');
    }

    // 오직 한글
    if (options.onlyKorean && !/^[ㄱ-ㅎㅏ-ㅣ가-힣\s]+$/.test(value)) {
      appendErrorMessage('한글만 입력 가능');
    }

    if (errorMessage) {
      // 마지막 ', ' 제거
      return Promise.reject(errorMessage.substring(0, errorMessage.length - 2));
    } else {
      return Promise.resolve();
    }
  };

  return [
    {
      validator,
    },
  ];
};

export const requiredRule = {
  required: true,
  message: REQUIRED_FIELD,
};

export const emailRule = {
  pattern:
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_]?[0-9a-zA-Z.])*\.[a-zA-Z]{2,}$/i,
  message: INVALID_EMAIL_FORMAT,
};

export const adminEmailRule = {
  pattern: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@(cdri.pro|30cos.com)$/i,
  message: INVALID_EMAIL_FORMAT,
};

export const exceptNumberRule = {
  pattern: /^[^0-9]*$/,
  message: SHOULD_EXCEPT_NUMBER,
};

export const phoneNumberRule = {
  pattern: /^[0-9]{11}$/,
  message: INVALID_PHONE_NUMBER_FORMAT,
};
