import styled from '@emotion/styled';
import { Card, message, Typography } from 'antd';

import SessionForm from 'components/session/SessionForm';
import { useOpenSession } from 'core/query/session';
import path from 'shared/path';
import { ISessionForm } from 'type/session';
import history from 'util/history';

const Container = styled.div``;

const SessionOpen = () => {
  const { openSession, isLoading } = useOpenSession();
  const handleSubmit = (session: ISessionForm) => {
    openSession(session, {
      onSuccess: () => {
        message.success('새로운 세션이 오픈되었습니다.');
        history.push(path.session.ongoing);
      },
      onError: () => {
        message.error('세션 오픈에 실패했습니다.');
      },
    });
  };
  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 12 }}>
        세션 오픈
      </Typography.Title>
      <Card>
        <SessionForm
          submitBtnText="등록"
          onSubmit={handleSubmit}
          submitLoading={isLoading}
        />
      </Card>
    </Container>
  );
};

export default SessionOpen;
