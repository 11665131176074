import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import { EAttachType, IAPIResponse, IAttach, IInterest } from 'type/common';
import api from 'util/api';
import { createFormData } from 'util/form';

export const useInterests = () => {
  const { data: interests = [] } = useQuery(
    ['interests'],
    () => api.get<IAPIResponse<IInterest[]>>('/v1/interests'),
    { select: (res) => res.data.result, staleTime: Number.MAX_SAFE_INTEGER },
  );
  return interests;
};

export const useUploadFiles = () => {
  const { mutate: uploadFiles, isLoading } = useMutation(
    ({ files, attachType }: { files: File[]; attachType: EAttachType }) =>
      api.post<IAPIResponse<IAttach[]>>(
        `/v1/attaches/${attachType}/files`,
        createFormData({ files }),
      ),
  );

  return useMemo(() => ({ uploadFiles, isLoading }), [uploadFiles, isLoading]);
};

export const useDeleteFiles = () => {
  const { mutate: deleteFiles, isLoading } = useMutation(
    (attachIds: number[]) => api.delete(`/v1/attaches`, { data: attachIds }),
  );

  return useMemo(() => ({ deleteFiles, isLoading }), [deleteFiles, isLoading]);
};

export const useUploadImageIntoEditor = (
  type: 'BOARD_NOTICE' | 'SESSION' | 'FAQ',
) => {
  const { mutate: uploadImageIntoEditor } = useMutation((file: File) =>
    api.post<IAPIResponse<string>>(
      `/v1/editors/${type}/image`,
      createFormData({ image: file }),
    ),
  );
  return uploadImageIntoEditor;
};
