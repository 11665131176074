import { EAdLocationCode, IStatisticsDataWithProductCode } from 'type/stats';

export const getRate = (
  locationCode: EAdLocationCode,
  statisticsData: IStatisticsDataWithProductCode,
) => {
  if (locationCode === EAdLocationCode.ALL) {
    return statisticsData.PB001.reduce(
      (prev, { clickCount, exposureCount }) => {
        return prev + Math.floor(clickCount / (exposureCount || clickCount));
      },
      0,
    );
  }

  return statisticsData.PB001.filter(
    ({ advertisingLocationCode }) => advertisingLocationCode === locationCode,
  ).reduce((prev, { clickCount, exposureCount }) => {
    return prev + Math.floor(clickCount / (exposureCount || clickCount));
  }, 0);
};

export const getExposure = (
  locationCode: EAdLocationCode,
  statisticsData: IStatisticsDataWithProductCode,
) => {
  if (locationCode === EAdLocationCode.ALL) {
    return statisticsData.PB001.reduce(
      (prev, { exposureCount }) => prev + exposureCount,
      0,
    );
  }

  return statisticsData.PB001.filter(
    ({ advertisingLocationCode }) => advertisingLocationCode === locationCode,
  ).reduce((prev, { exposureCount }) => prev + exposureCount, 0);
};

export const getClick = (
  locationCode: EAdLocationCode,
  statisticsData: IStatisticsDataWithProductCode,
) => {
  if (locationCode === EAdLocationCode.ALL) {
    return statisticsData.PB001.reduce(
      (prev, { clickCount }) => prev + clickCount,
      0,
    );
  }

  return statisticsData.PB001.filter(
    ({ advertisingLocationCode }) => advertisingLocationCode === locationCode,
  ).reduce((prev, { clickCount }) => prev + clickCount, 0);
};
