export interface IAdCompany {
  companyId: number;
  companyName: string;
  bizNumber: string;
  registerDt: string;
  firstAdvertisingStartDate: string;
}

export interface IPeriod {
  from: string;
  to: string;
}

export enum EPeriodRangeType {
  ALL = 'all',
  MONTH = 'month',
  DATE = 'date',
}

export type AdDataType = 'exposureCount' | 'clickCount' | 'rate';

export interface IStatisticsDataWithProductCode {
  PB001: IStatisticInfo[];
}

export interface IStatisticInfo {
  purchaseCode: string;
  advertisingLocationCode: 'SESSION_MAIN' | 'COMMUNITY_MAIN';
  exposureCount: number;
  clickCount: number;
}

export interface IAdStats {
  statisticsDate: string;
  statisticsDataWithProductCode: IStatisticsDataWithProductCode;
}

export enum EAdLocationCode {
  ALL = 'ALL',
  SESSION_MAIN = 'SESSION_MAIN',
  COMMUNITY_MAIN = 'COMMUNITY_MAIN',
}
