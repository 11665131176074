import { Route, Routes } from 'react-router-dom';

import BusinessSupport from 'components/business/BusinessSupport';
import BeautyFair from 'components/business/BeautyFair';

const BusinessRoutes = () => {
  return (
    <Routes>
      <Route path="/business-support" element={<BusinessSupport />} />
      <Route path="/beauty-fair" element={<BeautyFair />} />
    </Routes>
  );
};

export default BusinessRoutes;
