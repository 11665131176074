import { EReportType } from 'type/common';
import {
  EBoardCategory,
  EBoardType,
  EBoardStatus,
  EDeleteItemType,
} from 'type/board';

export const reportTypeMap = {
  [EReportType.ADVERTISEMENT]: '광고 / 도배',
  [EReportType.PRIVATE]: '개인 정보 침해',
  [EReportType.EXPLETIVE]: '욕설 / 음란물',
  [EReportType.DISPUTE]: '정치적 / 분쟁 유발',
};

export const boardTypeMap = {
  [EBoardType.ORIGINAL]: '브릿지 이야기',
  [EBoardType.PROMOTION]: '자료 모아보기',
  [EBoardType.FREE]: '수다공간',
  [EBoardType.QNA]: '궁금해요',
  [EBoardType.ANONYMOUS]: '익명 게시판',
};

export const boardCategoryMap = {
  [EBoardCategory.NONE]: '무관',
  [EBoardCategory.RETAIL_CO]: '유통사',
  [EBoardCategory.BRAND_CO]: '브랜드사',
  [EBoardCategory.MANUFACTURER_CO]: '제조사',
  [EBoardCategory.MATERIAL_CO]: '원료사',
  [EBoardCategory.SUBMATERIAL_CO]: '부자재사',
};

export const boardStatusMap = {
  [EBoardStatus.BLOCK]: '차단',
  [EBoardStatus.ADMIN_DELETE]: '관리자 삭제',
  [EBoardStatus.NORMAL]: '정상',
  [EBoardStatus.DELETE]: '유저 삭제',
};

export const itemTypeMap = {
  [EDeleteItemType.BOARD]: '게시글',
  [EDeleteItemType.COMMENT]: '댓글',
  [EDeleteItemType.REPLY]: '답글',
};
