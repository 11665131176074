import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { useUploadImageIntoEditor } from 'core/query/common';
import { SunEditorOptions } from 'suneditor/src/options';

const Editor = ({
  imageUploadType,
  initialValue,
  onChange,
  maxCharCount = 6000,
  maxHeight,
}: {
  imageUploadType: 'BOARD_NOTICE' | 'SESSION' | 'FAQ';
  initialValue?: string;
  onChange?: (value: string) => void;
  maxCharCount?: number;
  maxHeight?: number;
}) => {
  const uploadImageIntoEditor = useUploadImageIntoEditor(imageUploadType);

  const editorOptions: SunEditorOptions = {
    width: 'auto',
    height: 'auto',
    ...(maxHeight && { maxHeight: `${maxHeight}px` }),
    minHeight: '400px',
    maxCharCount,
    buttonList: [
      [
        'formatBlock',
        'fontSize',
        'fontColor',
        'hiliteColor',
        'bold',
        'underline',
        'strike',
        'superscript',
        'removeFormat',
      ],
      ['outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
      ['link', 'image', 'video', 'fullScreen', 'codeView', 'preview'],
    ],
  };

  return (
    <SunEditor
      key={initialValue}
      lang="ko"
      defaultValue={initialValue}
      setOptions={editorOptions}
      onChange={onChange}
      onImageUploadBefore={(files, _, uploadHandler) => {
        uploadImageIntoEditor(files[0], {
          onSuccess: ({ data: { result: imageUrl } }) => {
            uploadHandler({
              result: [
                {
                  url: imageUrl,
                  name: files[0].name,
                  size: files[0].size,
                },
              ],
            });
          },
          onError: (error) => {
            uploadHandler();
            console.error(error);
          },
        });

        return undefined;
      }}
    />
  );
};

export default Editor;
