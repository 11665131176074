import { atom, selector } from 'recoil';

import { IAuth } from 'type/auth';

export const authState = atom<IAuth | null>({
  key: 'authState',
  default: null,
});

export const isLoggedInState = selector({
  key: 'isLoggedInState',
  get: ({ get }) => get(authState) !== null,
});

export const isSignupState = atom<boolean>({
  key: 'isSignupState',
  default: false,
});
