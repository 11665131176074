import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const useAuthTimer = (duration: number, onTimeout?: () => void) => {
  const [second, setSecond] = useState(duration + 1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timerRef = useRef<any>(null);
  const startTimer = useCallback(() => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
    }
    setSecond(duration);
    timerRef.current = setInterval(() => {
      setSecond((draft) => draft - 1);
    }, 1000);
  }, [duration]);
  const resetTimer = useCallback(() => {
    setSecond(duration + 1);
    clearInterval(timerRef.current);
    timerRef.current === null;
  }, [duration]);
  useEffect(() => {
    if (second === 0) {
      resetTimer();
      onTimeout && onTimeout();
    }
  }, [second, resetTimer, onTimeout]);

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return useMemo(
    () => ({
      activated: second <= duration,
      min: Math.floor(second / 60),
      sec: second % 60,
      startTimer,
      resetTimer,
    }),
    [second, duration, startTimer, resetTimer],
  );
};

export default useAuthTimer;
