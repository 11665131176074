import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, message, Select, Table, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { nanoid } from 'nanoid';
import { ColumnsType } from 'antd/lib/table';
import { useRecoilValue } from 'recoil';

import {
  useBoards,
  useBoardNotice,
  useDeleteBoards,
} from 'core/query/community';
import SearchInput from 'components/ui/SearchInput';
import { EBoardType, EBoardStatus, EDeleteItemType, IBoard } from 'type/board';
import { theme } from 'styles/theme';
import {
  boardCategoryMap,
  boardStatusMap,
  boardTypeMap,
  itemTypeMap,
} from 'shared/consts';
import useDeleteItems from 'util/hooks/useDeleteItems';
import useShowMemberDetail from 'util/hooks/useShowMemberDetail';
import useShowReports from 'util/hooks/useShowReports';
import history from 'util/history';
import { EReportKind } from 'type/common';
import { Flex } from 'components/ui';
import { authState } from 'core/store/auth';

const Container = styled.div`
  table {
    tr.deleted {
      background-color: ${theme.colors.GRAY80};
    }
    tr.notice td {
      border-bottom: 1px solid ${theme.colors.PRIMARY};
    }
    .deleted-board-title {
      text-decoration: line-through;
    }
  }
`;

interface IBoardListHistoryState {
  page?: number;
  searchType?: 'TITLE' | 'CONTENT' | 'USERNAME' | 'NICKNAME';
  searchKeyword?: string;
  selectedBoardIds?: number[];
}

const BoardList = ({ boardType }: { boardType: EBoardType }) => {
  const auth = useRecoilValue(authState);
  const adminId = auth?.adminMemberId;
  const tableRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const historyState = location.state as IBoardListHistoryState | null;
  const [page, setPage] = useState(historyState?.page || 1);
  const [searchType, setSearchType] = useState<
    'TITLE' | 'CONTENT' | 'USERNAME' | 'NICKNAME'
  >(historyState?.searchType || 'TITLE');
  const [searchKeyword, setSearchKeyword] = useState(
    historyState?.searchKeyword || '',
  );
  const [selectedBoardIds, setSelectedBoardIds] = useState<number[]>(
    historyState?.selectedBoardIds || [],
  );
  const { deleteBoards } = useDeleteBoards();
  const showMemberDetail = useShowMemberDetail();
  const showReports = useShowReports();
  const deleteItems = useDeleteItems();

  const { boards, total } = useBoards({
    page,
    boardType,
    searchType,
    searchKeyword,
  });
  const { boardNotice } = useBoardNotice({ boardType });

  const columns: ColumnsType<IBoard> = [
    ...(boardNotice && page === 1
      ? [
          {
            width: 120,
          },
        ]
      : []),
    {
      title: '제목',
      align: 'left',
      width: 800,
      render: (_, { boardId, title, commentCount, category, status }) => (
        <Link to={boardId.toString()}>
          <Flex columnGap={8} wrap="true">
            <Typography.Text
              className={
                status !== EBoardStatus.NORMAL ? 'deleted-board-title' : ''
              }
            >
              {category && `[${boardCategoryMap[category]}]`} {title}
            </Typography.Text>
            {commentCount !== 0 && (
              <Typography.Text style={{ color: theme.colors.PRIMARY }}>
                {`[${commentCount}]`}
              </Typography.Text>
            )}
            {status !== EBoardStatus.NORMAL && (
              <Typography.Text style={{ color: theme.colors.RED50 }}>
                {`(${boardStatusMap[status]})`}
              </Typography.Text>
            )}
          </Flex>
        </Link>
      ),
    },
    {
      title: '유저 아이디',
      align: 'center',
      render: (_, { username, memberId, adminMemberId }) =>
        adminMemberId ? (
          `관리자 (${username})`
        ) : (
          <Typography.Link onClick={() => showMemberDetail(memberId)}>
            {username}
          </Typography.Link>
        ),
      width: 180,
    },
    {
      title: '유저 닉네임',
      align: 'center',
      render: (_, { nickname, memberId, adminMemberId }) =>
        adminMemberId ? (
          nickname || '관리자'
        ) : (
          <Typography.Link onClick={() => showMemberDetail(memberId)}>
            {nickname || '-'}
          </Typography.Link>
        ),
      width: 180,
    },
    {
      title: '등록일',
      align: 'center',
      render: (_, { registerDt }) => registerDt.slice(0, 10),
      width: 160,
    },
    {
      title: '조회수',
      align: 'center',
      dataIndex: 'viewCount',
      width: 120,
    },
    {
      title: '신고수',
      align: 'center',
      render: (_, { reportCount, boardId, title }) => (
        <Button
          type="link"
          size="small"
          onClick={() =>
            showReports({
              id: boardId,
              reportKind: EReportKind.BOARD,
              title: title,
            })
          }
        >
          {reportCount}
        </Button>
      ),
      width: 120,
    },
  ];

  useEffect(() => {
    history.replace(location.pathname, {
      page,
      searchType,
      searchKeyword,
      selectedBoardIds,
    });
  }, [page, searchType, searchKeyword, selectedBoardIds, location.pathname]);

  useEffect(() => {
    const moveTo = () => {
      history.push(
        `/community/${boardType}/notice/${boardNotice?.boardNoticeId}`,
      );
    };
    if (boardNotice && page === 1) {
      const $tbody = tableRef.current?.querySelector('.ant-table-tbody');
      const titleId = nanoid();
      const $tr = document.createElement('tr');
      $tr.classList.add('notice');
      $tr.innerHTML = `
        <td></td>
        <td style="text-align:center;"><button style="border: 1px solid ${
          theme.colors.PRIMARY
        };padding: 0 10px;background: none;line-height: 20px;border-radius:2px">공지</button></td>
        <td id="${titleId}" class="ant-table-cell" style="cursor:pointer;">${
        boardNotice.title
      }</td>
        <td class="ant-table-cell" style="text-align:center;">관리자</td>
        <td class="ant-table-cell" style="text-align:center;">관리자</td>
        <td class="ant-table-cell" style="text-align:center;">${boardNotice.registerDt.slice(
          0,
          10,
        )}<td>
        <td></td>
      `;
      $tbody?.prepend($tr);
      const $title = document.getElementById(titleId);
      $title?.addEventListener('click', () => {
        moveTo();
      });
      return () => {
        $tbody?.removeChild($tr);
      };
    }
  }, [boardNotice, page, boardType]);

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        {boardTypeMap[boardType]}
      </Typography.Title>
      <Flex justify="space-between" gap={8} style={{ marginBottom: 12 }}>
        <Flex gap={8}>
          <Link to={`/community/notice`} state={{ boardType, mode: 'add' }}>
            <Button type="primary" icon={<PlusOutlined />}>
              새로운 공지사항 작성하기
            </Button>
          </Link>
          <Button
            icon={<DeleteOutlined />}
            onClick={() =>
              deleteItems({
                itemType: EDeleteItemType.BOARD,
                deleteMutate: () =>
                  deleteBoards(selectedBoardIds, {
                    onSuccess: () => {
                      message.success(
                        `${
                          itemTypeMap[EDeleteItemType.BOARD]
                        }이 삭제되었습니다.`,
                      );
                      if (setSelectedBoardIds) {
                        setSelectedBoardIds([]);
                      }
                    },
                  }),
                selectedItemIds: selectedBoardIds,
              })
            }
          >
            선택 삭제
          </Button>
        </Flex>
        <Flex gap={8}>
          <Link to={`/community/${boardType}/admin`}>
            <Button icon={<PlusOutlined />}>글쓰기</Button>
          </Link>
          <Select
            value={searchType}
            options={[
              { label: '제목', value: 'TITLE' },
              { label: '내용', value: 'CONTENT' },
              { label: '아이디', value: 'USERNAME' },
              { label: '닉네임', value: 'NICKNAME' },
            ]}
            onChange={setSearchType}
            style={{ width: 100 }}
          />
          <SearchInput
            defaultValue={searchKeyword}
            onSearch={(value) => {
              setSearchKeyword(value);
              setPage(1);
            }}
            placeholder="검색어 입력"
          />
        </Flex>
      </Flex>
      <Table
        ref={tableRef}
        dataSource={boards}
        pagination={{
          showSizeChanger: false,
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: total,
        }}
        rowSelection={{
          columnWidth: 60,
          selectedRowKeys: selectedBoardIds,
          onChange: (keys) =>
            setSelectedBoardIds(keys.map((key) => Number(key))),
          getCheckboxProps: ({ status, adminMemberId }) => ({
            disabled:
              status !== EBoardStatus.NORMAL ||
              (adminMemberId ? adminId !== adminMemberId : false),
          }),
        }}
        columns={columns}
        rowKey="boardId"
        onRow={({ status }) => ({
          className: status !== EBoardStatus.NORMAL ? 'deleted' : '',
        })}
      />
    </Container>
  );
};

export default BoardList;
