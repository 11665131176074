import styled from '@emotion/styled';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { useEffect } from 'react';

import Editor from 'components/ui/Editor';
import { useFaq } from 'core/query/cs';
import { requiredRule } from 'shared/rule';
import { IFaq } from 'type/cs';
import { REQUIRED_FIELD } from 'shared/message';

const Container = styled.div`
  margin-top: 24px;
`;

const FaqForm = ({
  faqId,
  onSubmit,
}: {
  faqId?: number;
  onSubmit: () => void;
}) => {
  const [form] = Form.useForm<IFaq>();
  const { faq, addFaq, addLoading, updateFaq, updateLoading } = useFaq(faqId);
  const handleSubmitFaq = (faq: IFaq) => {
    if (!faqId) {
      addFaq(faq, { onSuccess: onSubmit });
    } else {
      updateFaq(faq, { onSuccess: onSubmit });
    }
  };
  useEffect(() => {
    if (faq) {
      form.setFieldsValue(faq);
    }
  }, [form, faq]);
  return (
    <Container>
      <Form form={form} onFinish={handleSubmitFaq}>
        <Form.Item label="제목" name="title" required rules={[requiredRule]}>
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item
          label="내용"
          name="content"
          rules={[
            requiredRule,
            {
              validator: async (_, value: string) => {
                if (!value.replace('<p><br></p>', '')) {
                  throw new Error(REQUIRED_FIELD);
                }
              },
            },
          ]}
        >
          <Editor initialValue={faq?.content} imageUploadType="FAQ" />
        </Form.Item>
        <Form.Item
          label="공개 여부"
          name="isLive"
          initialValue={true}
          style={{ marginTop: 12 }}
        >
          <Radio.Group
            options={[
              { value: true, label: '공개' },
              { value: false, label: '비공개' },
            ]}
          />
        </Form.Item>
        <Row justify="center">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={addLoading || updateLoading}
            >
              {faq === null ? '추가하기' : '수정하기'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default FaqForm;
