import { Upload } from 'antd';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { DownloadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import { downloadFileFromUrl } from 'util/file';
import BoardUploadItem from './BoardUploadItem';
import { IAttach } from 'type/common';

const Container = styled.div`
  max-width: 400px;
`;

const BoardAttach = ({
  attaches = [],
  maxCount = 1,
}: {
  attaches?: IAttach[];
  maxCount?: number;
}) => {
  const fileList: UploadFile[] = useMemo(
    () =>
      attaches.map(({ attachId, filename, uploadFileUrl }) => ({
        uid: String(attachId),
        name: filename,
        status: 'done',
        url: uploadFileUrl,
      })),
    [attaches],
  );

  return (
    <Container>
      <Upload
        fileList={fileList}
        maxCount={maxCount}
        name="files"
        multiple={maxCount > 1}
        showUploadList={{
          showDownloadIcon: true,
          downloadIcon: <DownloadOutlined />,
        }}
        itemRender={(_, file) => <BoardUploadItem attach={file} />}
        onDownload={(file) => {
          console.debug(file.name);
          file.url && downloadFileFromUrl(file.url, file.name);
        }}
      ></Upload>
    </Container>
  );
};

export default BoardAttach;
