import styled from '@emotion/styled';
import { Button, Modal } from 'antd';
import { Flex } from 'components/ui';
import { useSessionBannerImages } from 'core/query/session';

import SessionImageSelectModalContent from './SessionBannerImageSelectModalContent';

const Container = styled.div``;

const SessionBannerImage = styled.img`
  display: block;
  width: 144px;
`;

const SessionBannerImageSelect = ({
  sessionBannerImageId,
  onChange,
}: {
  sessionBannerImageId?: number;
  onChange?: (sessionBannerImageId: number) => void;
}) => {
  const sessionBannerImages = useSessionBannerImages();
  const sessionBannerImage = sessionBannerImages.find(
    (sessionBannerImage) =>
      sessionBannerImage.sessionBannerImageId === sessionBannerImageId,
  );
  const handleClickImageSelect = () => {
    const modal = Modal.info({
      width: 800,
      icon: null,
      content: (
        <SessionImageSelectModalContent
          defaultSessionBannerImage={sessionBannerImage}
          sessionBannerImages={sessionBannerImages}
          onSelect={(sessionBannerImageId) => {
            onChange && onChange(sessionBannerImageId);
            modal.destroy();
          }}
        />
      ),
      okButtonProps: { hidden: true },
      closable: true,
      maskClosable: true,
      bodyStyle: { padding: 0 },
    });
  };
  return (
    <Container>
      <Flex gap={8}>
        <Button onClick={handleClickImageSelect}>이미지 선택</Button>
        {sessionBannerImage && (
          <SessionBannerImage
            src={sessionBannerImage.imageUrl}
            alt="session image"
          />
        )}
      </Flex>
    </Container>
  );
};

export default SessionBannerImageSelect;
