import moment from 'moment';

export const getYYYYMMDDs = (
  from: string,
  to: string,
  unit: 'days' | 'months',
) => {
  const YYYYMMDDs = [];
  for (let i = moment(moment(from)); !i.isAfter(moment(to)); i.add(1, unit)) {
    YYYYMMDDs.push(i.format('YYYY-MM-DD'));
  }

  return YYYYMMDDs;
};
