import { useMutation, useQuery } from 'react-query';
import qs from 'qs';
import moment from 'moment';
import { useMemo } from 'react';

import { IAPIPageableResponse, IAPIResponse } from 'type/common';
import {
  EMemberInquirySearchType,
  IMember,
  IMemberInquiry,
  IMemberInquiryDetail,
  IMemberListItem,
} from 'type/member';

import api from 'util/api';
import { downloadFile } from 'util/file';

export const useMembers = ({
  page,
  businessTypeId,
  searchType,
  searchKeyword,
}: {
  page: number;
  businessTypeId?: number;
  searchType: string;
  searchKeyword: string;
}) => {
  const { data: membersInfo = { content: [], totalElements: 0 }, refetch } =
    useQuery(
      ['member', 'members', page, businessTypeId, searchType, searchKeyword],
      () =>
        api.get<IAPIPageableResponse<IMemberListItem[]>>(
          `/v1/members?${qs.stringify({
            page,
            businessTypeId,
            searchType,
            searchKeyword,
            size: 10,
          })}`,
        ),
      { select: (res) => res.data.result },
    );

  return useMemo(() => ({ membersInfo, refetch }), [membersInfo, refetch]);
};

export const useMember = (memberId: number) => {
  const { data: member = null } = useQuery(
    ['member', memberId],
    () => api.get<IAPIResponse<IMember>>(`/v1/members/${memberId}`),
    { select: (res) => res.data.result },
  );
  return member;
};

export const useMemberListExcel = () => {
  const { mutate: downlaodMemberList, isLoading } = useMutation(() =>
    downloadFile(
      `/files/excel/members`,
      `회원리스트_${moment().format('YYMMDD')}`,
      {
        useClient: true,
      },
    ),
  );
  return {
    downlaodMemberList,
    isLoading,
  };
};

export const useMemberInquiries = ({
  page,
  searchType,
  searchKeyword,
}: {
  page: number;
  searchType: EMemberInquirySearchType;
  searchKeyword: string;
}) => {
  const {
    data: memberInquiriesInfo = { content: [], totalElements: 0 },
    refetch,
  } = useQuery(
    ['inquiries', page, searchType, searchKeyword],
    () =>
      api.get<IAPIPageableResponse<IMemberInquiry[]>>(
        `/v1/inquiries?${qs.stringify({
          page,
          searchType,
          searchKeyword,
          size: 10,
        })}`,
      ),
    { select: (res) => res.data.result },
  );
  return useMemo(
    () => ({ memberInquiriesInfo, refetch }),
    [memberInquiriesInfo, refetch],
  );
};

export const useMemberInquiry = (memberInquiryId: number) => {
  const { data: memberInquiry = null } = useQuery(
    ['member', 'memberInquiry', memberInquiryId],
    () =>
      api.get<IAPIResponse<IMemberInquiryDetail>>(
        `/v1/inquiries/${memberInquiryId}`,
      ),
    { select: (res) => res.data.result },
  );
  const { mutate: answerMemberInquiry } = useMutation((content: string) =>
    api.post(`/v1/inquiries/${memberInquiryId}/answer`, { content }),
  );

  return useMemo(
    () => ({ memberInquiry, answerMemberInquiry }),
    [memberInquiry, answerMemberInquiry],
  );
};
