import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Col, message, Modal, Row, Table, Typography } from 'antd';

import { useDeleteNotices, useNotices } from 'core/query/cs';
import { useState } from 'react';
import { QueryClientProvider, useQueryClient } from 'react-query';

import { theme } from 'styles/theme';
import NoticeForm from './NoticeForm';

const Container = styled.div``;

const NoticeList = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const { notices, total } = useNotices(page);
  const { deleteNotices, isLoading } = useDeleteNotices();
  const [selectedNoticeIds, setSelectedNoticeIds] = useState<number[]>([]);
  const handleAddNotice = () => {
    const modal = Modal.info({
      title: '공지사항 등록',
      width: 920,
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <NoticeForm
            onSubmit={() => {
              modal.destroy();
            }}
          />
        </QueryClientProvider>
      ),
    });
  };
  const handleShowNotice = (noticeId: number) => {
    const modal = Modal.info({
      title: '공지사항 상세',
      width: 920,
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <NoticeForm
            noticeId={noticeId}
            onSubmit={() => {
              modal.destroy();
            }}
          />
        </QueryClientProvider>
      ),
    });
  };
  const handleDeleteNotices = () => {
    if (selectedNoticeIds.length === 0) {
      return message.warn('삭제할 공지사항을 선택해 주세요.');
    }
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      icon: null,
      onOk: () => {
        deleteNotices(selectedNoticeIds, {
          onSuccess: () => {
            message.success('공지사항이 삭제되었습니다.');
            setSelectedNoticeIds([]);
          },
        });
      },
    });
  };
  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 12 }}>
        공지사항
      </Typography.Title>
      <Row justify="end" gutter={8} style={{ marginBottom: 12 }}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddNotice}
          >
            새로운 공지사항 작성하기
          </Button>
        </Col>
        <Col>
          <Button
            icon={<DeleteOutlined />}
            loading={isLoading}
            onClick={handleDeleteNotices}
          >
            선택 삭제
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={notices}
        rowKey="noticeId"
        pagination={{ current: page, onChange: setPage, total }}
        rowSelection={{
          selectedRowKeys: selectedNoticeIds,
          onChange: (keys) =>
            setSelectedNoticeIds(keys.map((key) => Number(key))),
        }}
        columns={[
          {
            title: '번호',
            dataIndex: 'noticeId',
            align: 'center',
            width: '100px',
          },
          {
            title: '제목',
            align: 'center',
            render: (_, { noticeId, title }) => (
              <Typography.Text
                type="secondary"
                style={{
                  color: theme.colors.PRIMARY,
                  cursor: 'pointer',
                }}
                onClick={() => handleShowNotice(noticeId)}
              >
                {title}
              </Typography.Text>
            ),
          },
          {
            title: '공개 여부',
            align: 'center',
            width: '140px',
            render: (_, { isLive }) => (isLive ? '공개' : '비공개'),
          },
          {
            title: '팝업 노출',
            align: 'center',
            width: '140px',
            render: (_, { isMainPopup }) => (isMainPopup ? '노출' : '비노출'),
          },
        ]}
      />
    </Container>
  );
};

export default NoticeList;
