import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Card, Tabs, Typography } from 'antd';

import EntireBoardList from './EntireBoardList';
import EntireBoardCommentList from './EntireBoardCommentList';
import history from 'util/history';
import { IEntireBoardOrCommentHistoryState } from 'type/board';
import { Flex } from 'components/ui';

const Container = styled.div`
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const EntireBoardOrComment = () => {
  const historyState = history.location
    .state as IEntireBoardOrCommentHistoryState | null;

  const [tab, setTab] = useState<'boardList' | 'boardCommentList'>(
    historyState?.tab || 'boardList',
  );

  const boardList = useMemo(() => {
    switch (tab) {
      case 'boardList':
        return <EntireBoardList />;
      case 'boardCommentList':
        return <EntireBoardCommentList />;
      default:
        return <EntireBoardList />;
    }
  }, [tab]);

  useEffect(() => {
    history.replace('/community', { tab });
  }, [tab]);

  return (
    <Container>
      <Flex justify="space-between">
        <Typography.Title style={{ fontSize: 18, marginBottom: 12 }}>
          전체 글 / 댓글
        </Typography.Title>
        <Tabs
          moreIcon={null}
          activeKey={tab}
          onChange={(key) => {
            setTab(key as typeof tab);
          }}
        >
          <Tabs.TabPane tab="전체 글" key="boardList" />
          <Tabs.TabPane tab="전체 댓글" key="boardCommentList" />
        </Tabs>
      </Flex>
      <Card>{boardList}</Card>
    </Container>
  );
};

export default EntireBoardOrComment;
