import styled from '@emotion/styled';
import { Button, Card, Col, Form, Input, message, Row, Typography } from 'antd';
import { useSetRecoilState } from 'recoil';
import { useState } from 'react';

import {
  adminEmailRule,
  exceptNumberRule,
  phoneNumberRule,
  requiredRule,
} from 'shared/rule';
import logo from 'assets/logo.svg';
import { isSignupState } from 'core/store/auth';
import { ISignupForm } from 'type/auth';
import {
  useCheckEmail,
  useSendEmailCode,
  useSignup,
  useVerifyEmail,
} from 'core/query/auth';
import useAuthTimer from 'util/hooks/useAuthTimer';
import PhoneNumberInput from 'components/ui/PhoneNumberInput';
import { theme } from 'styles/theme';
import { REQUIRED_FIELD } from 'shared/message';

const Container = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto 24px;
  width: 100px;
`;

const Signup = () => {
  const [form] = Form.useForm<ISignupForm>();
  const [emailVerified, setEmailVerified] = useState(false);
  const { sendEmailCode, isLoading: isLoadingSendEmail } = useSendEmailCode();
  const { verifyEmail, isLoading: isLoadingVerifyEmail } = useVerifyEmail();

  const { activated, min, sec, startTimer, resetTimer } = useAuthTimer(180);

  const { signup, isLoading } = useSignup();
  const setIsSignup = useSetRecoilState(isSignupState);
  const handleSignup = (formValues: ISignupForm) => {
    if (!emailVerified) {
      message.warning('이메일 인증이 필요합니다.');
      return;
    }
    const { emailCode: _, ...rest } = formValues;
    signup(
      { ...rest },
      {
        onSuccess: () => {
          message.success('회원등록이 완료되었습니다.');
          setIsSignup(false);
        },
      },
    );
  };
  const handleSendEmailCode = () => {
    if (form.getFieldError('email').length !== 0) {
      return;
    }
    const email = form.getFieldValue('email');
    if (!email) {
      return message.warn('이메일을 입력해 주세요.');
    }
    sendEmailCode(form.getFieldValue('email'), {
      onSuccess: () => {
        startTimer();
      },
    });
  };
  const handleEmailVerify = () => {
    verifyEmail(
      {
        email: form.getFieldValue('email'),
        authKey: form.getFieldValue('authKey'),
      },
      {
        onSuccess: (res) => {
          if (res.data.result) {
            setEmailVerified(true);
            resetTimer();
            message.success('이메일 인증 성공');
          } else {
            message.warn('올바르지 않은 인증코드');
          }
        },
      },
    );
  };

  const { checkEmail } = useCheckEmail();

  return (
    <Container>
      <Logo src={logo} alt="logo" />
      <Form
        form={form}
        onFinish={handleSignup}
        onFieldsChange={(fields) => {
          const name = fields[0].name;
          if (Array.isArray(name) && name[0] === 'email') {
            if (activated) {
              resetTimer();
            }
            setEmailVerified(false);
          }
        }}
      >
        <Form.Item
          name="username"
          rules={[
            requiredRule,
            {
              pattern: /^[A-Z0-9-_]{4,}$/i,
              message: '영문, 숫자, 기호(-, _) / 4글자 이상',
            },
          ]}
        >
          <Input placeholder="아이디" maxLength={12} />
        </Form.Item>
        <Form.Item name="password" rules={[requiredRule]}>
          <Input.Password placeholder="비밀번호" maxLength={20} />
        </Form.Item>
        <Form.Item name="name" rules={[requiredRule, exceptNumberRule]}>
          <Input placeholder="이름" maxLength={50} />
        </Form.Item>
        <Form.Item name="cellPhone" rules={[requiredRule, phoneNumberRule]}>
          <PhoneNumberInput placeholder="휴대폰 번호" />
        </Form.Item>
        <Form.Item name="companyDepartmentName" rules={[requiredRule]}>
          <Input placeholder="부서" maxLength={50} />
        </Form.Item>
        <Form.Item name="companyPositionName" rules={[requiredRule]}>
          <Input placeholder="직급" maxLength={50} />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.email !== next.email}
        >
          {({ getFieldError }) => (
            <Form.Item noStyle>
              {!activated ? (
                <Form.Item
                  name="email"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!value) {
                          throw new Error(REQUIRED_FIELD);
                        }
                        if (!adminEmailRule.pattern.test(value)) {
                          throw new Error(adminEmailRule.message);
                        }
                        checkEmail(value, {
                          onSuccess: (response) => {
                            const isDuplicated = response.data.result;
                            if (isDuplicated)
                              return Promise.reject('중복된 이메일');
                          },
                        });
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="이메일"
                    suffix={
                      !emailVerified ? (
                        <Button
                          disabled={getFieldError('email').length > 0}
                          size="small"
                          onClick={handleSendEmailCode}
                          loading={isLoadingSendEmail}
                        >
                          인증코드 전송
                        </Button>
                      ) : null
                    }
                  />
                </Form.Item>
              ) : (
                <Form.Item name="email">
                  <Input
                    placeholder="이메일 인증 코드"
                    suffix={
                      <Button size="small" onClick={handleSendEmailCode}>
                        재전송
                      </Button>
                    }
                  />
                </Form.Item>
              )}
              {activated && (
                <Form.Item name="authKey" preserve={false}>
                  <Input
                    placeholder="이메일 인증 코드"
                    suffix={
                      <Row align="middle" gutter={8}>
                        <Col>
                          <Typography.Text
                            style={{ color: theme.colors.PRIMARY }}
                          >
                            {min > 0 && `${min}분 `}
                            {sec}초
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Button
                            size="small"
                            loading={isLoadingVerifyEmail}
                            onClick={handleEmailVerify}
                          >
                            인증 확인
                          </Button>
                        </Col>
                      </Row>
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          onClick={() => form.submit()}
          block
          size="large"
          style={{ marginBottom: 8, marginTop: 16 }}
        >
          등록
        </Button>
        <Button
          type="default"
          block
          size="large"
          onClick={() => setIsSignup(false)}
        >
          취소
        </Button>
      </Form>
    </Container>
  );
};

export default Signup;
