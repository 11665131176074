import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useEffect } from 'react';

import SessionDatePicker from './SessionDatePicker';
import Editor from 'components/ui/Editor';
import AddressInput from 'components/ui/AddressInput';
import SessionOpenDatePicker from './SessionOpenDatePicker';
import { emailRule, requiredRule } from 'shared/rule';
import { scrollToFirstErrorOption } from 'shared/form';
import SessionBannerImageSelect from './SessionBannerImageSelect/SessionBannerImageSelect';
import PhoneNumberInput from 'components/ui/PhoneNumberInput';
import { ISessionForm, ISessionGet } from 'type/session';
import SessionHostCompanySelect from './SessionHostCompanySelect';
import BusinessTypeSelection from '../BusinessTypeSelection';
import { useInterests } from 'core/query/common';
import { useCompanyDepartments } from 'core/query/company';
import { REQUIRED_FIELD } from 'shared/message';

const SessionForm = ({
  session,
  submitBtnText,
  onSubmit,
  submitLoading,
}: {
  session?: ISessionGet;
  submitBtnText: string;
  onSubmit: (session: ISessionForm) => void;
  submitLoading: boolean;
}) => {
  const interests = useInterests();
  const [form] = Form.useForm<ISessionForm>();
  const companyDepartments = useCompanyDepartments();
  useEffect(() => {
    if (session) {
      form.setFieldsValue({
        ...session,
        businessTypeIds: session.businessTypes.map(
          ({ businessTypeId }) => businessTypeId,
        ),
        sessionHostCompanyId: session.sessionHostCompany.sessionHostCompanyId,
        sessionBannerImageId: session.sessionBannerImage.sessionBannerImageId,
        interestIds: session.interests.map(({ interestId }) => interestId),
      });
    }
  }, [session, form]);
  return (
    <Form
      form={form}
      colon={false}
      labelCol={{ span: 4 }}
      onFinish={onSubmit}
      scrollToFirstError={scrollToFirstErrorOption}
    >
      <Typography.Title
        style={{ fontSize: 16, marginBottom: 16, fontWeight: 500 }}
      >
        세션 기본 정보
      </Typography.Title>
      <Form.Item label="세션 타이틀" name="sessionTitle" rules={[requiredRule]}>
        <Input maxLength={70} style={{ width: 360 }} />
      </Form.Item>
      <Form.Item label="세션 진행 일시" required>
        <Form.List name="sessionProgressTimes" initialValue={[undefined]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row key={field.key} gutter={8}>
                  <Col>
                    <Form.Item
                      name={[field.name]}
                      rules={[
                        requiredRule,
                        {
                          validator: async (
                            _,
                            progressTime?: { startDt: string; endDt: string },
                          ) => {
                            if (!progressTime) return;
                            const { startDt, endDt } = progressTime;
                            // HINT: 세션 오픈 일시가 같은 경우에만 열리도록. (현재는 안 쓰지만 필요시 아래 코드 사용.)
                            // if (!moment(startDt).isSame(endDt, 'day')) {
                            //   throw new Error('서로 다른 날짜');
                            // }
                            if (
                              moment(startDt).add(30, 'minutes').isAfter(endDt)
                            ) {
                              throw new Error('최소 진행 시간: 30분');
                            }
                          },
                        },
                      ]}
                    >
                      <SessionDatePicker />
                    </Form.Item>
                  </Col>
                  {field.name !== 0 && (
                    <Col>
                      <MinusCircleOutlined
                        style={{ position: 'relative', top: 6 }}
                        onClick={() => remove(field.name)}
                      />
                    </Col>
                  )}
                </Row>
              ))}
              {fields.length < 5 && (
                <Button
                  type="dashed"
                  onClick={() => add(undefined)}
                  style={{ width: 360 }}
                  icon={<PlusOutlined />}
                >
                  날짜 추가
                </Button>
              )}
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item label="참가비" name="entryFee" rules={[requiredRule]}>
        <InputNumber
          controls={false}
          style={{ width: 120 }}
          formatter={(value) => Number(value).toLocaleString() || ''}
          min={0}
        />
      </Form.Item>
      <Form.Item label="세션 개최 장소" name="address" rules={[requiredRule]}>
        <AddressInput style={{ maxWidth: 460 }} />
      </Form.Item>
      <Form.Item label=" " name="addressDetail">
        <Input placeholder="상세 주소 입력" style={{ maxWidth: 360 }} />
      </Form.Item>
      <Form.Item
        label="세션 최대 인원"
        name="maxCapacity"
        initialValue={10}
        rules={[
          requiredRule,
          {
            validator: async (_, value) => {
              if (value && session && session.sessionApplicantCount > value) {
                throw new Error('세션 신청자 수 보다 작음');
              }
            },
          },
        ]}
      >
        <InputNumber style={{ width: 120 }} min={1} />
      </Form.Item>
      <Form.Item
        label="키워드"
        name="interestIds"
        initialValue={[]}
        rules={[requiredRule]}
      >
        <Select
          options={interests.map(({ interestId, name }) => ({
            label: name,
            value: interestId,
          }))}
          mode="multiple"
          style={{ width: 360 }}
        />
      </Form.Item>
      <Form.Item
        label="대표 이미지 선택"
        name="sessionBannerImageId"
        valuePropName="sessionBannerImageId"
        rules={[requiredRule]}
      >
        <SessionBannerImageSelect />
      </Form.Item>
      <Form.Item
        label="모집 시작일"
        name="applicationStartDate"
        rules={[requiredRule]}
      >
        <SessionOpenDatePicker />
      </Form.Item>
      <Form.Item
        label="세션 대상 업종"
        initialValue={[]}
        name="businessTypeIds"
        rules={[requiredRule]}
      >
        <BusinessTypeSelection style={{ width: 360 }} />
      </Form.Item>
      <Form.Item label="세션 정보" name="description" rules={[requiredRule]}>
        <Editor
          imageUploadType="SESSION"
          initialValue={session?.description}
          maxCharCount={20000}
          maxHeight={720}
        />
      </Form.Item>
      <Divider />
      <Typography.Title
        style={{
          fontSize: 16,
          marginTop: 24,
          marginBottom: 16,
          fontWeight: 500,
        }}
      >
        세션 주최 회사
      </Typography.Title>
      <Form.Item
        label="회사 선택"
        name="sessionHostCompanyId"
        valuePropName="sessionHostCompanyId"
        rules={[requiredRule]}
      >
        <SessionHostCompanySelect />
      </Form.Item>
      <Divider />
      <Typography.Title
        style={{
          fontSize: 16,
          marginTop: 24,
          marginBottom: 16,
          fontWeight: 500,
        }}
      >
        요청 실무자 정보
      </Typography.Title>
      <Form.Item
        label="실무자 이름"
        name={['sessionHostCompanyManager', 'managerName']}
        rules={[requiredRule]}
      >
        <Input style={{ width: 360 }} />
      </Form.Item>
      <Form.Item
        label="연락처"
        name={['sessionHostCompanyManager', 'cellPhone']}
        rules={[
          requiredRule,
          {
            pattern: /^[0-9]{1,11}$/,
            message: '올바르지 않은 전화번호 형식',
          },
        ]}
      >
        <PhoneNumberInput style={{ width: 200 }} type="phone" />
      </Form.Item>
      <Form.Item
        label="이메일"
        name={['sessionHostCompanyManager', 'email']}
        rules={[requiredRule, emailRule]}
      >
        <Input style={{ width: 360 }} maxLength={100} />
      </Form.Item>
      <Form.Item
        label="부서"
        name={['sessionHostCompanyManager', 'companyDepartmentId']}
        required
        rules={[
          {
            validator: async (_, value) => {
              if (typeof value === 'undefined') {
                throw new Error(REQUIRED_FIELD);
              }
            },
          },
        ]}
      >
        <Select
          options={[
            ...companyDepartments.map(
              ({ companyDepartmentId, companyDepartmentName }) => ({
                label: companyDepartmentName,
                value: companyDepartmentId,
              }),
            ),
            { label: '직접입력', value: null },
          ]}
          style={{ width: 360 }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          prev.sessionHostCompanyManager?.companyDepartmentId !==
          next.sessionHostCompanyManager?.companyDepartmentId
        }
      >
        {({ getFieldValue }) =>
          getFieldValue([
            'sessionHostCompanyManager',
            'companyDepartmentId',
          ]) === null && (
            <Form.Item
              label=" "
              name={['sessionHostCompanyManager', 'companyDepartmentName']}
              rules={[requiredRule]}
              required={false}
            >
              <Input placeholder="부서명 직접입력" style={{ width: 360 }} />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item
        label="직급"
        name={['sessionHostCompanyManager', 'positionName']}
        rules={[requiredRule]}
      >
        <Input style={{ width: 360 }} maxLength={50} />
      </Form.Item>
      <Row justify="center" style={{ marginTop: 32 }}>
        <Col>
          <Button
            loading={submitLoading}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: 120 }}
          >
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SessionForm;
