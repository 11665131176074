import { SyncOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Col, Modal, Row, Table, Typography } from 'antd';
import { useState } from 'react';
import { QueryClientProvider, useQueryClient } from 'react-query';

import SearchInput from 'components/ui/SearchInput';
import SessionReviewList from './SessionReviewList';
import { getModalContainer } from 'util/modal';
import SessionAttendSituation from './SessionAttendSituation';
import { useDoneSessions, useSessionControl } from 'core/query/session';
import Switch from 'components/ui/Switch';

const Container = styled.div``;

const DoneSessionList = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const {
    doneSessionsInfo: { content: doneSessions, totalElements },
    refetch,
  } = useDoneSessions({
    page,
    searchKeyword,
  });
  const { sessionControl } = useSessionControl();

  const handleShowSessionReview = (sessionId: number, sessionTitle: string) => {
    Modal.info({
      width: 720,
      title: (
        <Typography.Text style={{ fontSize: '18px', fontWeight: 700 }}>
          {sessionTitle}
        </Typography.Text>
      ),
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <SessionReviewList sessionId={sessionId} />
        </QueryClientProvider>
      ),
      getContainer: getModalContainer,
    });
  };
  const handleShowAttendSituation = (
    sessionId: number,
    isCloseSession: boolean,
  ) => {
    const modal = Modal.info({
      width: 720,
      title: '참여자 선택',
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <SessionAttendSituation
            sessionId={sessionId}
            isCloseSession={isCloseSession}
            onClose={() => {
              modal.destroy();
            }}
            onSubmit={() => refetch()}
          />
        </QueryClientProvider>
      ),
      getContainer: getModalContainer,
    });
  };
  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        종료된 세션
      </Typography.Title>
      <Row justify="space-between" style={{ marginBottom: 8 }}>
        <Col>
          <Button icon={<SyncOutlined />} onClick={() => refetch()}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <SearchInput
                defaultValue={searchKeyword}
                onSearch={setSearchKeyword}
                placeholder="세션 타이틀 검색"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        size="small"
        rowKey="sessionId"
        dataSource={doneSessions}
        pagination={{
          current: page,
          onChange: setPage,
          pageSize: 10,
          total: totalElements,
        }}
        columns={[
          { title: '세션 번호', dataIndex: 'sessionId', align: 'center' },
          {
            title: '세션 타이틀',
            align: 'center',
            render: (_, { sessionId, sessionTitle }) => (
              <Typography.Link
                onClick={() => handleShowSessionReview(sessionId, sessionTitle)}
              >
                {sessionTitle}
              </Typography.Link>
            ),
          },

          {
            title: '세션 진행 일시',
            align: 'center',
            width: '20%',
            render: (_, { sessionProgressTimes }) => (
              <Row gutter={[0, 4]} wrap={true} justify="center">
                {sessionProgressTimes.map(
                  ({ sessionProgressTimeId, startDt, endDt }) => (
                    <Col key={sessionProgressTimeId}>
                      <Typography.Text>
                        {startDt} ~ {endDt.slice(11)}
                      </Typography.Text>
                    </Col>
                  ),
                )}
              </Row>
            ),
          },
          {
            title: '참여 현황',
            align: 'center',
            render: (
              _,
              { sessionApplicantCount, sessionAttendCount, isCloseSession },
            ) =>
              `${
                isCloseSession ? sessionAttendCount : '-'
              } / ${sessionApplicantCount}`,
          },
          {
            title: '세션 On/Off',
            align: 'center',
            render: (_, { sessionId, isDisplay }) => (
              <Switch
                active={isDisplay}
                onChange={() =>
                  sessionControl({
                    sessionId,
                    isDisplay: !isDisplay,
                  })
                }
              />
            ),
          },
          {
            align: 'center',
            render: (_, { sessionId, isCloseSession }) => (
              <Button
                onClick={() =>
                  handleShowAttendSituation(sessionId, isCloseSession)
                }
              >
                참여자 선택
              </Button>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default DoneSessionList;
