import { IInterest } from './common';
import { IBusinessType } from './company';

export enum ESessionStatus {
  ONGOING = 'ONGOING',
  DONE = 'DONE',
}

export interface ISessionBannerImage {
  sessionBannerImageId: number;
  imageUrl: string;
}

export enum ESessionHostCompanySearchType {
  COMPANY_NAME = 'COMPANY_NAME',
  CEO_NAME = 'CEO_NAME',
}
export interface ISessionHostCompany {
  sessionHostCompanyId: number;
  companyName: string;
  businessTypes: IBusinessType[];
  businessTypeIds?: number[];
  ceoName: string;
  address: string;
  addressDetail: string | null;
  description: string;
  ongoingSessionCount: number;
  doneSessionCount: number;
}

export interface ISessionHostCompanyManager {
  sessionHostCompanyManagerId?: number;
  managerName: string;
  cellPhone: string;
  email: string;
  companyDepartmentId?: number;
  companyDepartmentName?: string;
  positionName: string;
}

export interface ISessionProgressTime {
  sessionProgressTimeId?: number;
  startDt: string;
  endDt: string;
}

export interface ISessionApplicants {
  sessionApplicantId: number;
  name: string;
  cellPhone: string;
  companyName: string;
  companyDepartmentName: string;
  companyPositionName: string;
  requestMessage: string;
  isAttend: boolean;
}

export interface ISession {
  sessionTitle: string;
  applicationStartDate: string;
  sessionProgressTimes: ISessionProgressTime[];
  address: string;
  addressDetail: string | null;
  description: string;
  sessionHostCompanyManager: ISessionHostCompanyManager;
  maxCapacity: number;
  entryFee: number;
}

export interface ISessionForm extends ISession {
  sessionHostCompanyId?: number;
  sessionBannerImageId: number;
  interestIds: number[];
  businessTypeIds: number[];
}

export interface ISessionGet extends ISession {
  sessionId: number;
  sessionApplicantCount: number;
  sessionBannerImage: ISessionBannerImage;
  sessionHostCompany: ISessionHostCompany;
  interests: IInterest[];
  businessTypes: IBusinessType[];
  status: ESessionStatus;
  isDisplay: boolean;
}

export interface ISessionListItem extends ISessionGet {
  sessionAttendCount: number;
  isCloseSession: boolean;
}

export interface ISessionUpdate
  extends Omit<Partial<ISessionForm>, 'sessionHostCompanyManager'> {
  sessionHostCompanyManager?: Partial<ISessionHostCompanyManager>;
}

export interface ISessionCSManager {
  sessionCSManagerId: number;
  profileImageUrl: string;
  name: string;
}
export interface ISessionCSManagerForm extends ISessionCSManager {
  profileImage: File;
}

export enum ESessionReviewStatus {
  NORMAL = 'NORMAL',
  DELETE = 'ADMIN_DELETE',
}

export interface ISessionReview {
  sessionReviewId: number;
  rating: number;
  description: string;
  status: ESessionReviewStatus;
  registerDt: string;
  sessionApplicant: ISessionApplicant;
}

export interface ISessionApplicant {
  sessionApplicantId: number;
  name: string;
  cellPhone: string;
  companyName: string;
  isAttend: boolean | null;
}
