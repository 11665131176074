import { CustomScrollAction } from 'scroll-into-view-if-needed/typings/types';

export const scrollToFirstErrorOption = {
  behavior: (actions: CustomScrollAction[]) => {
    const { el, top }: { el: Element; top: number } = { ...actions?.[0] };
    if (el && el.scrollTop > top) {
      el.scrollTop = top - 80;
    }
  },
};
