import { useParams } from 'react-router-dom';
import { Button, Card, message, Modal, Typography } from 'antd';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { useBoardNotice } from 'core/query/community';
import { ReactComponent as BackArrow } from 'assets/back-arrow.svg';
import { theme } from 'styles/theme';
import { EBoardType } from 'type/board';
import { boardTypeMap } from 'shared/consts';
import history from 'util/history';
import EditorViewer from '../../ui/EditorViewer';
import BoardAttach from '../BoardAttach';
import { Flex } from 'components/ui';

const Container = styled.div``;

const BoardCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

const BoardContent = styled.div``;

const BoardNotice = ({ boardType }: { boardType: EBoardType }) => {
  const boardNoticeId = Number(useParams().id);
  const { boardNoticeDetail, deleteBoardNotice, deleteLoading } =
    useBoardNotice({ boardNoticeId });

  if (!boardNoticeDetail) return <div>loading...</div>;

  const handleDelete = () => {
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      onOk: () => {
        deleteBoardNotice(boardNoticeId, {
          onSuccess: () => {
            history.replace(`/community/${boardType}`);
            message.success('삭제되었습니다.');
          },
        });
      },
    });
  };

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        {boardTypeMap[boardType]}
      </Typography.Title>
      <Card>
        <BackArrow
          onClick={() => history.back()}
          style={{ cursor: 'pointer', marginBottom: 10 }}
        />
        <BoardCard
          title={<Typography.Text>{boardNoticeDetail.title}</Typography.Text>}
          headStyle={{ background: theme.colors.PRIMARYGREEN10 }}
        >
          <Flex
            justify="space-between"
            style={{
              padding: '8px 16px',
              borderBottom: `1px solid ${theme.colors.SLATEGRAY10}`,
            }}
          >
            <div>관리자</div>
            <div>{boardNoticeDetail.registerDt?.slice(0, 10)}</div>
          </Flex>
          <BoardContent style={{ padding: 16 }}>
            <EditorViewer content={boardNoticeDetail.content} />
            <Flex justify="space-between">
              <div>
                {boardNoticeDetail.attaches.length > 0 && (
                  <BoardAttach attaches={boardNoticeDetail.attaches} />
                )}
              </div>
              <Flex gap={8}>
                <Button loading={deleteLoading} onClick={handleDelete}>
                  삭제
                </Button>
                <Link to={'update'} state={{ boardType, mode: 'update' }}>
                  <Button type="primary">수정</Button>
                </Link>
              </Flex>
            </Flex>
          </BoardContent>
        </BoardCard>
      </Card>
    </Container>
  );
};

export default BoardNotice;
