import { useState, useMemo } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';

import { useAdCalendarCounts } from 'core/query/ad';
import { IProductCount, IAdCalendarCategory, EAdBannerType } from 'type/ad';
import AdCalendar, { getCategoryByCode } from '../AdCalendar';
import AdOngoingCompanyListModal from './AdOngoingCompanyListModal';

const Container = styled.div`
  margin-top: 16px;
`;

const categories: IAdCalendarCategory[] = [
  {
    name: '배너 광고',
    code: EAdBannerType.PB001,
    badgeStatus: 'success',
    limit: 10,
  },
];

const AdBannerCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [ongoingCompanyListModalDate, setOngoingCompanyListModalDate] =
    useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<
    [string | undefined, string | undefined] | []
  >([]);
  const [startDate, endDate] = dateRange;
  const { adCalendarCounts } = useAdCalendarCounts({
    startDate,
    endDate,
    advertisingType: 'BANNER',
  });

  const handlePanelChange = (startDate: string, endDate: string) => {
    setDateRange([startDate, endDate]);
  };

  const calendarData = useMemo(() => {
    const productCounts = (productCounts: IProductCount[]) => {
      const newCountBadges = [];

      for (const productCount of productCounts) {
        const category = getCategoryByCode(
          categories,
          productCount.product.code,
        );
        if (!category || productCount.count <= 0) continue;

        newCountBadges.push({
          badgeStatus: category.badgeStatus,
          count: productCount.count,
          isOverLimit: productCount.count >= category.limit,
        });
      }

      return newCountBadges;
    };

    return adCalendarCounts.map((adCalendarCount) => ({
      date: moment(adCalendarCount.date, 'YYYY-MM-DD'),
      countBadges: productCounts(adCalendarCount.productCounts),
    }));
  }, [adCalendarCounts]);

  return (
    <Container>
      {ongoingCompanyListModalDate && (
        <AdOngoingCompanyListModal
          date={ongoingCompanyListModalDate}
          onClose={() => setOngoingCompanyListModalDate(undefined)}
        />
      )}
      <AdCalendar
        onDateClick={(date: string) => setOngoingCompanyListModalDate(date)}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        onPanelChange={handlePanelChange}
        categories={categories}
        data={calendarData}
      />
    </Container>
  );
};

export default AdBannerCalendar;
