import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import qs from 'qs';

import { NO_NEED_TO_UPDATE } from 'shared/message';
import { IAPIPageableResponse, IAPIResponse, IInterest } from 'type/common';
import { IBusinessType } from 'type/company';

import {
  ESessionHostCompanySearchType,
  ISessionApplicant,
  ISessionBannerImage,
  ISessionCSManager,
  ISessionCSManagerForm,
  ISessionForm,
  ISessionGet,
  ISessionHostCompany,
  ISessionHostCompanyManager,
  ISessionListItem,
  ISessionProgressTime,
  ISessionReview,
  ISessionUpdate,
} from 'type/session';
import api from 'util/api';
import { createFormData, getUpdatingObject } from 'util/form';
import { base64ToFile } from 'util/file';

const isBusinessTypesChanged = (
  businessTypeIds: number[],
  businessTypes: IBusinessType[],
) => {
  if (
    businessTypeIds.length !== businessTypes.length ||
    businessTypes.some(
      ({ businessTypeId }) => !businessTypeIds.includes(businessTypeId),
    )
  ) {
    return true;
  }
  return false;
};

export const useSessionBannerImages = () => {
  const { data: sessionBannerImages = [] } = useQuery(
    ['session', 'sessionBannerImages'],
    () => api.get<IAPIResponse<ISessionBannerImage[]>>('/v1/banner-images'),
    { select: (res) => res.data.result, staleTime: Number.MAX_SAFE_INTEGER },
  );
  return sessionBannerImages;
};

export const useOpenSession = () => {
  const { mutate: openSession, isLoading } = useMutation(
    (session: ISessionForm) =>
      api.post('/v1/session', {
        ...session,
        sessionProgressTimes: session.sessionProgressTimes.map(
          ({ startDt, endDt }) => ({
            startDt: startDt.replace(' ', 'T'),
            endDt: endDt.replace(' ', 'T'),
          }),
        ),
      }),
  );

  return useMemo(() => ({ openSession, isLoading }), [openSession, isLoading]);
};

export const useSession = (sessionId: number) => {
  const queryClient = useQueryClient();
  const { data: session = null } = useQuery(
    ['session', sessionId],
    () => api.get<IAPIResponse<ISessionGet>>(`/v1/sessions/${sessionId}`),
    { select: (res) => res.data.result },
  );

  const { mutate: updateSession, isLoading: updateLoading } = useMutation(
    ({
      sessionTitle,
      applicationStartDate,
      address,
      addressDetail,
      description,
      sessionHostCompanyManager,
      maxCapacity,
      entryFee,
      sessionHostCompanyId,
      sessionBannerImageId,
      sessionProgressTimes,
      interestIds,
      businessTypeIds,
    }: ISessionForm) => {
      if (session === null) {
        throw new Error('Invalid session');
      }

      const updatingSession: ISessionUpdate = {
        ...getUpdatingObject(
          {
            sessionTitle,
            applicationStartDate,
            address,
            addressDetail,
            description,
            maxCapacity,
            entryFee,
          },
          session,
        ),
        ...(sessionBannerImageId !==
          session.sessionBannerImage.sessionBannerImageId && {
          sessionBannerImageId,
        }),
        ...(sessionHostCompanyId !==
          session.sessionHostCompany.sessionHostCompanyId && {
          sessionHostCompanyId,
        }),
        sessionHostCompanyManager: {
          companyDepartmentId: sessionHostCompanyManager.companyDepartmentId,
        },
      };
      const updatingSessionHostCompanyManager: Partial<ISessionHostCompanyManager> =
        getUpdatingObject(
          sessionHostCompanyManager,
          session.sessionHostCompanyManager,
        ) || {};

      if (Object.keys(updatingSessionHostCompanyManager).length > 0) {
        updatingSession.sessionHostCompanyManager = {
          ...updatingSessionHostCompanyManager,
          ...updatingSession.sessionHostCompanyManager,
        };
      }

      const isSessionProgressTimesChanged = (
        newTimes: ISessionProgressTime[],
        originalTimes: ISessionProgressTime[],
      ) => {
        if (newTimes.length !== originalTimes.length) return true;
        for (let i = 0; i < newTimes.length; i++) {
          if (
            newTimes[i].startDt !== originalTimes[i].startDt ||
            newTimes[i].endDt !== originalTimes[i].endDt
          ) {
            return true;
          }
        }
        return false;
      };
      if (
        isSessionProgressTimesChanged(
          sessionProgressTimes,
          session.sessionProgressTimes,
        )
      ) {
        updatingSession.sessionProgressTimes = sessionProgressTimes.map(
          ({ startDt, endDt }) => ({
            startDt: startDt.replace(' ', 'T'),
            endDt: endDt.replace(' ', 'T'),
          }),
        );
      }
      const isInterestsChanged = (
        interestIds: number[],
        originalInterests: IInterest[],
      ) => {
        if (
          interestIds.length !== originalInterests.length ||
          originalInterests.some(
            ({ interestId }) => !interestIds.includes(interestId),
          )
        ) {
          return true;
        }
        return false;
      };
      if (isInterestsChanged(interestIds, session.interests)) {
        updatingSession.interestIds = interestIds;
      }
      if (isBusinessTypesChanged(businessTypeIds, session.businessTypes)) {
        updatingSession.businessTypeIds = businessTypeIds;
      }

      if (Object.keys(updatingSession).length === 0) {
        message.info(NO_NEED_TO_UPDATE);
        throw new Error();
      }

      return api.patch(`/v1/sessions/${sessionId}`, updatingSession);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['session', 'ongoingSessions']);
      },
    },
  );

  return useMemo(
    () => ({ session, updateSession, updateLoading }),
    [session, updateSession, updateLoading],
  );
};

export const useOngoingSessions = ({
  page,
  searchKeyword,
}: {
  page: number;
  searchKeyword: string;
}) => {
  const {
    data: ongoingSessionsInfo = { content: [], totalElements: 0 },
    refetch,
  } = useQuery(
    ['session', 'ongoingSessions', page, searchKeyword],
    () =>
      api.get<IAPIPageableResponse<ISessionListItem[]>>(
        `/v1/ongoing-sessions?${qs.stringify({
          page,
          sessionTitle: searchKeyword,
          size: 10,
        })}`,
      ),
    { select: (res) => res.data.result },
  );

  return useMemo(
    () => ({ ongoingSessionsInfo, refetch }),
    [ongoingSessionsInfo, refetch],
  );
};

export const useCloseSession = () => {
  const queryClient = useQueryClient();
  const { mutate: closeSession } = useMutation(
    (sessionId: number) => api.patch(`/v1/sessions/${sessionId}/done`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['session', 'ongoingSessions']);
      },
    },
  );
  return closeSession;
};

export const useDoneSessions = ({
  page,
  searchKeyword,
}: {
  page: number;
  searchKeyword: string;
}) => {
  const {
    data: doneSessionsInfo = { content: [], totalElements: 0 },
    refetch,
  } = useQuery(
    ['session', 'doneSessions', page, searchKeyword],
    () =>
      api.get<IAPIPageableResponse<ISessionListItem[]>>(
        `/v1/done-sessions?${qs.stringify({
          page,
          size: 10,
          sessionTitle: searchKeyword,
        })}`,
      ),
    { select: (res) => res.data.result },
  );

  return useMemo(
    () => ({ doneSessionsInfo, refetch }),
    [doneSessionsInfo, refetch],
  );
};

export const useSessionCSManager = () => {
  const { data: sessionCSManager = null, refetch } = useQuery(
    ['session', 'sessionCSManager'],
    () => api.get<IAPIResponse<ISessionCSManager>>('/v1/cs-manager'),
    { select: (res) => res.data.result },
  );
  const { mutate: addSessionCSManager } = useMutation(
    ({ name, profileImage }: ISessionCSManagerForm) =>
      api.post('/v1/cs-manager', createFormData({ name, profileImage })),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
  const { mutate: updateSessionCSManager } = useMutation(
    ({ name, profileImage }: ISessionCSManagerForm) => {
      if (!sessionCSManager) {
        throw new Error('Invalid sessionCSManager');
      }
      const updatingSessionCSManager: Partial<ISessionCSManagerForm> = {
        ...(name !== sessionCSManager.name && { name }),
        ...(profileImage instanceof File && { profileImage }),
      };
      if (Object.keys(updatingSessionCSManager).length === 0) {
        message.warn(NO_NEED_TO_UPDATE);
        throw new Error();
      }
      return api.patch(
        '/v1/cs-manager',
        createFormData(updatingSessionCSManager),
      );
    },
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return useMemo(
    () => ({
      sessionCSManager,
      addSessionCSManager,
      updateSessionCSManager,
    }),
    [sessionCSManager, addSessionCSManager, updateSessionCSManager],
  );
};

export const useSessionHostCompanies = (searchParams: {
  page: number;
  businessTypeId: number | undefined;
  searchType: ESessionHostCompanySearchType;
  searchKeyword: string;
}) => {
  const queryClient = useQueryClient();
  const {
    data: sessionHostCompaniesInfo = {
      content: [],
      totalElements: 0,
    },
    refetch,
  } = useQuery(
    ['session', 'sessionHostCompanies', searchParams],
    () =>
      api.get<IAPIPageableResponse<ISessionHostCompany[]>>(
        '/v1/host-companies',
        { params: { ...searchParams, size: 10 } },
      ),
    { select: (res) => res.data.result },
  );

  const { mutate: addSessionHostCompany } = useMutation(
    (sessionHostCompany: ISessionHostCompany) =>
      api.post('/v1/host-company', sessionHostCompany),
  );
  const { mutate: updateSessionHostCompany } = useMutation(
    ({
      sessionHostCompanyId,
      companyName,
      businessTypeIds,
      ceoName,
      address,
      addressDetail,
      description,
    }: ISessionHostCompany) => {
      const {
        data: { result: originalSessionHostCompany },
      } = queryClient.getQueryData([
        'session',
        'sessionHostCompany',
        sessionHostCompanyId,
      ]) as AxiosResponse<IAPIResponse<ISessionHostCompany>>;
      if (!originalSessionHostCompany) {
        throw new Error('Invalid sessionHostCompany');
      }
      const updatingSessionHostCompany: Partial<ISessionHostCompany> = {
        ...getUpdatingObject(
          {
            companyName,
            description,
            ceoName,
            address,
            addressDetail,
          },
          originalSessionHostCompany,
        ),
      };
      if (
        isBusinessTypesChanged(
          businessTypeIds || [],
          originalSessionHostCompany.businessTypes,
        )
      ) {
        updatingSessionHostCompany.businessTypeIds = businessTypeIds;
      }
      if (Object.keys(updatingSessionHostCompany).length === 0) {
        message.warn(NO_NEED_TO_UPDATE);
        throw new Error();
      }
      return api.patch(
        `/v1/host-companies/${sessionHostCompanyId}`,
        updatingSessionHostCompany,
      );
    },
  );
  return useMemo(
    () => ({
      sessionHostCompaniesInfo,
      addSessionHostCompany,
      updateSessionHostCompany,
      refetch,
    }),
    [
      sessionHostCompaniesInfo,
      addSessionHostCompany,
      updateSessionHostCompany,
      refetch,
    ],
  );
};

export const useSessionHostCompany = (sessionHostCompanyId?: number) => {
  const { data: sessionHostCompany = null } = useQuery(
    ['session', 'sessionHostCompany', sessionHostCompanyId],
    () =>
      api.get<IAPIResponse<ISessionHostCompany>>(
        `/v1/host-companies/${sessionHostCompanyId}`,
      ),
    {
      enabled: typeof sessionHostCompanyId !== 'undefined',
      select: (res) => res.data.result,
    },
  );
  return sessionHostCompany;
};

export const useSessionReviews = ({
  sessionId,
  page,
}: {
  sessionId: number;
  page: number;
}) => {
  const {
    data: sessionReviewsInfo = { content: [], totalElements: 0 },
    refetch,
  } = useQuery(
    ['session', sessionId, 'sessionReviews', page],
    () =>
      api.get<IAPIPageableResponse<ISessionReview[]>>(
        `/v1/sessions/${sessionId}/reviews?page=${page}&size=10`,
      ),
    { select: (res) => res.data.result },
  );

  const { mutate: deleteSessionReview } = useMutation(
    (sessionReviewId: number) =>
      api.delete(`/v1/session/reviews/${sessionReviewId}`),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return useMemo(
    () => ({ sessionReviewsInfo, deleteSessionReview }),
    [sessionReviewsInfo, deleteSessionReview],
  );
};

export const useSessionApplicants = (sessionId: number) => {
  const { data: sessionApplicants = [] } = useQuery(
    ['session', 'sessionApplicants', sessionId],
    () =>
      api.get<IAPIResponse<ISessionApplicant[]>>(
        `/v1/sessions/${sessionId}/applicants`,
      ),
    { select: (res) => res.data.result },
  );
  const { mutate: changeSessionAttends } = useMutation(
    ({
      attendIds,
      isCloseSession,
    }: {
      attendIds: number[];
      isCloseSession: boolean;
    }) => {
      const originalAttendIds = sessionApplicants
        .filter(({ isAttend }) => isAttend)
        .map(({ sessionApplicantId }) => sessionApplicantId);

      const sessionAttends = [
        ...attendIds
          .filter((attendId) => !originalAttendIds.includes(attendId))
          .map((attendId) => ({
            sessionApplicantId: attendId,
            isAttend: true,
          })),
        ...originalAttendIds
          .filter((originalAttendId) => !attendIds.includes(originalAttendId))
          .map((attendId) => ({
            sessionApplicantId: attendId,
            isAttend: false,
          })),
      ];
      if (isCloseSession && sessionAttends.length === 0) {
        message.warn(NO_NEED_TO_UPDATE);
        return Promise.reject();
      }

      return api.patch(`/v1/sessions/${sessionId}/attends`, sessionAttends);
    },
  );
  return useMemo(
    () => ({ sessionApplicants, changeSessionAttends }),
    [sessionApplicants, changeSessionAttends],
  );
};

export const useSessionApplicantDocument = (sessionId: number) => {
  const { data: sessionApplicantDocument = null } = useQuery(
    ['session', 'sessionApplicantDocument', sessionId],
    () =>
      api.get<IAPIResponse<string>>(
        `/v1/sessions/${sessionId}/applicant-document`,
      ),
    {
      select: (res) => {
        return base64ToFile(
          `data:application/pdf;base64,${res.data.result}`,
          '세션 참여 예정자.pdf',
        );
      },
    },
  );
  return sessionApplicantDocument;
};

export const useSessionControl = () => {
  const queryClient = useQueryClient();

  const { mutate: sessionControl, isLoading: isSessionControlLoading } =
    useMutation(
      ({ sessionId, isDisplay }: { sessionId: number; isDisplay: boolean }) =>
        api.patch<IAPIResponse<null>>(`/v1/sessions/${sessionId}/display`, {
          isDisplay,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries('session');
          message.success('변경되었습니다.');
        },
      },
    );

  return {
    sessionControl,
    isSessionControlLoading,
  };
};
