import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-query';
import { getYYYYMMDDs } from 'util/day';
import { message } from 'antd';

import { IAPIPageableResponse, IAPIResponse } from 'type/common';
import {
  AdDataType,
  EAdLocationCode,
  EPeriodRangeType,
  IAdCompany,
  IAdStats,
  IPeriod,
  IStatisticsDataWithProductCode,
} from 'type/stats';
import api from 'util/api';
import { getClick, getExposure, getRate } from 'util/stats';
import { theme } from 'styles/theme';
import { base64ToFile, downloadFile } from 'util/file';

export const useAdBannerCompanies = ({
  advertisingType,
  page,
  companyName,
  bizNumber,
}: {
  advertisingType: 'BANNER' | 'MATERIAL';
  page: number;
  companyName?: string;
  bizNumber?: string;
}) => {
  const { data } = useQuery(
    ['advertising-companies', companyName, bizNumber],
    () =>
      api.get<IAPIPageableResponse<IAdCompany[]>>(`/v1/advertising-companies`, {
        params: {
          advertisingType,
          page,
          companyName,
          bizNumber,
          size: 10,
        },
      }),
    {
      select: (res) => res.data.result,
    },
  );

  return {
    adBannerCompanies: data?.content || [],
    total: data?.totalElements || 0,
  };
};

export const useAdCompany = (companyId: number) => {
  const { data: adCompany } = useQuery(
    [],
    () =>
      api.get<IAPIResponse<IAdCompany>>(
        `/v1/advertising-companies/${companyId}`,
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return adCompany;
};

const bannerDatasetProps = [
  {
    label: EAdLocationCode.ALL,
    borderColor: theme.colors.PRIMARYGREEN60,
  },
  {
    label: EAdLocationCode.SESSION_MAIN,
    borderColor: theme.colors.SLATEGRAY60,
  },
  {
    label: EAdLocationCode.COMMUNITY_MAIN,
    borderColor: theme.colors.SLATEGRAY60,
  },
];

export function useAdStats({
  advertisingCompanyId,
  periodType,
  period,
  dataType,
  advertisingType,
  page,
  size,
  chartType = 'GRAPH',
}: {
  advertisingCompanyId: number;
  periodType: EPeriodRangeType;
  period: IPeriod;
  dataType: AdDataType;
  advertisingType: 'BANNER' | 'MATERIAL';
  page?: number;
  size?: number;
  chartType?: 'GRAPH' | 'RAW';
}) {
  const { data, isLoading } = useQuery(
    [
      'statistics/advertising-companies',
      advertisingCompanyId,
      period.from,
      period.to,
      page,
      size,
      periodType,
      advertisingType,
    ],
    () =>
      api.get<IAPIPageableResponse<IAdStats[]>>(
        `/v1/statistics/advertising-companies/${advertisingCompanyId}/statistics`,
        {
          params: {
            advertisingType,
            from: period.from,
            to: period.to,
            page,
            size,
          },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const adStats = useMemo(() => {
    if (data) {
      return data.content;
    }

    return [];
  }, [data]);

  const adMap = adStats.reduce(
    (preMap, { statisticsDate, statisticsDataWithProductCode }) =>
      preMap.set(statisticsDate, statisticsDataWithProductCode),
    new Map<string, IStatisticsDataWithProductCode>(),
  );

  const adMonthMap = adStats.reduce(
    (preMap, { statisticsDate, statisticsDataWithProductCode }) => {
      const month = statisticsDate.slice(0, 7);
      const value = preMap.get(month);
      if (value) {
        return preMap.set(month, value.concat(statisticsDataWithProductCode));
      }

      return preMap.set(month, [statisticsDataWithProductCode]);
    },
    new Map<string, IStatisticsDataWithProductCode[]>(),
  );

  const allLabels = useMemo(() => {
    if (periodType === EPeriodRangeType.MONTH) {
      const yyyymmdds = getYYYYMMDDs(period.from, period.to, 'months');
      return yyyymmdds.map((yyyymmdd) => yyyymmdd.slice(0, 7));
    }
    const yyyymmdds = getYYYYMMDDs(period.from, period.to, 'days');
    return yyyymmdds.length > 31 ? [] : yyyymmdds;
  }, [periodType, period]);

  const tableLabels = useMemo(() => {
    if (periodType === EPeriodRangeType.MONTH) {
      const yyyymmdds = getYYYYMMDDs(period.from, period.to, 'months');
      return yyyymmdds.map((yyyymmdd) => yyyymmdd.slice(0, 7));
    }
    return adStats.map(({ statisticsDate }) => statisticsDate);
  }, [adStats, periodType, period]);

  const getPBData = (
    locationCode: EAdLocationCode,
    dataType: AdDataType,
    label: string[],
  ) => {
    if (periodType === EPeriodRangeType.MONTH) {
      return label.map((key) => {
        const value = adMonthMap.get(key);
        if (value) {
          return value.reduce((prev, statisticsData) => {
            if (dataType === 'rate') {
              getRate(locationCode, statisticsData);
            }

            if (dataType === 'exposureCount') {
              return prev + getExposure(locationCode, statisticsData);
            }

            return prev + getClick(locationCode, statisticsData);
          }, 0);
        }

        // HINT: 해당 날짜에 데이터가 없는 경우.
        return NaN;
      });
    }

    return label.map((key) => {
      const statisticsData = adMap.get(key);
      if (statisticsData) {
        if (dataType === 'rate') {
          return getRate(locationCode, statisticsData);
        }

        if (dataType === 'exposureCount') {
          return getExposure(locationCode, statisticsData);
        }

        return getClick(locationCode, statisticsData);
      }

      return NaN;
    });
  };

  const getDataLabels = (labels: string[]) =>
    labels.map((key) => {
      const value = adMap.get(key);
      if (value) {
        if (value['PB001']) {
          return value['PB001'][0].purchaseCode;
        }
      }

      return '';
    });

  const datasets = bannerDatasetProps.map((props) => ({
    ...props,
    borderWidth: 2,
    backgroundColor: 'rgb(0,0,0,0)',
    ...(props.label === EAdLocationCode.COMMUNITY_MAIN && {
      borderDash: [10, 5],
    }),
    fill: true,
    pointBorderWidth: 1,
    pointBackgroundColor: '#fff',
    data: getPBData(
      props.label,
      dataType,
      chartType === 'GRAPH' ? allLabels : tableLabels,
    ),
    datalabels: getDataLabels(chartType === 'GRAPH' ? allLabels : tableLabels),
  }));

  return {
    labels: allLabels,
    tableLabels,
    datasets,
    isLoading,
  };
}

export const useAdStatExcel = () => {
  const { mutate: getAdStatExcel, isLoading } = useMutation(
    ({
      advertisingType,
      companyId,
    }: {
      advertisingType: 'BANNER' | 'MATERIAL';
      companyId: number;
      bizNumber?: string;
    }) =>
      api.get<IAPIResponse<string | null>>(
        `/v1/statistics/advertising-companies/${companyId}/statistic-excel`,
        {
          params: {
            advertisingType,
          },
        },
      ),
    {
      onSuccess: (res, { advertisingType, bizNumber }) => {
        const binaryData = res.data.result;
        const date = new Date();
        const month = date.getMonth();
        const yyyymmdd = `${date.getFullYear()}${
          month < 9 ? '0' + (month + 1) : month + 1
        }${date.getDate()}`;
        const filename = `Data_${
          advertisingType === 'BANNER' ? 'ad' : 'searched'
        }_${bizNumber}_${yyyymmdd}.xlsx`;

        if (binaryData) {
          const file = base64ToFile(
            `data:application/xlsx;base64,${binaryData}`,
            filename,
          );
          downloadFile(file);
        } else {
          message.warn('통계 데이터가 없습니다.');
        }
      },
    },
  );

  return {
    getAdStatExcel,
    isLoading,
  };
};
