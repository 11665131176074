import styled from '@emotion/styled';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Modal, Row, Select, Table, Typography } from 'antd';

import SearchInput from 'components/ui/SearchInput';
import { reportTypeMap } from 'shared/consts';
import { EReportKind, EReportStatus, EReportType } from 'type/common';
import useShowMemberDetail from 'util/hooks/useShowMemberDetail';
import { useBoardReports, useWithdrawReport } from 'core/query/report';

const Container = styled.div`
  padding-top: 24px;
`;

const BoardReportList = () => {
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] =
    useState<Parameters<typeof useBoardReports>[0]['searchType']>(
      'titleOrContent',
    );
  const [searchKeyword, setSearchKeyword] = useState('');
  const showMemberDetail = useShowMemberDetail();
  const { boardReports, totalElements } = useBoardReports({
    page,
    searchType,
    searchKeyword,
  });
  const withdrawReport = useWithdrawReport();
  const handleShowMember = (memberId: number) => {
    showMemberDetail(memberId);
  };
  const handleWithdraw = (reportId: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title style={{ fontSize: 18 }}>
            신고를 정말로 철회하시겠습니까?
          </Typography.Title>
          <Typography.Text>철회한 신고는 되돌릴 수 없습니다.</Typography.Text>
        </>
      ),
      okText: '철회',
      onOk: () => {
        withdrawReport({ reportId, reportKind: EReportKind.BOARD });
      },
    });
  };
  return (
    <Container>
      <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
        <Col>
          <Typography.Title style={{ fontSize: 16 }}>글 신고</Typography.Title>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <Select
                value={searchType}
                options={[
                  { label: '제목+내용', value: 'titleOrContent' },
                  { label: '작성자', value: 'writtenUsername' },
                ]}
                onChange={setSearchType}
              />
            </Col>
            <Col>
              <SearchInput
                defaultValue={searchKeyword}
                onSearch={setSearchKeyword}
                placeholder="검색어 입력"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        pagination={{ current: page, onChange: setPage, total: totalElements }}
        rowKey="reportId"
        columns={[
          {
            title: '제목',
            width: '44%',
            render: (_, { boardId, boardType, title }) => (
              <Link to={`/community/${boardType}/${boardId}`}>{title}</Link>
            ),
          },
          {
            title: '신고 사유',
            align: 'center',
            width: '10%',
            render: (_, { reportType, reportReason }) =>
              reportType !== EReportType.ETC ? (
                <Typography.Text>{reportTypeMap[reportType]}</Typography.Text>
              ) : (
                <Button
                  onClick={() => {
                    Modal.success({ icon: null, content: reportReason });
                  }}
                >
                  사유 보기
                </Button>
              ),
          },
          {
            title: '작성자',
            align: 'center',
            width: '12%',
            render: (_, { boardMemberId, boardUsername }) => (
              <Button
                type="link"
                size="small"
                onClick={() => handleShowMember(boardMemberId)}
              >
                {boardUsername}
              </Button>
            ),
          },
          {
            title: '신고자',
            align: 'center',
            width: '12%',
            render: (_, { reportMemberId, reportUsername }) => (
              <Button
                type="link"
                size="small"
                onClick={() => handleShowMember(reportMemberId)}
              >
                {reportUsername}
              </Button>
            ),
          },
          {
            title: '작성일',
            align: 'center',
            width: '8%',
            render: (_, { registerDt }) => registerDt?.slice(0, 10),
          },
          {
            width: '8%',
            align: 'center',
            render: (_, { reportId, withdrawDt, status }) =>
              status === EReportStatus.NORMAL ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleWithdraw(reportId)}
                >
                  신고 철회
                </Button>
              ) : (
                withdrawDt?.slice(0, 10)
              ),
          },
        ]}
        dataSource={boardReports}
      />
    </Container>
  );
};

export default BoardReportList;
