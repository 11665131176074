import styled from '@emotion/styled';

interface IFlexProps {
  dir?: React.CSSProperties['flexDirection'];
  align?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
  flex?: number | string;
  wrap?: string;
  textAlign?: string;
  gap?: number;
  rowGap?: number;
  columnGap?: number;
}

const defaultProps = {
  dir: 'row' as const,
  align: 'flex-start',
  justify: 'normal',
  flex: 'none',
  textAlign: 'unset',
  wrap: 'false',
};

const Flex = styled.div<IFlexProps>`
  display: flex;
  flex-direction: ${(props) => props.dir};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex: ${(props) => (props.flex ? props.flex : 'none')};
  gap: ${(props) => props.gap && `${props.gap}px`};
  row-gap: ${(props) => props.rowGap && `${props.rowGap}px`};
  column-gap: ${(props) => props.columnGap && `${props.columnGap}px`};
  flex-wrap: ${({ wrap }) => (wrap === 'true' ? 'wrap' : 'unset')};
`;

Flex.defaultProps = defaultProps;

export default Flex;
