import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, message, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Flex from 'components/ui/Flex';
import { IAdCompanyListItem } from 'type/ad';
import { useAdCompanyListItems, useDeleteAdCompany } from 'core/query/ad';
import AdRegisterModal from './AdCompanyRegisterModal';

const Container = styled(Flex)`
  .ant-table-tbody svg {
    cursor: pointer;
  }
`;

const AdCompanyList = () => {
  const [visibleRegisterModal, setVisibleRegisterModal] = useState(false);
  const [page, setPage] = useState(1);
  const { adCompanyListItems, totalElements } = useAdCompanyListItems({
    page,
  });

  const toggleRegisterModal = useCallback(() => {
    setVisibleRegisterModal((draft) => !draft);
  }, []);
  const { mutate } = useDeleteAdCompany();

  const deleteAdCompany = useCallback(
    (companyId: number) => {
      mutate(companyId, {
        onSuccess: () => {
          message.success('삭제되었습니다.');
        },
      });
    },
    [mutate],
  );

  const columns: ColumnsType<IAdCompanyListItem> = useMemo(
    () => [
      {
        title: 'No.',
        width: '5%',
        align: 'center',
        render: (_, __, index) => adCompanyListItems.length - index,
      },
      {
        title: '회사(소속)명',
        width: '45%',
        align: 'center',
        dataIndex: 'companyName',
      },
      {
        title: '사업자등록번호',
        width: '25%',
        align: 'center',
        dataIndex: 'bizNumber',
      },
      {
        title: '등록일',
        width: '15%',
        align: 'center',
        dataIndex: 'registerDt',
        render: (_, { registerDt }) => registerDt.slice(0, 10),
      },
      {
        width: '10%',
        align: 'center',
        render: (_, { companyId }) => (
          <DeleteOutlined onClick={() => deleteAdCompany(companyId)} />
        ),
      },
    ],
    [adCompanyListItems, deleteAdCompany],
  );

  return (
    <Container dir="column">
      {visibleRegisterModal && (
        <AdRegisterModal onClose={toggleRegisterModal} />
      )}
      <Typography.Title style={{ fontSize: 18 }}>
        광고 진행 회사
      </Typography.Title>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ alignSelf: 'end' }}
        onClick={toggleRegisterModal}
      >
        광고 진행 회사 추가
      </Button>
      <Table
        style={{ width: '100%', marginTop: 16 }}
        loading={false}
        dataSource={adCompanyListItems}
        pagination={{
          current: page,
          onChange: setPage,
          total: totalElements,
        }}
        columns={columns}
        rowKey="companyId"
      />
    </Container>
  );
};

export default AdCompanyList;
