/* eslint-disable @typescript-eslint/no-explicit-any */

export function getUpdatingObject<T>(
  newObject: T,
  originObject: any,
): Partial<T> | undefined {
  const updatedObject: Partial<T> = {};
  (Object.keys(newObject) as Array<keyof T>).forEach((key) => {
    const newValue = newObject[key];
    const originValue = originObject[key];
    if (newValue !== originValue) {
      updatedObject[key] = newValue;
    }
  });
  return Object.keys(updatedObject).length > 0 ? updatedObject : undefined;
}

export const createFormData = (object: any) => {
  const formData = new FormData();
  for (const key in object) {
    const value = object[key];
    if (value instanceof FileList) {
      Array.prototype.forEach.call(value, (file) => {
        formData.append(key, file);
      });
    } else if (value instanceof Array) {
      value.forEach((element) => {
        formData.append(key, element);
      });
    } else if (value instanceof File) {
      formData.append(key, value);
    } else {
      formData.set(key, value);
    }
  }
  return formData;
};
