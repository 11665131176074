import styled from '@emotion/styled';
import { Button, Col, Input, Modal, Row } from 'antd';
import DaumPostcode, { Address } from 'react-daum-postcode';

const Container = styled.div``;

const AddressInput = ({
  value,
  style,
  onChange,
}: {
  value?: string;
  style?: React.CSSProperties;
  onChange?: (value: string) => void;
}) => {
  const handleCompleteInput = (address: string) => {
    onChange && onChange(address);
  };
  return (
    <Container style={style}>
      <Row gutter={8}>
        <Col flex="auto">
          <Input value={value} readOnly />
        </Col>
        <Col>
          <Button
            style={{ width: 92 }}
            onClick={() => {
              const modal = Modal.info({
                icon: null,
                content: (
                  <DaumPostcode
                    onComplete={({ address }: Address) => {
                      handleCompleteInput(address);
                      modal.destroy();
                    }}
                  />
                ),
                closable: true,
                maskClosable: true,
                okButtonProps: {
                  hidden: true,
                },
              });
            }}
          >
            주소 검색
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddressInput;
