import { message } from 'antd';

import { useSession } from 'core/query/session';
import { UPDATE_SUCCESS } from 'shared/message';
import { ISessionForm } from 'type/session';
import SessionForm from '../SessionForm';

const SessionDetail = ({
  sessionId,
  onClose,
}: {
  sessionId: number;
  onClose: () => void;
}) => {
  const { session, updateSession, updateLoading } = useSession(sessionId);
  const handleUpdate = (session: ISessionForm) => {
    updateSession(session, {
      onSuccess: () => {
        message.success(UPDATE_SUCCESS);
        onClose();
      },
    });
  };
  if (session === null) {
    return null;
  }
  return (
    <SessionForm
      session={session}
      onSubmit={handleUpdate}
      submitBtnText="수정"
      submitLoading={updateLoading}
    />
  );
};

export default SessionDetail;
