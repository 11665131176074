export const theme = {
  colors: {
    WHITE: '#fff',
    BLACK20: '#222',
    GRAY20: '#A9A6A6',
    GRAY70: '#aaa',
    GRAY80: '#ddd',
    PRESSED: '#F7F8F9',

    SLATEGRAY10: '#F2F4F6',
    SLATEGRAY20: '#E2E4E8',
    SLATEGRAY30: '#CBCFD3',
    SLATEGRAY40: '#B0B7BC',
    SLATEGRAY50: '#949DA3',
    SLATEGRAY60: '#646F7C',
    SLATEGRAY70: '#495056',
    SLATEGRAY80: '#393E43',
    SLATEGRAY90: '#2B2E31',
    SLATEGRAY100: '#1E2124',

    SUCCESS: '#4CAF50',
    ERROR: '#D32F2F',
    WARNING: '#F9A825',

    PRIMARYGREEN10: '#EFF8F3',
    PRIMARYGREEN20: '#C9E7D5',
    PRIMARYGREEN30: '#82C69A',
    PRIMARYGREEN40: '#66B782',
    PRIMARYGREEN50: '#4F9967',
    PRIMARYGREEN60: '#00A74C',
    PRIMARYGREEN70: '#00803A',
    PRIMARYGREEN80: '#004D23',
    PRIMARYGREEN90: '#003317',
    PRIMARYGREEN100: '#001A0B',

    PRIMARY: '#00a74c',

    BLUE10: '#EEF7FF',
    BLUE50: '#2498FF',
    BLUE60: '#007FF0',

    RED50: '#F22003',
  },
};
