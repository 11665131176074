import { useEffect } from 'react';
import styled from '@emotion/styled';
import { Calendar, Select, Badge, Typography } from 'antd';
import moment, { Moment } from 'moment';

import { theme } from 'styles/theme';
import { AdCalendarBadgeStatus, IAdCalendarCategory } from 'type/ad';
import Flex from 'components/ui/Flex';

const CalendarHeader = styled.div`
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
`;

const CalendarContainer = styled.div`
  padding: 44px 37px 34px 37px;
  background-color: #fff;

  .ant-picker-calendar {
    .ant-picker-calendar-date-content {
      padding-top: 4px;
    }

    .ant-picker-cell-selected {
      .ant-picker-calendar-date {
        border-color: ${theme.colors.PRIMARY};
      }
    }

    // HINT: 현재 날짜가 선택됬을 경우 테두리 스타일 제거
    .ant-picker-cell-in-view.ant-picker-cell-today.ant-picker-cell-selected
      .ant-picker-cell-inner::before {
      border: none;
    }
  }
`;

// HINT: 카테고리에서 코드에 해당하는 카테고리를 반환합니다.
export const getCategoryByCode = (
  categories: IAdCalendarCategory[],
  code: string,
) => {
  const category = categories.find(
    (c) => c.code.toLocaleLowerCase() === code.toLocaleLowerCase(),
  );

  // HINT: 원본을 바꾸지 못하도록 복사본 반환
  return (
    category && {
      ...category,
    }
  );
};

const getStartDateAndEndDateOnThePanel = (date: Moment) => {
  const startDate = moment(date).startOf('month');
  const endDate = moment(date).endOf('month');

  const startDateDay = startDate.day();
  startDate.subtract(startDateDay, 'days');
  endDate.add(42 - Number(endDate.format('DD')) - startDateDay, 'days');

  return {
    startDate,
    endDate,
  };
};

const makeOptions = (selectedDate: Moment) => {
  const monthOptions = [];
  const yearOptions = [];
  const months = [];

  const current = selectedDate.clone();
  const localeData = selectedDate.localeData();
  const year = selectedDate.year();
  const month = selectedDate.month();

  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let i = year - 10; i < year + 10; i++) {
    yearOptions.push(
      <Select.Option key={i} value={i}>
        {i}
      </Select.Option>,
    );
  }

  for (let i = 0; i < 12; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i}>
        {months[i]}
      </Select.Option>,
    );
  }

  return { yearOptions, month, year, monthOptions };
};

const AdCalendar = ({
  selectedDate,
  setSelectedDate,
  categories,
  data,
  onPanelChange,
  onDateClick,
}: {
  selectedDate: Moment;
  setSelectedDate: (selectedDate: Moment) => void;
  categories: IAdCalendarCategory[];
  data: {
    date: Moment;
    countBadges: {
      badgeStatus: AdCalendarBadgeStatus;
      count: number;
      isOverLimit?: boolean;
    }[];
  }[];
  onPanelChange: (startDate: string, endDate: string) => void;
  onDateClick?: (date: string) => void;
}) => {
  const { month, year, monthOptions, yearOptions } = makeOptions(selectedDate);

  const handleDateClick = (date: string) => () => {
    onDateClick?.(date);
  };

  const handlePanelChange = (date: Moment) => {
    const { startDate, endDate } = getStartDateAndEndDateOnThePanel(date);

    onPanelChange(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  };

  useEffect(() => {
    handlePanelChange(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CalendarContainer>
      <Calendar
        value={selectedDate}
        onSelect={setSelectedDate}
        onPanelChange={handlePanelChange}
        headerRender={({ onChange }: { onChange: (date: Moment) => void }) => (
          <CalendarHeader>
            <div>
              <Select
                onChange={(newYear) => {
                  const now = selectedDate.clone().year(newYear);
                  onChange(now);
                }}
                value={year}
                style={{ marginRight: 8 }}
              >
                {yearOptions}
              </Select>
              <Select
                value={month}
                onChange={(newMonth) => {
                  const now = selectedDate.clone().month(newMonth);
                  onChange(now);
                }}
              >
                {monthOptions}
              </Select>
            </div>
            <Flex columnGap={8}>
              {categories.map((category) => (
                <Flex align="center" key={category.name}>
                  <Badge status={category.badgeStatus} text={category.name} />
                </Flex>
              ))}
            </Flex>
          </CalendarHeader>
        )}
        dateFullCellRender={(date) => {
          const currentData = data.find((d) =>
            moment(d.date.format('YYYY-MM-DD')).isSame(
              date.format('YYYY-MM-DD'),
            ),
          );

          const content = currentData
            ? currentData.countBadges.map(
                ({ badgeStatus, isOverLimit, count }, countBadgeIdx) => (
                  <Flex columnGap={8} align="center" key={countBadgeIdx}>
                    <Badge
                      status={badgeStatus}
                      text={
                        <Typography.Text
                          type="secondary"
                          style={{
                            display: 'inline-block',
                            color: isOverLimit ? '#ff4d4f' : 'inherit',
                            fontWeight: 'bold',
                          }}
                        >
                          {count}개
                        </Typography.Text>
                      }
                    />
                  </Flex>
                ),
              )
            : '';

          // HINT: antd 캘린더가 그리는 html을 그대로 가져와 content 파트에 커스텀 내용 추가
          return (
            <div
              className="ant-picker-cell-inner ant-picker-calendar-date"
              onClick={handleDateClick(date.format('YYYY-MM-DD'))}
            >
              <div className="ant-picker-calendar-date-value">
                {date.format('DD')}
              </div>
              <div className="ant-picker-calendar-date-content">{content}</div>
            </div>
          );
        }}
      />
    </CalendarContainer>
  );
};

export default AdCalendar;
