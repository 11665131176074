import { SyncOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Col, Modal, Row, Table, Typography } from 'antd';
import { useState } from 'react';
import { QueryClientProvider, useQueryClient } from 'react-query';

import SearchInput from 'components/ui/SearchInput';
import SessionCSManager from '../SessionCSManager';
import { getModalContainer } from 'util/modal';
import SessionDetail from './SessionDetail';
import {
  useCloseSession,
  useOngoingSessions,
  useSessionControl,
} from 'core/query/session';
import SessionApplicantDocument from './SessionApplicantDocument';
import Switch from 'components/ui/Switch';

const Container = styled.div``;

const OngoingSessionList = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const {
    ongoingSessionsInfo: { content: ongoingSessions, totalElements },
    refetch,
  } = useOngoingSessions({
    page,
    searchKeyword,
  });
  const { sessionControl } = useSessionControl();
  const closeSession = useCloseSession();

  const handleShowSession = (sessionId: number) => {
    const modal = Modal.info({
      width: 920,
      closable: true,

      content: (
        <QueryClientProvider client={queryClient}>
          <SessionDetail
            sessionId={sessionId}
            onClose={() => {
              modal.destroy();
            }}
          />
        </QueryClientProvider>
      ),
      getContainer: getModalContainer,
    });
  };
  const handleCloseSession = (sessionId: number) => {
    Modal.confirm({
      title: '정말로 세션을 종료하시겠습니까?',
      icon: null,
      onOk: () => closeSession(sessionId),
    });
  };
  const handleShowSessionApplicantDocument = (sessionId: number) => {
    Modal.info({
      title: '세션 참여 예정자 목록',
      width: 1000,
      maskClosable: true,
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <SessionApplicantDocument sessionId={sessionId} />
        </QueryClientProvider>
      ),
    });
  };

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        진행 중인 세션
      </Typography.Title>
      <Row style={{ marginBottom: 8 }} justify="space-between">
        <Col>
          <Button icon={<SyncOutlined />} onClick={() => refetch()}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <SessionCSManager />
            </Col>
            <Col>
              <SearchInput
                defaultValue={searchKeyword}
                onSearch={setSearchKeyword}
                placeholder="세션 타이틀 검색"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        size="small"
        dataSource={ongoingSessions}
        rowKey="sessionId"
        pagination={{
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: totalElements,
        }}
        columns={[
          { title: '세션 번호', dataIndex: 'sessionId', align: 'center' },
          {
            title: '세션 타이틀',
            align: 'center',
            render: (_, { sessionId, sessionTitle }) => (
              <Typography.Link onClick={() => handleShowSession(sessionId)}>
                {sessionTitle}
              </Typography.Link>
            ),
          },
          {
            title: '모집 시작일',
            dataIndex: 'applicationStartDate',
            align: 'center',
          },
          {
            title: '세션 진행 일시',
            align: 'center',
            width: '20%',
            render: (_, { sessionProgressTimes }) => (
              <Row gutter={[0, 4]} wrap={true} justify="center">
                {sessionProgressTimes.map(
                  ({ sessionProgressTimeId, startDt, endDt }) => (
                    <Col key={sessionProgressTimeId}>
                      <Typography.Text>
                        {startDt} ~ {endDt.slice(11)}
                      </Typography.Text>
                    </Col>
                  ),
                )}
              </Row>
            ),
          },
          {
            title: '신청 현황',
            align: 'center',
            render: (_, { sessionId, sessionApplicantCount, maxCapacity }) => (
              <Typography.Link
                onClick={() => handleShowSessionApplicantDocument(sessionId)}
              >
                {sessionApplicantCount} / {maxCapacity}
              </Typography.Link>
            ),
          },
          {
            title: '세션 On/Off',
            align: 'center',
            render: (_, { sessionId, isDisplay }) => (
              <Switch
                active={isDisplay}
                onChange={() =>
                  sessionControl({
                    sessionId,
                    isDisplay: !isDisplay,
                  })
                }
              />
            ),
          },
          {
            align: 'center',
            render: (_, { sessionId }) => (
              <Button
                type="primary"
                onClick={() => handleCloseSession(sessionId)}
              >
                세션 종료
              </Button>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default OngoingSessionList;
