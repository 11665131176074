import { Button, message, Upload } from 'antd';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { DownloadOutlined } from '@ant-design/icons';

import { useUploadFiles } from 'core/query/common';
import { EAttachType, IAttach } from 'type/common';
import { UploadFile } from 'antd/lib/upload/interface';
import { downloadFileFromUrl } from 'util/file';
import UploadItem from './UploadItem';

const Container = styled.div`
  width: 400px;
`;

const AttachUpload = ({
  attaches = [],
  onChange,
  maxCount = 1,
  attachType,
}: {
  attaches?: IAttach[];
  onChange?: (attaches: IAttach[]) => void;
  attachType: EAttachType;
  maxCount?: number;
}) => {
  const { uploadFiles, isLoading } = useUploadFiles();
  const fileList: UploadFile[] = useMemo(
    () =>
      attaches.map(({ attachId, filename, uploadFileUrl }) => ({
        uid: String(attachId),
        name: filename,
        status: 'done',
        url: uploadFileUrl,
      })),
    [attaches],
  );
  const handleDeleteFile = (attachId: number) => {
    onChange &&
      onChange(attaches.filter((attach) => attach.attachId !== attachId));
  };

  return (
    <Container>
      <Upload
        fileList={fileList}
        maxCount={maxCount}
        name="files"
        multiple={maxCount > 1}
        beforeUpload={(file, files) => {
          if (attaches.length === maxCount) {
            message.warn(`최대 ${maxCount}개의 파일만 업로드 가능합니다.`);
            return false;
          }
          const maxFiles = files.slice(0, maxCount);
          if (file.uid === maxFiles[maxFiles.length - 1].uid) {
            uploadFiles(
              { files: maxFiles, attachType },
              {
                onSuccess: (res) => {
                  onChange && onChange([...attaches, ...res.data.result]);
                },
              },
            );
          }
          return false;
        }}
        showUploadList={{
          showDownloadIcon: true,
          downloadIcon: <DownloadOutlined />,
        }}
        itemRender={(_, file) => (
          <UploadItem attach={file} onRemove={handleDeleteFile} />
        )}
        onDownload={(file) => {
          console.debug(file.name);
          file.url && downloadFileFromUrl(file.url, file.name);
        }}
      >
        <Button loading={isLoading}>파일 선택</Button>
      </Upload>
    </Container>
  );
};

export default AttachUpload;
