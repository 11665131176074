import { UserOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import { useSessionCSManager } from 'core/query/session';
import SessionCSManagerForm from './SessionCSManagerForm/SessionCSManagerForm';

const SessionCSManager = () => {
  const { sessionCSManager, addSessionCSManager, updateSessionCSManager } =
    useSessionCSManager();
  const handleShowSessionCSManager = () => {
    const modal = Modal.info({
      title: '담당자 정보',
      closable: true,
      width: 520,
      content: (
        <SessionCSManagerForm
          sessionCSManager={sessionCSManager}
          onSubmit={(sessionCSManagerForm) => {
            if (!sessionCSManager) {
              addSessionCSManager(sessionCSManagerForm, {
                onSuccess: () => {
                  modal.destroy();
                },
              });
            } else {
              updateSessionCSManager(sessionCSManagerForm, {
                onSuccess: () => {
                  modal.destroy();
                },
              });
            }
          }}
        />
      ),
    });
  };
  return (
    <Button icon={<UserOutlined />} onClick={handleShowSessionCSManager}>
      담당자 정보
    </Button>
  );
};

export default SessionCSManager;
