import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Col, Row, Transfer, Typography } from 'antd';

import { useSessionApplicants } from 'core/query/session';

const Container = styled.div``;

const SessionAttendSituation = ({
  sessionId,
  isCloseSession,
  onSubmit,
  onClose,
}: {
  sessionId: number;
  isCloseSession: boolean;
  onSubmit: () => void;
  onClose: () => void;
}) => {
  const { sessionApplicants, changeSessionAttends } =
    useSessionApplicants(sessionId);
  const [attendKeys, setAttendKeys] = useState<string[]>([]);
  const handleSelectAll = () => {
    setAttendKeys(
      sessionApplicants.map(({ sessionApplicantId }) =>
        sessionApplicantId.toString(),
      ),
    );
  };
  const handleDeselectAll = () => {
    setAttendKeys([]);
  };
  const handleSubmit = () => {
    changeSessionAttends(
      {
        attendIds: attendKeys.map((attendKey) => Number(attendKey)),
        isCloseSession,
      },
      {
        onSuccess: () => {
          onSubmit();
          onClose();
        },
      },
    );
  };

  useEffect(() => {
    setAttendKeys(
      sessionApplicants
        .filter(({ isAttend }) => isAttend)
        .map(({ sessionApplicantId }) => sessionApplicantId.toString()),
    );
  }, [sessionApplicants]);
  return (
    <Container>
      <Transfer
        dataSource={sessionApplicants}
        rowKey={({ sessionApplicantId }) => sessionApplicantId.toString()}
        targetKeys={attendKeys}
        onChange={setAttendKeys}
        locale={{ itemUnit: '명', itemsUnit: '명' }}
        titles={[
          <Button
            key="selectAll"
            style={{ height: 32 }}
            type="link"
            onClick={handleSelectAll}
          >
            모두 참여
          </Button>,
          <Button
            key="deselectAll"
            style={{ height: 32 }}
            type="link"
            onClick={handleDeselectAll}
          >
            모두 불참
          </Button>,
        ]}
        listStyle={{
          width: '100%',
          height: 500,
        }}
        render={({ name, companyName, cellPhone }) => (
          <Typography.Text>
            {name} [{companyName} / {cellPhone.slice(-4)}]
          </Typography.Text>
        )}
        operations={['참여', '불참']}
        style={{ width: '100%' }}
      />
      <Row style={{ marginTop: 16 }} justify="center">
        <Col>
          <Button type="primary" size="large" onClick={handleSubmit}>
            선택 완료
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SessionAttendSituation;
