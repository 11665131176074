import { useEffect, useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
} from 'antd';
import moment, { Moment } from 'moment';

import {
  useAddBeautyFair,
  useBeautyFairCountry,
  useGetBeautyFairDetail,
  useUpdateBeautyFair,
} from 'core/query/business';
import { generateFormRules } from 'shared/rule';
import { Flex } from 'components/ui';

export interface IBeautyFairForm {
  beautyFairCountryId?: number;
  fairLink: string;
  fairVenueName: string;
  isExpectFair: 'expected' | 'notExpected';
  datePicker?: Moment;
  rangePicker?: Moment[];
  title: string;
  subtitle: string;
  cityName: string;
}

const LinkInputWrapper = styled.div`
  position: relative;
`;

const BeautyFairAddModal = ({
  onClose,
  beautyFairScheduleId,
}: {
  beautyFairScheduleId?: number;
  onClose: () => void;
}) => {
  const [form] = Form.useForm<IBeautyFairForm>();
  const [linkUrl, setLinkUrl] = useState('');
  const { beautyFairCountries, getLoading } = useBeautyFairCountry();
  const { addBeautyFair, addLoading } = useAddBeautyFair();
  const { beautyFairDetail, isLoading } = useGetBeautyFairDetail({
    beautyFairScheduleId,
  });
  const { updateBeautyFair, updateLoading } = useUpdateBeautyFair({
    beautyFairScheduleId,
  });

  const updateMode = beautyFairDetail?.beautyFairCountryId !== undefined;
  useEffect(() => {
    if (beautyFairDetail) {
      form.setFieldsValue({
        beautyFairCountryId: beautyFairDetail.beautyFairCountryId,
        fairLink: beautyFairDetail?.fairLink,
        fairVenueName: beautyFairDetail.fairVenueName,
        isExpectFair: beautyFairDetail?.isExpectFair
          ? 'expected'
          : 'notExpected',
        cityName: beautyFairDetail?.cityName,
        title: beautyFairDetail?.title,
        subtitle: beautyFairDetail?.subtitle,
        datePicker: moment(beautyFairDetail.startDate).month('month'),
        rangePicker: [
          moment(beautyFairDetail.startDate, 'YYYY-MM-DD'),
          moment(beautyFairDetail.endDate, 'YYYY-MM-DD'),
        ],
      });
      setLinkUrl(beautyFairDetail?.fairLink);
    }
  }, [beautyFairDetail, form]);

  const handleSubmit = ({
    beautyFairCountryId,
    datePicker,
    rangePicker,
    isExpectFair,
    ...rest
  }: IBeautyFairForm) => {
    let startDate, endDate;

    if (datePicker) {
      startDate = datePicker.startOf('month').format('YYYY-MM-DD');
      endDate = datePicker.endOf('month').format('YYYY-MM-DD');
    } else if (rangePicker) {
      [startDate, endDate] = rangePicker.map((date) =>
        date.format('YYYY-MM-DD'),
      );
    }

    if (!startDate || !endDate) return;

    if (updateMode) {
      if (!beautyFairCountryId) return;

      updateBeautyFair(
        {
          ...rest,
          beautyFairCountryId,
          isExpectFair: isExpectFair === 'expected',
          startDate,
          endDate,
        },
        {
          onSuccess: () => {
            message.success('수정되었습니다.');
            onClose();
          },
        },
      );
    } else {
      addBeautyFair(
        {
          ...rest,
          beautyFairCountryId,
          isExpectFair: isExpectFair === 'expected',
          startDate,
          endDate,
        },
        {
          onSuccess: () => {
            message.success('등록되었습니다.');
            onClose();
          },
        },
      );
    }
  };

  const beautyFairCountryOptions = beautyFairCountries.map(
    ({ beautyFairCountryId, countryName }) => ({
      label: countryName,
      value: beautyFairCountryId,
    }),
  );

  const handleClickUpload = async () => {
    try {
      await form.validateFields();

      Modal.confirm({
        style: { marginTop: 100 },
        content: `박람회 일정을 ${updateMode ? '수정' : '업로드'}하시겠습니까?`,
        onOk: form.submit,
      });
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const loading = isLoading || updateLoading || addLoading;

  return (
    <Modal
      width={800}
      title="박람회 일정"
      maskClosable
      visible
      footer={
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={handleClickUpload}
        >
          {updateMode ? '수정' : '업로드'}
        </Button>
      }
      onCancel={onClose}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          required
          labelCol={{ span: 3 }}
          label="기간"
          style={{ marginBottom: 0 }}
        >
          <Input.Group>
            <Flex>
              <Form.Item
                name="isExpectFair"
                rules={generateFormRules({ required: true })}
              >
                <Radio.Group>
                  <Radio value="expected">예정</Radio>
                  <Radio value="notExpected">확정</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  prev.isExpectFair !== next.isExpectFair
                }
              >
                {({ getFieldValue }) => {
                  const isExpectFair = getFieldValue('isExpectFair');

                  return isExpectFair !== 'notExpected' ? (
                    <Form.Item
                      name="datePicker"
                      rules={generateFormRules({
                        required: true,
                      })}
                    >
                      <DatePicker picker="month" />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="rangePicker"
                      rules={[
                        ...generateFormRules({ required: true }),
                        {
                          validator: async (
                            _,
                            progressTime?: { startDt: string; endDt: string },
                          ) => {
                            if (!progressTime) return;
                          },
                        },
                      ]}
                    >
                      <DatePicker.RangePicker
                        style={{ width: 256 }}
                        format="YYYY-MM-DD"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Flex>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label="박람회 제목"
          name="title"
          required
          rules={generateFormRules({
            required: true,
            maxLength: 100,
          })}
          labelCol={{ span: 3 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="박람회 소제목"
          name="subtitle"
          required
          rules={generateFormRules({
            required: true,
            maxLength: 150,
            exceptKorean: true,
          })}
          labelCol={{ span: 3 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: 0,
          }}
          label="개최지역"
          required
          labelCol={{ span: 3 }}
        >
          <Input.Group>
            <Flex gap={8}>
              <Form.Item
                required
                name="beautyFairCountryId"
                rules={generateFormRules({
                  required: true,
                })}
                style={{ width: 400 }}
              >
                <Select
                  style={{ width: 400 }}
                  loading={getLoading}
                  showSearch
                  filterOption={(keyword, option) =>
                    option?.label
                      .toLowerCase()
                      .includes(keyword.toLowerCase()) || false
                  }
                  placeholder="국가명 입력"
                  options={beautyFairCountryOptions}
                />
              </Form.Item>
              <Form.Item
                name="cityName"
                rules={generateFormRules({
                  required: true,
                  maxLength: 10,
                  exceptEnglish: true,
                })}
                style={{ flex: 1 }}
              >
                <Input placeholder="도시명 입력" />
              </Form.Item>
            </Flex>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label="개최 장소"
          name="fairVenueName"
          required
          rules={generateFormRules({
            required: true,
            maxLength: 100,
            exceptKorean: true,
          })}
          labelCol={{ span: 3 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="링크 url"
          required
          name="fairLink"
          rules={generateFormRules({
            required: true,
            maxLength: 500,
          })}
          labelCol={{ span: 3 }}
        >
          <LinkInputWrapper>
            <Input
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
            />
            {linkUrl && (
              <a
                href={linkUrl.includes('://') ? linkUrl : `//${linkUrl}`}
                rel="noreferrer"
                target="_blank"
              >
                <LinkOutlined
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: 10,
                    transform: 'translateY(-50%)',
                    opacity: 0.25,
                    cursor: 'pointer',
                  }}
                />
              </a>
            )}
          </LinkInputWrapper>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BeautyFairAddModal;
