import styled from '@emotion/styled';
import { Button, message, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';

import { useBeautyFair } from 'core/query/business';
import { EBeautyFairStatus, IBeautyFairListItem } from 'type/business';

const StyledModal = styled(Modal)`
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    padding: 12px !important;
  }
`;

const DeletedBeautyFairModal = ({
  onClose,
  columns,
}: {
  onClose: () => void;
  columns: ColumnsType<IBeautyFairListItem>;
}) => {
  const [page, setPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { beautyFairListItems, isLoading, total, changeBeautyFairDisplay } =
    useBeautyFair({
      page,
      size: 10,
      status: EBeautyFairStatus.DELETE,
    });

  const handleReuploadBeautyFair = () => {
    if (selectedIds.length === 0) {
      return message.warn('재업로드할 일정을 선택해주세요.');
    }

    Modal.confirm({
      content: '삭제한 일정을 재업로드하시겠습니까?',
      onOk: () =>
        changeBeautyFairDisplay(
          {
            beautyFairScheduleIds: selectedIds,
            isDisplay: true,
          },
          {
            onSuccess: () => {
              message.success('수정되었습니다.');
              setSelectedIds([]);
              onClose();
            },
          },
        ),
    });
  };

  return (
    <StyledModal
      width={920}
      title="삭제한 일정 목록"
      maskClosable
      visible
      footer={
        <Button key="submit" type="primary" onClick={handleReuploadBeautyFair}>
          재업로드
        </Button>
      }
      onCancel={onClose}
    >
      <Table
        bordered
        dataSource={beautyFairListItems}
        pagination={{
          showSizeChanger: false,
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: total,
        }}
        rowSelection={{
          columnWidth: 60,
          selectedRowKeys: selectedIds,
          onChange: (keys) => {
            setSelectedIds(keys.map((key) => Number(key)));
          },
        }}
        loading={isLoading}
        columns={columns}
        rowKey="beautyFairScheduleId"
        style={{ marginTop: 24 }}
      />
    </StyledModal>
  );
};

export default DeletedBeautyFairModal;
