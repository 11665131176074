import { ReactNode } from 'react';
import styled from '@emotion/styled';

import { theme } from 'styles/theme';
import Flex from './Flex';

const SwitchBlock = styled(Flex)`
  position: relative;
  cursor: pointer;
  width: 24px;
  margin: 0 auto;
`;

const SwitchButtonBlock = styled.div<{ $active: boolean }>`
  position: relative;
  width: 24px;
  height: 8px;
  border-radius: 4px;
  background-color: #d3d3d3;
  transition: background-color 0.25s ease;

  ${({ $active }) =>
    $active &&
    `
      background-color: ${theme.colors.PRIMARY};
      opacity: 0.2;
    `}
`;

const SwitchButton = styled.div<{ $active: boolean }>`
  position: absolute;
  left: 0;
  top: -4px;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1.2px solid #d3d3d3;
  background-color: #fff;
  transition-property: border-color, transform;
  transition-timing-function: ease;
  transition-duration: 0.25s;

  ${({ $active }) =>
    $active &&
    `
      border-color: ${theme.colors.PRIMARY};
      background-color: ${theme.colors.PRIMARY};
      transform: translateX(10px);
    `};
`;

interface ISwitchProps {
  active?: boolean;
  style?: React.CSSProperties;
  children?: ReactNode;
  onChange?: () => void;
  disabled?: boolean;
}

const Switch = ({
  active = true,
  style,
  children,
  onChange,
  disabled = false,
}: ISwitchProps) => {
  const handleClick = () => {
    if (!disabled) onChange?.();
  };

  return (
    <SwitchBlock
      align="center"
      justify="center"
      onClick={handleClick}
      style={style}
    >
      <SwitchButtonBlock $active={active} />
      <SwitchButton $active={active} />
      {children}
    </SwitchBlock>
  );
};

export default Switch;
