import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ITypographyProps } from './Typography';

interface ITextProps extends ITypographyProps {
  type?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 's0'
    | 's1'
    | 's2'
    | 'b1'
    | 'b2'
    | 'small'
    | 'caption';
  pre?: boolean;
  whiteSpace?: React.CSSProperties['whiteSpace'];
}

const Text = styled.span<ITextProps>`
  display: block;
  color: ${({ theme }) => theme.colors.SLATEGRAY80};
  font-size: 16px;
  font-weight: 400;
  line-height: 1;

  ${({ type }) =>
    type === 'h1' &&
    css`
      font-size: 32px;
      line-height: 40px;
    `}
  ${({ type }) =>
    type === 'h2' &&
    css`
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    `}
  ${({ type }) =>
    type === 'h3' &&
    css`
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    `}

     ${({ type }) =>
    type === 's0' &&
    css`
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    `}
     ${({ type }) =>
    type === 's1' &&
    css`
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    `}
  ${({ type }) =>
    type === 's2' &&
    css`
      font-size: 14px;
      line-height: 1.4;
      font-weight: 700;
      line-height: 20px;
    `}

  ${({ type }) =>
    type === 'b1' &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
  ${({ type }) =>
    type === 'b2' &&
    css`
      font-size: 14px;
      line-height: 20px;
    `}
  ${({ type }) =>
    type === 'small' &&
    css`
      font-size: 12px;
      line-height: 18px;
    `}
  ${({ type }) =>
    type === 'caption' &&
    css`
      font-size: 10px;
      line-height: 12px;
    `}

  ${({ gutter }) => {
    if (typeof gutter === 'number') {
      return css`
        margin: ${gutter}px;
      `;
    }
    if (typeof gutter === 'object') {
      return css`
        margin-top: ${gutter.top ? `${gutter.top}px` : 0};
        margin-bottom: ${gutter.bottom ? `${gutter.bottom}px` : 0};
        margin-left: ${gutter.left ? `${gutter.left}px` : 0};
        margin-right: ${gutter.right ? `${gutter.right}px` : 0};
      `;
    }
  }}
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
    `}
  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
  ${({ medium }) =>
    medium &&
    css`
      font-weight: 500;
    `}
  ${({ regular }) =>
    regular &&
    css`
      font-weight: 400;
    `}
  ${({ light }) =>
    light &&
    css`
      font-weight: 300;
    `}
    
  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `}

  ${({ pre }) =>
    pre &&
    css`
      white-space: pre-wrap;
    `}

     ${({ asterisk, theme }) =>
    asterisk &&
    css`
      &::before {
        content: '*';
        color: ${theme.colors.PRIMARYGREEN60};
      }
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}  
    
  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors[color as keyof typeof theme.colors]};
    `}

    ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export default Text;
