import { Route, Routes } from 'react-router-dom';

import EntireBoardOrComment from 'components/community/EntireBoardOrComment';
import BoardNoticeForm from 'components/community/BoardNotice/BoardNoticeForm';
import { EBoardType } from 'type/board';
import Board from 'components/community/Board';
import BoardNoticeDetail from 'components/community/BoardNotice';
import AnonymousBoardList from 'components/community/AnonymousBoardList/AnonymousBoardList';
import FreeBoardList from 'components/community/FreeBoardList/FreeBoardList';
import QnaBoardList from 'components/community/QnaBoardList/QnaBoardList';
import PromotionBoardList from 'components/community/PromotionBoardList/PromotionBoardList';
import BoardAdminForm from 'components/community/BoardAdminForm';
import OriginalBoardList from 'components/community/OriginalBoardList/OriginalBoardList';

const CommunityRoutes = () => {
  const boardPath = [
    { path: '/original/:id', boardType: EBoardType.ORIGINAL },
    { path: '/qna/:id', boardType: EBoardType.QNA },
    { path: '/promotion/:id', boardType: EBoardType.PROMOTION },
    { path: '/free/:id', boardType: EBoardType.FREE },
    { path: '/anonymous/:id', boardType: EBoardType.ANONYMOUS },
  ];

  const boardNoticeDetailPath = [
    { path: '/original/notice/:id', boardType: EBoardType.ORIGINAL },
    { path: '/qna/notice/:id', boardType: EBoardType.QNA },
    { path: '/promotion/notice/:id', boardType: EBoardType.PROMOTION },
    { path: '/free/notice/:id', boardType: EBoardType.FREE },
    { path: '/anonymous/notice/:id', boardType: EBoardType.ANONYMOUS },
  ];

  const boardNoticeFormPath = [
    '/notice',
    '/original/notice/:id/update',
    '/qna/notice/:id/update',
    '/promotion/notice/:id/update',
    '/free/notice/:id/update',
    '/anonymous/notice/:id/update',
  ];

  const boardAdminPath = [
    { path: '/original/admin', boardType: EBoardType.ORIGINAL },
    { path: '/qna/admin', boardType: EBoardType.QNA },
    { path: '/promotion/admin', boardType: EBoardType.PROMOTION },
    { path: '/free/admin', boardType: EBoardType.FREE },
    { path: '/anonymous/admin', boardType: EBoardType.ANONYMOUS },
    { path: '/original/admin/:id', boardType: EBoardType.ORIGINAL },
    { path: '/qna/admin/:id', boardType: EBoardType.QNA },
    { path: '/promotion/admin/:id', boardType: EBoardType.PROMOTION },
    { path: '/free/admin/:id', boardType: EBoardType.FREE },
    { path: '/anonymous/admin/:id', boardType: EBoardType.ANONYMOUS },
  ];

  return (
    <Routes>
      <Route path="/" element={<EntireBoardOrComment />} />
      <Route path="/original" element={<OriginalBoardList />} />
      <Route path="/qna" element={<QnaBoardList />} />
      <Route path="/promotion" element={<PromotionBoardList />} />
      {boardAdminPath.map(({ path, boardType }) => (
        <Route
          key={path}
          path={path}
          element={<BoardAdminForm boardType={boardType} />}
        />
      ))}
      <Route path="/free" element={<FreeBoardList />} />
      <Route path="/anonymous" element={<AnonymousBoardList />} />
      {boardPath.map(({ path, boardType }) => (
        <Route
          key={path}
          path={path}
          element={<Board boardType={boardType} />}
        />
      ))}
      {boardNoticeDetailPath.map(({ path, boardType }) => (
        <Route
          key={path}
          path={path}
          element={<BoardNoticeDetail boardType={boardType} />}
        />
      ))}
      {boardNoticeFormPath.map((path) => (
        <Route key={path} path={path} element={<BoardNoticeForm />} />
      ))}
    </Routes>
  );
};

export default CommunityRoutes;
