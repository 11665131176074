import { Button, Col, Form, Input, Row } from 'antd';
import { useEffect } from 'react';

import AddressInput from 'components/ui/AddressInput';
import { requiredRule } from 'shared/rule';
import { ISessionHostCompany } from 'type/session';
import BusinessTypeSelection from '../BusinessTypeSelection';
import { useSessionHostCompany } from 'core/query/session';

const SessionHostCompanyForm = ({
  sessionHostCompanyId,
  onSubmit,
}: {
  sessionHostCompanyId?: number;
  onSubmit: (sessionHostCompany: ISessionHostCompany) => void;
}) => {
  const sessionHostCompany = useSessionHostCompany(sessionHostCompanyId);
  const [form] = Form.useForm<ISessionHostCompany>();
  useEffect(() => {
    if (sessionHostCompany) {
      form.setFieldsValue({
        ...sessionHostCompany,
        businessTypeIds: sessionHostCompany.businessTypes.map(
          ({ businessTypeId }) => businessTypeId,
        ),
      });
    }
  }, [sessionHostCompany, form]);
  return (
    <Form
      form={form}
      colon={false}
      labelCol={{ span: 4 }}
      onFinish={(sessionHostCompany) =>
        onSubmit({
          ...sessionHostCompany,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          sessionHostCompanyId: sessionHostCompanyId!,
        })
      }
    >
      <Form.Item label="업종" name="businessTypeIds" rules={[requiredRule]}>
        <BusinessTypeSelection />
      </Form.Item>
      <Form.Item label="회사명" name="companyName" rules={[requiredRule]}>
        <Input maxLength={100} />
      </Form.Item>
      <Form.Item label="주소" name="address" rules={[requiredRule]}>
        <AddressInput />
      </Form.Item>
      <Form.Item label=" " name="addressDetail">
        <Input placeholder="상세 주소 입력" style={{ maxWidth: 360 }} />
      </Form.Item>
      <Form.Item label="대표자" name="ceoName" rules={[requiredRule]}>
        <Input maxLength={50} />
      </Form.Item>
      <Form.Item label="회사 소개" name="description" rules={[requiredRule]}>
        <Input.TextArea
          maxLength={150}
          autoSize={{ maxRows: 4, minRows: 4 }}
          showCount
        />
      </Form.Item>
      <Row justify="center">
        <Col>
          <Button type="primary" htmlType="submit" size="large">
            저장
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SessionHostCompanyForm;
