import styled from '@emotion/styled';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';

import { useAdBannerCompanies } from 'core/query/stats';
import { IAdCompany } from 'type/stats';
import history from 'util/history';
import path from 'shared/path';
import Flex from 'components/ui/Flex';
import SearchInput from 'components/ui/SearchInput';

const Container = styled.div``;

const BannerAdCompanyList = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (searchValue: string) => {
    setPage(1);
    setSearchValue(searchValue);
  };
  const { adBannerCompanies, total } = useAdBannerCompanies({
    advertisingType: 'BANNER',
    page,
    companyName: searchValue,
  });

  const columns: ColumnsType<IAdCompany> = useMemo(
    () => [
      {
        title: 'No.',
        width: '5%',
        align: 'center',
        render: (_, __, index) => total - ((page - 1) * 10 + index),
      },
      {
        title: '회사(소속)명',
        width: '45%',
        align: 'center',
        dataIndex: 'companyName',
        render: (_, { companyName, companyId }) => (
          <Typography.Text
            style={{
              cursor: 'pointer',
            }}
            onClick={() => history.push(`${path.stats.bannerAd}/${companyId}`)}
          >
            {companyName}
          </Typography.Text>
        ),
      },
      {
        title: '최초 광고 시작일',
        width: '15%',
        align: 'center',
        dataIndex: 'firstAdvertisingStartDate',
        render: (_, { firstAdvertisingStartDate }) => firstAdvertisingStartDate,
      },
    ],
    [total, page],
  );

  return (
    <Container>
      <Flex justify="space-between" style={{ marginBottom: 8 }}>
        <Typography.Title style={{ fontSize: 18, marginBottom: 12 }}>
          회사별 배너 광고
        </Typography.Title>
        <SearchInput
          style={{ width: 200 }}
          placeholder="회사명 입력"
          defaultValue={searchValue}
          onSearch={handleSearch}
        />
      </Flex>
      <Table
        style={{ width: '100%' }}
        loading={false}
        dataSource={adBannerCompanies}
        pagination={{
          current: page,
          onChange: setPage,
          total,
        }}
        columns={columns}
        rowKey="companyId"
      />
    </Container>
  );
};

export default BannerAdCompanyList;
