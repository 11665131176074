import styled from '@emotion/styled';
import { List } from 'antd';
import { useState } from 'react';

import { useSessionReviews } from 'core/query/session';
import SessionReview from './SessionReview';

const Container = styled.div`
  padding-top: 24px;
`;

const SessionReviewList = ({ sessionId }: { sessionId: number }) => {
  const [page, setPage] = useState(1);
  const {
    sessionReviewsInfo: { content: sessionReviews, totalElements },
    deleteSessionReview,
  } = useSessionReviews({ sessionId, page });
  return (
    <Container>
      <List
        dataSource={sessionReviews}
        pagination={{
          current: page,
          onChange: setPage,
          total: totalElements,
          size: 'small',
        }}
        renderItem={(sessionReview) => (
          <SessionReview
            sessionReview={sessionReview}
            onDelete={deleteSessionReview}
          />
        )}
      />
    </Container>
  );
};

export default SessionReviewList;
