import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Col, message, Modal, Row, Table, Typography } from 'antd';
import { useDeleteFaqs, useFaqs } from 'core/query/cs';
import { useState } from 'react';
import { QueryClientProvider, useQueryClient } from 'react-query';

import { theme } from 'styles/theme';
import FaqForm from './FaqForm';

const Container = styled.div``;

const FaqList = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const { faqs, total } = useFaqs(page);
  const { deleteFaqs, isLoading } = useDeleteFaqs();
  const [selectedFaqIds, setSelectedFaqIds] = useState<number[]>([]);
  const handleAddFaq = () => {
    const modal = Modal.info({
      title: 'FAQ 등록',
      width: 920,
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <FaqForm
            onSubmit={() => {
              modal.destroy();
            }}
          />
        </QueryClientProvider>
      ),
    });
  };
  const handleShowFaq = (faqId: number) => {
    const modal = Modal.info({
      title: 'FAQ 상세',
      width: 920,
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <FaqForm
            faqId={faqId}
            onSubmit={() => {
              modal.destroy();
            }}
          />
        </QueryClientProvider>
      ),
    });
  };
  const handleDeleteFaqs = () => {
    if (selectedFaqIds.length === 0) {
      return message.warn('삭제할 FAQ를 선택해 주세요.');
    }
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      icon: null,
      onOk: () => {
        deleteFaqs(selectedFaqIds, {
          onSuccess: () => {
            message.success('FAQ가 삭제되었습니다.');
            setSelectedFaqIds([]);
          },
        });
      },
    });
  };
  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 12 }}>
        FAQ
      </Typography.Title>
      <Row justify="end" gutter={8} style={{ marginBottom: 12 }}>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddFaq}>
            새로운 FAQ 작성하기
          </Button>
        </Col>
        <Col>
          <Button
            icon={<DeleteOutlined />}
            loading={isLoading}
            onClick={handleDeleteFaqs}
          >
            선택 삭제
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={faqs}
        rowKey="faqId"
        pagination={{ current: page, onChange: setPage, total }}
        columns={[
          {
            title: '번호',
            dataIndex: 'faqId',
            align: 'center',
            width: '100px',
          },
          {
            title: '제목',
            align: 'center',
            render: (_, { faqId, title }) => (
              <Typography.Text
                type="secondary"
                style={{
                  color: theme.colors.PRIMARY,
                  cursor: 'pointer',
                }}
                onClick={() => handleShowFaq(faqId)}
              >
                {title}
              </Typography.Text>
            ),
          },
          {
            title: '공개 여부',
            align: 'center',
            width: '140px',
            render: (_, { isLive }) => (isLive ? '공개' : '비공개'),
          },
        ]}
      />
    </Container>
  );
};

export default FaqList;
