import { DatePicker } from 'antd';
import moment from 'moment';
import { ISessionProgressTime } from 'type/session';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

const SessionDatePicker = ({
  value,
  onChange,
}: {
  value?: ISessionProgressTime;
  onChange?: (value?: ISessionProgressTime) => void;
}) => {
  return (
    <DatePicker.RangePicker
      value={value ? [moment(value.startDt), moment(value.endDt)] : undefined}
      showTime={{ minuteStep: 10 }}
      format={DATE_FORMAT}
      disabledDate={(date) => date.isBefore(moment().startOf('day'))}
      onChange={(_, [startDt, endDt]) => {
        onChange && onChange(startDt && endDt ? { startDt, endDt } : undefined);
      }}
      style={{ width: 360 }}
    />
  );
};

export default SessionDatePicker;
