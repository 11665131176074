import styled from '@emotion/styled';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { useEffect } from 'react';

import Editor from 'components/ui/Editor';
import { useNotice } from 'core/query/cs';
import { requiredRule } from 'shared/rule';
import { INotice } from 'type/cs';
import { EAttachType } from 'type/common';
import AttachUpload from 'components/ui/AttachUpload';
import { REQUIRED_FIELD } from 'shared/message';

const Container = styled.div`
  margin-top: 24px;
`;

const NoticeForm = ({
  noticeId,
  onSubmit,
}: {
  noticeId?: number;
  onSubmit: () => void;
}) => {
  const [form] = Form.useForm<INotice>();
  const { notice, addNotice, addLoading, updateNotice, updateLoading } =
    useNotice(noticeId);
  const handleSubmitNotice = (notice: INotice) => {
    if (!noticeId) {
      addNotice(notice, { onSuccess: onSubmit });
    } else {
      updateNotice(notice, { onSuccess: onSubmit });
    }
  };
  useEffect(() => {
    if (notice) {
      form.setFieldsValue(notice);
    }
  }, [form, notice]);
  return (
    <Container>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        colon={false}
        onFinish={handleSubmitNotice}
      >
        <Form.Item label="제목" name="title" rules={[requiredRule]}>
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item
          label="내용"
          name="content"
          rules={[
            requiredRule,
            {
              validator: async (_, value: string) => {
                if (!value.replace('<p><br></p>', '')) {
                  throw new Error(REQUIRED_FIELD);
                }
              },
            },
          ]}
        >
          <Editor
            initialValue={notice?.content}
            imageUploadType="BOARD_NOTICE"
          />
        </Form.Item>
        <Form.Item
          label="공개 여부"
          name="isLive"
          initialValue={true}
          style={{ marginTop: 12 }}
        >
          <Radio.Group
            options={[
              { value: true, label: '공개' },
              { value: false, label: '비공개' },
            ]}
          />
        </Form.Item>
        <Form.Item label="팝업 노출" name="isMainPopup" initialValue={true}>
          <Radio.Group
            options={[
              { value: true, label: '노출' },
              { value: false, label: '비노출' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="첨부파일"
          name="attaches"
          valuePropName="attaches"
          initialValue={[]}
        >
          <AttachUpload attachType={EAttachType.NOTICE} maxCount={10} />
        </Form.Item>
        <Row justify="center">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={addLoading || updateLoading}
            >
              {notice === null ? '추가하기' : '수정하기'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default NoticeForm;
