import { useState } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Col,
  Comment,
  Divider,
  List,
  message,
  Row,
  Typography,
} from 'antd';

import { EBoardStatus, EDeleteItemType, IBoardComment } from 'type/board';
import { ReactComponent as AvatarGreen } from 'assets/avatar-green.svg';
import { ReactComponent as AvatarBlue } from 'assets/avatar-blue.svg';
import { ReactComponent as AvatarBrown } from 'assets/avatar-brown.svg';
import { ReactComponent as AvatarRed } from 'assets/avatar-red.svg';
import { ReactComponent as AvatarPurple } from 'assets/avatar-purple.svg';
import { ReactComponent as AvatarYellow } from 'assets/avatar-yellow.svg';
import { ReactComponent as TriangleUp } from 'assets/triangle-up.svg';
import { ReactComponent as TriangleDown } from 'assets/triangle-down.svg';
import { EAvatarType } from 'type/board';
import BoardReply from './BoardReply';
import {
  useCommentReplies,
  useDeleteBoardComments,
} from 'core/query/community';
import useDeleteItems from 'util/hooks/useDeleteItems';
import useShowReports from 'util/hooks/useShowReports';
import { EReportKind } from 'type/common';
import { boardStatusMap, itemTypeMap } from 'shared/consts';
import { theme } from 'styles/theme';

const avatarTypeMap = {
  [EAvatarType.GREEN]: <AvatarGreen />,
  [EAvatarType.BLUE]: <AvatarBlue />,
  [EAvatarType.RED]: <AvatarRed />,
  [EAvatarType.PURPLE]: <AvatarPurple />,
  [EAvatarType.YELLOW]: <AvatarYellow />,
  [EAvatarType.BROWN]: <AvatarBrown />,
};

const BoardCommentContainer = styled.li`
  .ant-comment-inner {
    padding: 0 0 16px;
  }
`;

const DeleteButton = styled(Button)`
  padding: 2px 5px;
  height: auto;
  font-size: 12px;
`;

const BoardComment = ({
  boardId,
  item,
  showMemberDetail,
}: {
  boardId: number;
  item: IBoardComment;
  showMemberDetail: (memberId: number) => void;
}) => {
  const {
    boardCommentId,
    avatarType,
    memberId,
    nickname,
    username,
    reportCount,
    content,
    registerDt,
    replyCount,
    status,
  } = item;
  const [isOpenReplies, setIsOpenReplies] = useState(false);
  const { deleteBoardComments } = useDeleteBoardComments();
  const showReports = useShowReports();
  const deleteItems = useDeleteItems();
  const { replies, fetchNextPage, hasNextPage } = useCommentReplies({
    boardId,
    boardCommentId: boardCommentId,
    enabled: isOpenReplies,
  });

  return (
    <BoardCommentContainer>
      <Divider />
      <Comment
        avatar={avatarTypeMap[avatarType]}
        author={
          <Row gutter={8}>
            <Col>
              <Typography.Link onClick={() => showMemberDetail(memberId)}>
                {username} ({nickname ? nickname : '-'})
              </Typography.Link>
            </Col>
            <Col>
              <Typography.Text>
                신고수{' '}
                <Typography.Link
                  onClick={() =>
                    showReports({
                      id: boardCommentId,
                      reportKind: EReportKind.BOARD_COMMENT,
                      title: content,
                    })
                  }
                >
                  {reportCount}
                </Typography.Link>
              </Typography.Text>
            </Col>
          </Row>
        }
        content={
          <Row gutter={8}>
            <Col>
              <Typography.Text
                style={
                  status === EBoardStatus.ADMIN_DELETE ||
                  status === EBoardStatus.DELETE
                    ? { textDecoration: 'line-through', whiteSpace: 'pre-line' }
                    : { whiteSpace: 'pre-line' }
                }
              >
                {content}
              </Typography.Text>
            </Col>
            {status !== EBoardStatus.NORMAL && (
              <Col>
                <Typography.Text style={{ color: theme.colors.RED50 }}>
                  {`(${boardStatusMap[status]})`}
                </Typography.Text>
              </Col>
            )}
          </Row>
        }
        datetime={registerDt.slice(0, 10)}
      >
        <Row justify="space-between">
          <Col>
            {replyCount > 0 && (
              <Typography.Link
                onClick={() => {
                  setIsOpenReplies(!isOpenReplies);
                }}
              >
                <Row gutter={8}>
                  <Col>
                    답글 {replyCount}개 {isOpenReplies ? '숨기기' : '더보기'}
                  </Col>
                  <Col>
                    {isOpenReplies ? (
                      <TriangleUp style={{ verticalAlign: 'middle' }} />
                    ) : (
                      <TriangleDown style={{ verticalAlign: 'middle' }} />
                    )}
                  </Col>
                </Row>
              </Typography.Link>
            )}
          </Col>
          {(status === EBoardStatus.NORMAL ||
            status === EBoardStatus.BLOCK) && (
            <Col>
              <DeleteButton
                type="primary"
                onClick={() =>
                  deleteItems({
                    itemType: EDeleteItemType.COMMENT,
                    deleteMutate: () =>
                      deleteBoardComments([boardCommentId], {
                        onSuccess: () => {
                          message.success(
                            `${
                              itemTypeMap[EDeleteItemType.COMMENT]
                            }이 삭제되었습니다.`,
                          );
                        },
                      }),
                    selectedItemIds: [boardCommentId],
                  })
                }
              >
                삭제
              </DeleteButton>
            </Col>
          )}
        </Row>
        {isOpenReplies && (
          <>
            <List
              className="comment-list"
              itemLayout="horizontal"
              dataSource={replies}
              renderItem={(item) => {
                return (
                  <BoardReply
                    key={item.boardCommentId}
                    avatarTypeMap={avatarTypeMap}
                    item={item}
                    showMemberDetail={showMemberDetail}
                    showReports={showReports}
                  />
                );
              }}
            />
            {hasNextPage && (
              <Row>
                <Col>
                  <Typography.Link onClick={() => fetchNextPage()}>
                    답글 더보기
                  </Typography.Link>
                </Col>
                <Col>
                  <TriangleDown name="triangleDown" color="PRIMARYGREEN60" />
                </Col>
              </Row>
            )}
          </>
        )}
      </Comment>
    </BoardCommentContainer>
  );
};

export default BoardComment;
