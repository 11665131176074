import { IAPIResponse } from 'type/common';
import { useQuery } from 'react-query';

import { IAdminUser } from 'type/user';
import api from 'util/api';

export const useAdminUsers = () => {
  const { data: adminUsers } = useQuery(
    [`common/adminUsers`],
    () => api.get<IAPIResponse<IAdminUser[]>>(`/v1/simple-admin-members`),
    {
      staleTime: Infinity,
      select: (res) => res.data.result,
    },
  );

  return adminUsers || [];
};
