import styled from '@emotion/styled';
import { Button, Typography } from 'antd';
import { useState } from 'react';

import { useSessionHostCompanies } from 'core/query/session';
import { ESessionHostCompanySearchType } from 'type/session';
import SessionHostCompanySelectModal from './SessionHostCompanySelectModal';

const Container = styled.div``;

const SessionHostCompanySelect = ({
  sessionHostCompanyId,
  onChange,
}: {
  sessionHostCompanyId?: number;
  onChange?: (sessionHostCompanyId: number) => void;
}) => {
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState(
    ESessionHostCompanySearchType.COMPANY_NAME,
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { sessionHostCompaniesInfo } = useSessionHostCompanies({
    page,
    businessTypeId: undefined,
    searchType,
    searchKeyword,
  });
  const sessionHostCompany = sessionHostCompaniesInfo.content.find(
    (sessionHostCompany) =>
      sessionHostCompany.sessionHostCompanyId === sessionHostCompanyId,
  );
  return (
    <Container>
      <Button onClick={() => setModalVisible(true)}>선택 하기</Button>
      {sessionHostCompany && (
        <Typography.Text style={{ display: 'block', marginTop: 8 }}>
          {sessionHostCompany.companyName}
        </Typography.Text>
      )}
      <SessionHostCompanySelectModal
        visible={modalVisible}
        page={page}
        onChangePage={setPage}
        searchType={searchType}
        onChangeSearchType={setSearchType}
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={setSearchKeyword}
        sessionHostCompaniesInfo={sessionHostCompaniesInfo}
        onSelect={(sessionHostCompanyId: number) => {
          onChange && onChange(sessionHostCompanyId);
          setModalVisible(false);
        }}
        onClose={() => {
          setModalVisible(false);
        }}
      />
    </Container>
  );
};

export default SessionHostCompanySelect;
