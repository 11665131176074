import { useQuery } from 'react-query';

import { IAPIResponse } from 'type/common';
import { IBusinessType, ICompanyDepartment } from 'type/company';
import api from 'util/api';

export const useBusinessTypes = () => {
  const { data: businessTypes = [] } = useQuery(
    ['company', 'businessTypes'],
    () => api.get<IAPIResponse<IBusinessType[]>>('/v1/business-types'),
    { select: (res) => res.data.result, staleTime: Number.MAX_SAFE_INTEGER },
  );
  return businessTypes;
};

export const useCompanyDepartments = () => {
  const { data: companyDepartments = [] } = useQuery(
    ['company', 'companyDepartments'],
    () =>
      api.get<IAPIResponse<ICompanyDepartment[]>>('/v1/company-departments'),
    { select: (res) => res.data.result, staleTime: Number.MAX_SAFE_INTEGER },
  );
  return companyDepartments;
};
