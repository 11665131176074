import { authState } from 'core/store/auth';
import { useMutation } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMemo } from 'react';
import qs from 'qs';
import { message } from 'antd';

import { IAuth, ISignup } from 'type/auth';
import { IAPIResponse } from 'type/common';
import api, { clearToken, setToken } from 'util/api';

export const useLogin = () => {
  const setAuth = useSetRecoilState(authState);
  const { mutate: login, isLoading } = useMutation(
    ({ username, password }: { username: string; password: string }) =>
      api.post<IAPIResponse<IAuth>>(`/v1/login`, {
        username,
        password,
      }),
    {
      onSuccess: (res) => {
        const { result } = res.data;
        setToken(result.accessToken, result.refreshToken);
        setAuth(result);
      },
      onError: (error: { response: { data: { message: string } } }) => {
        message.warn(error.response.data.message);
      },
    },
  );
  return useMemo(() => ({ login, isLoading }), [login, isLoading]);
};

export const useReissueLogin = () => {
  const [_, setAuth] = useRecoilState(authState);

  const { mutate: reissueLogin, isLoading: isReissueLoginLoading } =
    useMutation(
      (refreshToken: string) =>
        api.post<IAPIResponse<IAuth>>(
          `/v1/reissue/access-token`,
          qs.stringify({
            refreshToken,
          }),
        ),
      {
        onSuccess: (res) => {
          const { result } = res.data;
          setToken(result.accessToken, result.refreshToken);
          setAuth(result);
        },
      },
    );
  return useMemo(
    () => ({ reissueLogin, isReissueLoginLoading }),
    [reissueLogin, isReissueLoginLoading],
  );
};

export const useLogout = () => {
  const [_, setAuth] = useRecoilState(authState);
  const logout = () => {
    setAuth(null);
    clearToken();
  };
  return logout;
};

export const useSignup = () => {
  const { mutate: signup, isLoading } = useMutation(
    (signupForm: ISignup) =>
      api.post<IAPIResponse<number>>(`/v1/admin`, signupForm),
    {
      onError: (error: { response: { data: { message: string } } }) => {
        message.warn(error.response.data.message);
      },
    },
  );
  return useMemo(() => ({ signup, isLoading }), [signup, isLoading]);
};

export const useSendEmailCode = () => {
  const { mutate: sendEmailCode, isLoading } = useMutation((email: string) =>
    api.post<IAPIResponse<string>>(
      `/v1/admin/email/authentication-code?${qs.stringify({ email })}`,
    ),
  );

  return useMemo(
    () => ({
      sendEmailCode,
      isLoading,
    }),
    [sendEmailCode, isLoading],
  );
};

export const useVerifyEmail = () => {
  const { mutate: verifyEmail, isLoading } = useMutation(
    ({ email, authKey }: { email: string; authKey: string }) =>
      api.post<IAPIResponse<boolean>>('/v1/admin/email/authentication', {
        email,
        authKey,
      }),
  );
  return useMemo(
    () => ({
      verifyEmail,
      isLoading,
    }),
    [verifyEmail, isLoading],
  );
};

export const useCheckEmail = () => {
  const { mutate: checkEmail, isLoading } = useMutation((email: string) =>
    api.get<IAPIResponse<boolean>>(
      `/v1/admin/email-reduplication?${qs.stringify({ email })}`,
    ),
  );

  return useMemo(
    () => ({
      checkEmail,
      isLoading,
    }),
    [checkEmail, isLoading],
  );
};
