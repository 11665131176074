import styled from '@emotion/styled';
import {
  Card,
  Col,
  Descriptions,
  Divider,
  Row,
  Statistic,
  Typography,
  Form,
  Input,
  Button,
} from 'antd';
import { useMemberInquiry } from 'core/query/member';

import { requiredRule } from 'shared/rule';

const Container = styled.div`
  padding-top: 24px;

  .ant-statistic {
    text-align: center;

    .ant-statistic-content-value {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .ant-descriptions-item-label {
    width: 120px;
    font-size: 12px;
    font-weight: 300;
  }

  .ant-descriptions-item-content {
    font-weight: 500;
  }

  .ant-descriptions-bordered {
    td.ant-descriptions-item-content:first-of-type {
      width: 50%;
    }
  }
`;

const MemberInquiry = ({
  memberInquiryId,
  onAnswer,
}: {
  memberInquiryId: number;
  onAnswer: () => void;
}) => {
  const [form] = Form.useForm<{ content: string }>();
  const { memberInquiry, answerMemberInquiry } =
    useMemberInquiry(memberInquiryId);
  if (memberInquiry === null) return null;
  const {
    title,
    sessionTitle,
    username,
    name,
    companyName,
    email,
    cellPhone,
    registerDt,
    content,
    answerContent,
    answerRegisterDt,
  } = memberInquiry;
  const handleSubmitAnswer = ({ content }: { content: string }) => {
    answerMemberInquiry(content, {
      onSuccess: () => {
        onAnswer();
      },
    });
  };
  return (
    <Container>
      <Card>
        <Row gutter={16} justify="space-around">
          <Col>
            <Statistic title="유저 아이디" value={username} />
          </Col>
          <Col>
            <Statistic title="유저 이름" value={name} />
          </Col>
          <Col>
            <Statistic title="회사명" value={companyName} />
          </Col>
          <Col>
            <Statistic title="이메일" value={email} />
          </Col>
          <Col>
            <Statistic
              title="휴대폰 번호"
              valueRender={() => (
                <Typography.Text style={{ fontSize: 14 }}>
                  {cellPhone}
                </Typography.Text>
              )}
            />
          </Col>
        </Row>
      </Card>
      <Descriptions bordered style={{ marginTop: 24 }}>
        <Descriptions.Item label="문의 제목">
          {title}{' '}
          {sessionTitle && (
            <Typography.Text
              style={{ display: 'block', fontWeight: 300, fontSize: 10 }}
            >
              세션 타이틀 : {sessionTitle}
            </Typography.Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="작성 일시">
          {registerDt.slice(0, 16)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions style={{ marginTop: 24, paddingLeft: 24 }} colon={false}>
        <Descriptions.Item label="문의 내용">
          <Typography.Text style={{ whiteSpace: 'pre-line' }}>
            {content}
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      {answerContent === null ? (
        <Form
          form={form}
          colon={false}
          style={{ paddingLeft: 24 }}
          wrapperCol={{ offset: 1 }}
          onFinish={handleSubmitAnswer}
        >
          <Form.Item label="답변 내용" rules={[requiredRule]} name="content">
            <Input.TextArea
              autoSize={{ minRows: 10, maxRows: 10 }}
              maxLength={1000}
              showCount
            />
          </Form.Item>
          <Row justify="end" style={{ marginTop: 16 }}>
            <Col>
              <Button type="primary" htmlType="submit">
                답변하기
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <>
          <Descriptions bordered column={2} style={{ marginTop: 24 }}>
            <Descriptions.Item label="답변 일시">
              {answerRegisterDt?.slice(0, 16)}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            style={{ marginTop: 24, paddingLeft: 24 }}
            colon={false}
          >
            <Descriptions.Item label="답변 내용">
              <Typography.Text style={{ whiteSpace: 'pre-line' }}>
                {answerContent}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Container>
  );
};

export default MemberInquiry;
