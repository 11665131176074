import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import parser from 'react-html-parser';
import 'suneditor/dist/css/suneditor.min.css';

const Container = styled.div`
  min-height: 200px;
  padding: 0px;
`;

const EditorViewer = ({
  content,
  cssStyle,
}: {
  content: string;
  cssStyle?: SerializedStyles;
}) => {
  return (
    <Container className="sun-editor-editable" css={cssStyle}>
      {parser(content)}
    </Container>
  );
};

export default EditorViewer;
