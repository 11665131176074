export interface IAdInquiry {
  advertisingAffiliationInquiryId: number;
  companyName: string;
  managerName: string;
  managerEmail: string;
  managerPhone?: string;
  // HINT: 개별 조회가 아닌 리스트 조회에서도 동일한 타입을 사용하기 위해
  // 개별 조회에서 nullable이 아닌 해당 inqueryContent 필드를 선택 옵션으로 처리
  inquiryContent?: string;
  adminMemo?: string;
  businessAdminId: number | null;
  businessManagerName: string | null;
}

export interface IAdCompanyListItem {
  companyId: number;
  companyName: string;
  bizNumber: string;
  registerDt: string;
}

export interface IAdCompanyRegisterParams {
  companyName: string;
  bizNumber: string;
  companyIntroduction: string;
}

export type AdCalendarBadgeStatus =
  | 'warning'
  | 'success'
  | 'error'
  | 'processing';

export interface IAdCalendarCategory {
  name: string;
  code: string;
  badgeStatus: AdCalendarBadgeStatus;
  limit: number;
}

export enum EAdBannerFilterOption {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR',
}

export interface IProductCount {
  product: {
    productId: number;
    code: string;
    productName: string;
  };
  count: number;
}

export interface IAdProduct {
  productId: number;
  code: string;
  productName: string;
}

export interface IAdProduct {
  productId: number;
  code: string;
  productName: string;
}

export interface IAdBannerDetail {
  companyPurchaseProductId: number;
  companyId: number;
  productId: number;
  startDt: string;
  endDt: string;
  isDisplay: true;
  memo: string | null;
  eventUrl: string;
  filename: string;
  fileUrl: string;
  mobileFileUrl: string;
  mobileFilename: string;
}

export interface IAdBannerListItem {
  companyPurchaseProductId: number;
  companyName: string;
  startDt: string;
  endDt: string;
  registerDt: string;
  isDisplay: boolean;
  bannerCode: string;
  filename: string;
  fileUrl: string;
  mobileFileUrl: string;
  mobileFilename: string;
  purchaseCode: string;
}

export interface IAdvertisingProductCount {
  date: string;
  productCounts: IProductCount[];
}

export interface IPurchaseAdvertising {
  companyPurchaseProductId: number;
  productId: number;
  companyId: number;
  companyName: string;
  startDt: string;
  endDt: string;
}

export enum EAdBannerType {
  PB001 = 'PB001',
}
