import { IAttach, IReport } from 'type/common';

// HINT : 게시글 관련 데이터에 사용되는 메타정보
export interface IPost {
  title: string;
  content: string;
  attaches: IAttach[];
  registerDt: string;
  updateDt: string | null;
}

export interface IBoard extends IPost {
  adminId?: number | null;
  adminMemberId?: number | null;
  boardId: number;
  boardType: EBoardType;
  category: EBoardCategory | null;
  viewCount: number;
  likeCount: number;
  reportCount: number;
  commentCount: number;
  status: EBoardStatus;
  memberId: number;
  nickname: string | null;
  username: string;
}

export enum EBoardType {
  ORIGINAL = 'original',
  PROMOTION = 'promotion',
  QNA = 'qna',
  FREE = 'free',
  ANONYMOUS = 'anonymous',
}

export enum EBoardCategory {
  NONE = 'NONE',
  RETAIL_CO = 'RETAIL_CO',
  BRAND_CO = 'BRAND_CO',
  MANUFACTURER_CO = 'MANUFACTURE_CO',
  MATERIAL_CO = 'MATERIAL_CO',
  SUBMATERIAL_CO = 'SUBMATERIAL_CO',
}

export enum EBoardStatus {
  NORMAL = 'NORMAL',
  BLOCK = 'BLOCK',
  ADMIN_DELETE = 'ADMIN_DELETE',
  DELETE = 'DELETE',
}

export interface IComment {
  boardCommentId: number;
  content: string;
  status: EBoardStatus;
  reportCount: number;
  replyCount: number;
  registerDt: string;
  memberId: number;
  username: string;
  nickname: string | null;
  avatarType: EAvatarType;
  anonymNickname?: string;
}
export interface IEntireBoardComment extends IComment {
  boardId: number;
  boardTitle: string;
  boardCommentCount: number;
  boardType: EBoardType;
}
export interface IBoardComment extends IComment {
  updateDt: string | null;
  targetMemberId: number | null;
  targetMemberNickname: string | null;
  parentBoardCommentId: number | null;
}

export interface IBoardReply extends IComment {
  updateDt: string | null;
  targetMemberId: number;
  targetMemberNickname: string;
  parentBoardCommentId: number;
}

export interface IBoardNotice extends IPost {
  boardNoticeId: number;
  boardType: EBoardType;
}

export interface IBoardReport extends IReport {
  boardId: number;
  boardType: EBoardType;
  title: string;
  boardMemberId: number;
  boardUsername: string;
  boardNickname: string;
}

export interface IBoardCommentReport extends IReport {
  boardCommentReportId: number;
  boardId: number;
  boardCommentId: number;
  boardTitle: string;
  boardType: EBoardType;
  boardCommentContent: string;
  boardCommentMemberId: number;
  boardCommentUsername: string;
}

export enum EAvatarType {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  PURPLE = 'PURPLE',
}

export interface IEntireBoardOrCommentHistoryState {
  tab?: 'boardList' | 'boardCommentList';
  boardListPage?: number;
  boardCommentListPage?: number;
}

export enum EDeleteItemType {
  BOARD = 'BOARD',
  COMMENT = 'COMMENT',
  REPLY = 'REPLY',
}
