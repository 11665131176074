import styled from '@emotion/styled';
import { Badge, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import path from 'shared/path';
import { useNewExists } from 'util/hooks/useNotifications';
import sesscionIcon from 'assets/sessions.svg';
import advertisingIcon from 'assets/advertising.svg';
import statisticsIcon from 'assets/statistics.svg';
import communityIcon from 'assets/community.svg';
import newsIcon from 'assets/news.svg';
import memberIcon from 'assets/member.svg';
import serviceIcon from 'assets/service.svg';

const Container = styled(Layout.Sider)`
  position: fixed;
  left: 0;
  top: 64px;
  height: calc(100% - 64px);
  z-index: 11;
  overflow-y: auto;
`;

// HINT: 기본 Badge 사용시 기본 스타일로 인해 글씨가 보이지 않아, 스타일 덮어씀
const NotificationBadge = styled(Badge)`
  color: inherit;

  sup {
    right: -5px;
    box-shadow: none;
  }
`;

const Navigator = () => {
  const { pathname } = useLocation();
  const { hasNewAdInquiry } = useNewExists();

  return (
    <Container>
      <Menu
        mode="inline"
        theme="dark"
        defaultOpenKeys={[
          'session',
          'ad',
          'stats',
          'community',
          'business',
          'member',
          'cs',
        ]}
        selectedKeys={[
          pathname.toLowerCase().replace(/\/\d+/, ''),
          pathname.replace(
            /original\/(notice|admin)(\/\d+)?(\/update)?/,
            'original',
          ),
          pathname.replace(/qna\/(notice|admin)(\/\d+)?(\/update)?/, 'qna'),
          pathname.replace(
            /promotion\/(notice|admin)(\/\d+)?(\/update)?/,
            'promotion',
          ),
          pathname.replace(/free\/(notice|admin)(\/\d+)?(\/update)?/, 'free'),
          pathname.replace(
            /anonymous\/(notice|admin)(\/\d+)?(\/update)?/,
            'anonymous',
          ),
        ]}
      >
        <Menu.SubMenu
          key="session"
          title="세션 관리"
          icon={<img src={sesscionIcon} />}
        >
          <Menu.Item key={path.session.open}>
            <Link to={path.session.open}>세션 오픈</Link>
          </Menu.Item>
          <Menu.Item key={path.session.ongoing}>
            <Link to={path.session.ongoing}>진행 중인 세션</Link>
          </Menu.Item>
          <Menu.Item key={path.session.done}>
            <Link to={path.session.done}>종료된 세션</Link>
          </Menu.Item>
          <Menu.Item key={path.session.hostCompany}>
            <Link to={path.session.hostCompany}>세션 주최 회사</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="ad"
          title="광고 관리"
          icon={<img src={advertisingIcon} />}
        >
          <Menu.Item key={path.ad.inquiry}>
            <Link to={path.ad.inquiry}>
              <NotificationBadge dot={hasNewAdInquiry}>
                광고 및 제휴 문의
              </NotificationBadge>
            </Link>
          </Menu.Item>
          <Menu.Item key={path.ad.ongoing}>
            <Link to={path.ad.ongoing}>광고 진행 회사</Link>
          </Menu.Item>
          <Menu.Item key={path.ad.banner}>
            <Link to={path.ad.banner}>배너 광고</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="stats"
          title="광고 통계"
          icon={<img src={statisticsIcon} />}
        >
          <Menu.Item key={path.stats.bannerAd}>
            <Link to={path.stats.bannerAd}>회사별 배너 광고</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="community"
          title="커뮤니티 관리"
          icon={<img src={communityIcon} />}
        >
          <Menu.Item key={path.community.root}>
            <Link to={path.community.root}>전체 글 / 댓글</Link>
          </Menu.Item>
          <Menu.Item key={path.community.original}>
            <Link to={path.community.original} state={null}>
              브릿지 이야기
            </Link>
          </Menu.Item>
          <Menu.Item key={path.community.promotion}>
            <Link to={path.community.promotion} state={null}>
              자료 모아보기
            </Link>
          </Menu.Item>
          <Menu.Item key={path.community.free}>
            <Link to={path.community.free} state={null}>
              수다공간
            </Link>
          </Menu.Item>
          <Menu.Item key={path.community.qna}>
            <Link to={path.community.qna} state={null}>
              궁금해요
            </Link>
          </Menu.Item>
          <Menu.Item key={path.community.anonymous}>
            <Link to={path.community.anonymous} state={null}>
              익명 게시판
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="business"
          title="업계 소식 관리"
          icon={<img src={newsIcon} />}
        >
          <Menu.Item key={path.business.businessSupport}>
            <Link to={path.business.businessSupport}>지원사업</Link>
          </Menu.Item>
          <Menu.Item key={path.business.beautyFair}>
            <Link to={path.business.beautyFair}>뷰티 박람회 일정</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="member"
          title="회원 관리"
          icon={<img src={memberIcon} />}
        >
          <Menu.Item key={path.member.root}>
            <Link to={path.member.root}>회원 정보</Link>
          </Menu.Item>
          <Menu.Item key={path.member.memberInquiry}>
            <Link to={path.member.memberInquiry}>1:1 문의</Link>
          </Menu.Item>
          <Menu.Item key={path.report.root}>
            <Link to={path.report.root}>신고 관리</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="cs"
          title="고객 서비스 관리"
          icon={<img src={serviceIcon} />}
        >
          <Menu.Item key={path.cs.notice}>
            <Link to={path.cs.notice}>공지사항</Link>
          </Menu.Item>
          <Menu.Item key={path.cs.faq}>
            <Link to={path.cs.faq}>FAQ</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Container>
  );
};

export default Navigator;
