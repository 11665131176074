import styled from '@emotion/styled';

import { EPeriodRangeType, IPeriod } from 'type/stats';
import AdLineChart from './AdLineChart';

const Container = styled.div``;

const BannerAdChartGraph = ({
  companyId,
  period,
  periodType,
}: {
  companyId: number;
  period: IPeriod;
  periodType: EPeriodRangeType;
}) => {
  return (
    <Container>
      <AdLineChart
        companyId={companyId}
        title="유저 노출 횟수"
        period={period}
        periodType={periodType}
        dataType="exposureCount"
      />
      <AdLineChart
        style={{ marginTop: 24 }}
        companyId={companyId}
        title="구좌별 클릭 수"
        period={period}
        periodType={periodType}
        dataType="clickCount"
      />
      <AdLineChart
        style={{ marginTop: 24 }}
        companyId={companyId}
        title="노출대비 클릭 수"
        period={period}
        periodType={periodType}
        dataType="rate"
      />
    </Container>
  );
};

export default BannerAdChartGraph;
