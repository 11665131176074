import { Card } from 'antd';
import { ChartOptions } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import styled from '@emotion/styled';

import { ReactComponent as GaugeGreen } from 'assets/gauge-green.svg';
import { ReactComponent as GaugeGray } from 'assets/gauge-gray.svg';
import { ReactComponent as GaugeDot } from 'assets/gauge-dot.svg';
import {
  AdDataType,
  EAdLocationCode,
  EPeriodRangeType,
  IPeriod,
} from 'type/stats';
import { Flex, Typography } from 'components/ui';
import { useAdStats } from 'core/query/stats';
import { theme } from 'styles/theme';

const Container = styled.div``;

const ChartWrapper = styled(Card)`
  width: 100%;

  .ant-card-body {
    padding: 32px 24px 48px;
  }
`;

const AdLineChart = ({
  companyId,
  title,
  period,
  periodType,
  dataType,
  advertisingType = 'BANNER',
  style,
}: {
  companyId: number;
  title: string;
  period: IPeriod;
  periodType: EPeriodRangeType;
  dataType: AdDataType;
  advertisingType?: 'BANNER' | 'MATERIAL';
  style?: React.CSSProperties;
}) => {
  const { labels, datasets } = useAdStats({
    advertisingCompanyId: companyId,
    advertisingType,
    periodType,
    period,
    dataType,
  });

  const chartOptions: ChartOptions = {
    layout: {
      padding: 30,
    },
    aspectRatio: 4,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: 'linear',
        ticks: {
          maxTicksLimit: 7,
        },
        beginAtZero: true,
        grid: {
          borderDash: [5, 5],
          display: true,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: true,
        backgroundColor: '#fff',
        borderColor: theme.colors.PRIMARY,
        borderWidth: 1,
        callbacks: {
          title: () => '',
          label: (context) => {
            return `${context.label} ${
              periodType !== EPeriodRangeType.MONTH
                ? context.dataset.datalabels
                  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (context.dataset.datalabels as any)[context.dataIndex]
                  : 'No Data'
                : ''
            } ${context.raw}회`;
          },
          labelTextColor: () => theme.colors.SLATEGRAY80,
          labelColor: (context) => {
            if (context.dataset?.label === EAdLocationCode.ALL) {
              return {
                borderColor: theme.colors.PRIMARYGREEN60,
                backgroundColor: theme.colors.PRIMARYGREEN60,
              };
            }

            if (context.dataset?.label === EAdLocationCode.SESSION_MAIN) {
              return {
                borderColor: theme.colors.SLATEGRAY60,
                backgroundColor: theme.colors.SLATEGRAY60,
              };
            }

            if (context.dataset?.label === EAdLocationCode.COMMUNITY_MAIN) {
              return {
                borderDash: [10, 5],
                borderColor: theme.colors.SLATEGRAY60,
                backgroundColor: '#fff',
              };
            }

            return {
              borderColor: theme.colors.PRIMARYGREEN60,
              backgroundColor: theme.colors.PRIMARYGREEN60,
            };
          },
        },
      },
    },
  };

  return (
    <Container style={style}>
      <Typography.Text type="h2" gutter={{ bottom: 16 }} color="BLACK20">
        {title}
      </Typography.Text>
      <ChartWrapper>
        <Flex justify="end" align="center" gap={16}>
          <Flex align="center" gap={4}>
            <GaugeGreen />
            <Typography.Text type="small" bold>
              총 합
            </Typography.Text>
          </Flex>
          <Flex align="center" gap={4}>
            <GaugeGray />
            <Typography.Text type="small" bold>
              세션
            </Typography.Text>
          </Flex>
          <Flex align="center" gap={4}>
            <GaugeDot />
            <Typography.Text type="small" bold>
              커뮤니티
            </Typography.Text>
          </Flex>
        </Flex>
        <ReactChart
          type="line"
          data={{
            labels,
            datasets,
          }}
          options={chartOptions}
        />
      </ChartWrapper>
    </Container>
  );
};

export default AdLineChart;
