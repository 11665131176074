import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, List, message, Typography } from 'antd';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import {
  useBoard,
  useBoardComments,
  useDeleteBoards,
} from 'core/query/community';
import { ReactComponent as BackArrow } from 'assets/back-arrow.svg';
import { theme } from 'styles/theme';
import { EBoardStatus, EBoardType, EDeleteItemType } from 'type/board';
import { boardStatusMap, boardTypeMap, itemTypeMap } from 'shared/consts';
import BoardComment from './BoardComment';
import useDeleteItems from 'util/hooks/useDeleteItems';
import useShowMemberDetail from 'util/hooks/useShowMemberDetail';
import useShowReports from 'util/hooks/useShowReports';
import history from 'util/history';
import { EReportKind } from 'type/common';
import EditorViewer from 'components/ui/EditorViewer';
import BoardAttach from './BoardAttach';
import { Flex } from 'components/ui';
import { authState } from 'core/store/auth';

const Container = styled.div``;

const BoardCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

const BoardContent = styled.div``;

const Board = ({ boardType }: { boardType: EBoardType }) => {
  const auth = useRecoilValue(authState);
  const adminId = auth?.adminMemberId;
  const [page, setPage] = useState(1);
  const boardId = Number(useParams().id);
  const { board } = useBoard(boardId);
  const isAdminBoard = typeof board?.adminId === 'number';
  const { boardComments, total } = useBoardComments({
    boardId,
    page,
  });
  const { deleteBoards } = useDeleteBoards();
  const deleteItems = useDeleteItems();
  const showMemberDetail = useShowMemberDetail();
  const showReports = useShowReports();

  if (!board) return <div>loading...</div>;

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        {boardTypeMap[boardType]}
      </Typography.Title>
      <Card>
        <BackArrow
          onClick={() => history.back()}
          style={{ cursor: 'pointer', marginBottom: 10 }}
        />
        <BoardCard
          title={
            <Flex gap={8}>
              <Typography.Text
                style={
                  board.status === EBoardStatus.ADMIN_DELETE
                    ? { textDecoration: 'line-through' }
                    : {}
                }
              >
                {board.title}
              </Typography.Text>
              <Typography.Text style={{ color: theme.colors.PRIMARY }}>
                {board.commentCount > 0 && `[${board.commentCount}]`}
              </Typography.Text>
              {board.status !== EBoardStatus.NORMAL && (
                <Typography.Text style={{ color: theme.colors.RED50 }}>
                  {`(${boardStatusMap[board.status]})`}
                </Typography.Text>
              )}
            </Flex>
          }
          headStyle={{ background: theme.colors.PRIMARYGREEN10 }}
        >
          <Flex
            justify="space-between"
            style={{
              padding: '8px 16px',
              borderBottom: `1px solid ${theme.colors.SLATEGRAY10}`,
            }}
          >
            {isAdminBoard ? (
              <Typography.Text>
                {board.username} ({board.nickname ? board.nickname : '작성자'})
              </Typography.Text>
            ) : (
              <Typography.Link onClick={() => showMemberDetail(board.memberId)}>
                {board.username} ({board.nickname ? board.nickname : '작성자'})
              </Typography.Link>
            )}
            <Flex gap={24}>
              {!isAdminBoard && (
                <div>
                  신고수{' '}
                  <Typography.Link
                    onClick={() =>
                      showReports({
                        id: board.boardId,
                        reportKind: EReportKind.BOARD,
                        title: board.title,
                      })
                    }
                  >
                    {board.reportCount}
                  </Typography.Link>
                </div>
              )}
              <div>{board.registerDt?.slice(0, 10)}</div>
              <div>조회수 {board.viewCount}</div>
            </Flex>
          </Flex>
          <BoardContent style={{ padding: 16 }}>
            <EditorViewer content={board.content} />
            <Flex justify="space-between">
              <div>
                {board.attaches.length > 0 && (
                  <BoardAttach attaches={board.attaches} />
                )}
              </div>
              {board.status !== EBoardStatus.ADMIN_DELETE &&
                !(isAdminBoard && adminId !== board.adminId) && (
                  <Flex gap={8} style={{ alignSelf: 'flex-end' }}>
                    <Button
                      type={isAdminBoard ? undefined : 'primary'}
                      onClick={() => {
                        deleteItems({
                          itemType: EDeleteItemType.BOARD,
                          deleteMutate: () =>
                            deleteBoards([boardId], {
                              onSuccess: () => {
                                history.replace(`/community/${boardType}`);
                                message.success(
                                  `${
                                    itemTypeMap[EDeleteItemType.BOARD]
                                  }이 삭제되었습니다.`,
                                );
                              },
                            }),
                          selectedItemIds: [boardId],
                        });
                      }}
                    >
                      삭제
                    </Button>
                    {isAdminBoard && adminId === board.adminId && (
                      <Link to={`/community/${boardType}/admin/${boardId}`}>
                        <Button type="primary">수정</Button>
                      </Link>
                    )}
                  </Flex>
                )}
            </Flex>
          </BoardContent>
        </BoardCard>
        <Flex style={{ marginTop: 32 }}>
          <Typography.Text>댓글 {board.commentCount}</Typography.Text>
        </Flex>
        <List
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            current: page,
            onChange: setPage,
            total: total,
          }}
          className="comment-list"
          itemLayout="horizontal"
          dataSource={boardComments}
          renderItem={(item) => (
            <BoardComment
              boardId={boardId}
              item={item}
              showMemberDetail={showMemberDetail}
            />
          )}
        />
      </Card>
    </Container>
  );
};

export default Board;
