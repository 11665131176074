import { LogoutOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Col, Layout, Row, Typography } from 'antd';

import logo from 'assets/logo.svg';
import LinkIcon from 'assets/service_link.svg';
import { useLogout } from 'core/query/auth';
import { theme } from 'styles/theme';
import history from 'util/history';

const HeaderContainer = styled(Layout.Header)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: '#fff';
  padding: 0 16px;
  box-shadow: 0 2px 10px #ccc;
`;

const Logo = styled.img`
  width: 200px;
  cursor: pointer;
`;

const Header = () => {
  const logout = useLogout();

  return (
    <HeaderContainer style={{ backgroundColor: '#fff' }}>
      <Row justify="space-between">
        <Col>
          <Row gutter={8} align="middle">
            <Col>
              <Logo src={logo} alt="logo" onClick={() => history.push('/')} />
            </Col>
            <Col style={{ padding: 0 }}>
              <span style={{ margin: '0 16px' }}>|</span>
              <Typography.Text
                style={{
                  fontSize: 16,
                }}
              >
                관리자 페이지
              </Typography.Text>
            </Col>
            <Col>
              <Button
                style={{
                  marginLeft: 20,
                  border: `1px solid ${theme.colors.PRIMARYGREEN60}`,
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                }}
                onClick={() => {
                  const devMode =
                    process.env.REACT_APP_API_HOST?.includes('dev');
                  window.open(
                    devMode
                      ? 'https://dev.cosbridge.pro/'
                      : 'https://cosbridge.pro/',
                    '_blank',
                  );
                }}
              >
                <img src={LinkIcon} alt="link" />
                <Typography.Text
                  style={{
                    fontSize: 14,
                    color: theme.colors.PRIMARYGREEN60,
                  }}
                >
                  서비스 바로가기
                </Typography.Text>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button icon={<LogoutOutlined />} onClick={logout}>
            로그아웃
          </Button>
        </Col>
      </Row>
    </HeaderContainer>
  );
};

export default Header;
