import { IReport } from './common';
import { IBusinessType } from './company';

export interface IInterest {
  interestId: number;
  name: string;
}

export enum EMemberState {
  NORMAL = 'NORMAL',
  BLOCK = 'BLOCK',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum EMemberSearchType {
  USERNAME = 'USERNAME',
  COMPANY = 'COMPANY',
  DEPARTMENT = 'DEPARTMENT',
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  CELL_PHONE = 'CELL_PHONE',
  SIGN_UP_PATH = 'SIGN_UP_PATH',
}

export interface IMemberListItem {
  memberId: number;
  username: string;
  name: string;
  cellPhone: string;
  businessType: IBusinessType;
  companyName: string;
  departmentName: string;
  companyPositionName: string;
  email: string;
  interests: IInterest[];
  careerDuration: string;
  status: EMemberState;
  signUpPath: string;
  registerDt: string;
}

export interface IMember {
  username: string;
  name: string;
  nickname: string;
  cellPhone: string;
  companyName: string;
  companyDepartmentName: string;
  companyPositionName: string;
  email: string;
  memberInterests: string[];
  careerDuration: EMemberCareerDuration;
  businessTypeName: string;
  reportCount: number;
  signUpPath: string;
}

export enum EMemberInquiryStatus {
  READY = 'READY',
  DONE = 'DONE',
  DELETE = 'DELETE',
}

export enum EMemberInquirySearchType {
  TITLE = 'TITLE',
  SESSION = 'SESSION',
  NAME = 'NAME',
}
export interface IMemberInquiry {
  memberInquiryId: number;
  title: string;
  name: string;
  sessionTitle: string | null;
  registerDt: string;
  status: EMemberInquiryStatus;
}

export interface IMemberInquiryDetail extends IMemberInquiry {
  username: string;
  content: string;
  companyName: string;
  email: string;
  cellPhone: string;
  answerContent: string | null;
  answerRegisterDt: string | null;
}

export interface IMemberReport extends IReport {
  memberId: number;
  memberUsername: string;
  memberNickname: string;
}

export enum EMemberCareerDuration {
  NEWBIE = 'NEWBIE',
  UNDER_ONE = 'UNDER_ONE',
  ONE_FIVE = 'ONE_FIVE',
  FIVE_TEN = 'FIVE_TEN',
  TEN_TWENTY = 'TEN_TWENTY',
  MORE_TWENTY = 'MORE_TWENTY',
}
