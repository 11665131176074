import styled from '@emotion/styled';
import { Spin } from 'antd';
import { useIsFetching } from 'react-query';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 11000;
  background-color: rgba(255, 255, 255, 0.8);
`;

const LoadingIndicator = () => {
  const isFetching = useIsFetching();
  if (isFetching === 0) {
    return null;
  }
  return (
    <Container>
      <Spin />
    </Container>
  );
};

export default LoadingIndicator;
