import { useState } from 'react';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  Button,
  Col,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { QueryClientProvider, useQueryClient } from 'react-query';

import SearchInput from 'components/ui/SearchInput';
import {
  ISessionHostCompany,
  ESessionHostCompanySearchType,
} from 'type/session';
import SessionHostCompanyForm from './SessionHostCompanyForm';
import { useSessionHostCompanies } from 'core/query/session';
import { getModalContainer } from 'util/modal';
import { useBusinessTypes } from 'core/query/company';

export const mockSessionHostCompanies: ISessionHostCompany[] = [
  {
    sessionHostCompanyId: 1,
    companyName: '종근당건강(주)',
    ceoName: '김호곤',
    businessTypes: [
      { businessTypeId: 1, businessTypeName: '제조사' },
      { businessTypeId: 2, businessTypeName: '' },
    ],
    address: 'test',
    addressDetail: 'test test',
    description: 'test test',
    ongoingSessionCount: 1,
    doneSessionCount: 3,
  },
];

const Container = styled.div``;

const SessionHostCompanyList = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [businessTypeId, setBusinessTypeId] = useState<number | undefined>();
  const handleChangeBusinessType = (e: RadioChangeEvent) => {
    setBusinessTypeId(e.target.value);
  };
  const [searchType, setSearchType] = useState<ESessionHostCompanySearchType>(
    ESessionHostCompanySearchType.COMPANY_NAME,
  );
  const [searchKeyword, setSearchKeyword] = useState('');
  const {
    sessionHostCompaniesInfo,
    addSessionHostCompany,
    updateSessionHostCompany,
    refetch,
  } = useSessionHostCompanies({
    page,
    businessTypeId,
    searchType,
    searchKeyword,
  });
  const businessTypes = useBusinessTypes();

  const handleShowAddModal = () => {
    const modal = Modal.info({
      title: '주최 회사 추가',
      width: 520,
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <SessionHostCompanyForm
            onSubmit={(company) => {
              addSessionHostCompany(company, {
                onSuccess: () => {
                  modal.destroy();
                  refetch();
                },
              });
            }}
          />
        </QueryClientProvider>
      ),
      getContainer: getModalContainer,
    });
  };

  const handleShowSessionHostCompanyModal = (sessionHostCompanyId: number) => {
    const modal = Modal.info({
      title: '회사 정보',
      width: 520,
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <SessionHostCompanyForm
            sessionHostCompanyId={sessionHostCompanyId}
            onSubmit={(company) => {
              updateSessionHostCompany(company, {
                onSuccess: () => {
                  modal.destroy();
                  refetch();
                },
              });
            }}
          />
        </QueryClientProvider>
      ),
      getContainer: getModalContainer,
    });
  };
  return (
    <Container>
      <Row
        gutter={16}
        align="middle"
        justify="space-between"
        style={{ marginBottom: 16 }}
      >
        <Col>
          <Typography.Title style={{ fontSize: 18 }}>
            세션 주최 회사
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleShowAddModal}
          >
            추가하기
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: 8 }} justify="space-between">
        <Col>
          <Button icon={<SyncOutlined />} onClick={() => refetch()}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <Radio.Group
                optionType="button"
                value={businessTypeId}
                onChange={handleChangeBusinessType}
              >
                <Radio.Button>전체</Radio.Button>
                {businessTypes.map(({ businessTypeId, businessTypeName }) => (
                  <Radio.Button key={businessTypeId} value={businessTypeId}>
                    {businessTypeName}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Col>
            <Col>
              <Row gutter={4}>
                <Col>
                  <Select
                    value={searchType}
                    options={[
                      {
                        label: '회사명',
                        value: ESessionHostCompanySearchType.COMPANY_NAME,
                      },
                      {
                        label: '대표자',
                        value: ESessionHostCompanySearchType.CEO_NAME,
                      },
                    ]}
                    style={{ width: 100 }}
                    onChange={setSearchType}
                  />
                </Col>
                <Col>
                  <SearchInput
                    defaultValue={searchKeyword}
                    onSearch={setSearchKeyword}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        size="small"
        columns={[
          { title: '번호', align: 'center', dataIndex: 'sessionHostCompanyId' },
          {
            title: '회사명',
            align: 'center',
            render: (_, { sessionHostCompanyId, companyName }) => (
              <Typography.Link
                onClick={() => {
                  handleShowSessionHostCompanyModal(sessionHostCompanyId);
                }}
              >
                {companyName}
              </Typography.Link>
            ),
          },
          { title: '대표자', align: 'center', dataIndex: 'ceoName' },
          {
            title: '업종',
            align: 'center',
            render: (_, { businessTypes }) =>
              `${
                businessTypeId
                  ? businessTypes.find(
                      (businessType) =>
                        businessType.businessTypeId === businessTypeId,
                    )?.businessTypeName
                  : businessTypes[0].businessTypeName
              }${
                businessTypes.length > 1 ? `외 ${businessTypes.length - 1}` : ''
              }`,
          },
          {
            title: (
              <Typography.Text>
                세션 주최 횟수
                <Typography.Text style={{ fontSize: 12 }}>
                  <br />
                  (진행 중 / 종료)
                </Typography.Text>
              </Typography.Text>
            ),
            align: 'center',
            render: (_, { ongoingSessionCount, doneSessionCount }) => (
              <Typography.Text>
                {ongoingSessionCount} / {doneSessionCount}
              </Typography.Text>
            ),
          },
        ]}
        pagination={{
          current: page,
          onChange: setPage,
          pageSize: 10,
          total: sessionHostCompaniesInfo.totalElements,
        }}
        dataSource={sessionHostCompaniesInfo.content}
        rowKey="sessionHostCompanyId"
      />
    </Container>
  );
};

export default SessionHostCompanyList;
