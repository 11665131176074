import { useMemo } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import qs from 'qs';
import { message } from 'antd';

import {
  IBoard,
  EBoardType,
  IBoardComment,
  IEntireBoardComment,
  IBoardReply,
  IBoardNotice,
} from 'type/board';
import api from 'util/api';
import { IAPIPageableResponse, IAPIResponse } from 'type/common';
import { getUpdatingObject } from 'util/form';
import { NO_NEED_TO_UPDATE } from 'shared/message';

// 게시판 목록 조회, 게시판 목록 검색
export const useBoards = ({
  boardType,
  page,
  searchType,
  searchKeyword,
}: {
  page: number;
  boardType?: EBoardType;
  searchType?: 'TITLE' | 'CONTENT' | 'USERNAME' | 'NICKNAME';
  searchKeyword?: string;
}) => {
  const title = searchType === 'TITLE' ? searchKeyword : '';
  const content = searchType === 'CONTENT' ? searchKeyword : '';
  const username = searchType === 'USERNAME' ? searchKeyword : '';
  const nickname = searchType === 'NICKNAME' ? searchKeyword : '';

  const {
    data: { content: boards, totalElements: total } = {
      content: [],
      totalElements: 0,
    },
  } = useQuery(
    ['community', 'boards', boardType, page, searchType, searchKeyword],
    () =>
      api.get<IAPIPageableResponse<IBoard[]>>(
        `/v1/community/boards?${qs.stringify({
          boardType: boardType?.toUpperCase(),
          page,
          title,
          content,
          nickname,
          username,
          size: 10,
        })}`,
      ),
    { select: (res) => res.data.result },
  );

  return useMemo(() => ({ boards, total }), [boards, total]);
};

// 게시글 조회
export const useBoard = (boardId?: number) => {
  const { data: board } = useQuery(
    ['community', 'boards', boardId],
    () => api.get<IAPIResponse<IBoard>>(`/v1/community/boards/${boardId}`),
    { select: (res) => res.data.result, enabled: boardId !== undefined },
  );

  return useMemo(() => ({ board }), [board]);
};

// 게시글 삭제
export const useDeleteBoards = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteBoards, isLoading } = useMutation(
    (boardIds: number[]) => {
      return api.delete(`/v1/community/boards`, {
        data: boardIds,
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['community', 'boards']);
      },
    },
  );

  return useMemo(
    () => ({
      deleteBoards,
      isLoading,
    }),
    [deleteBoards, isLoading],
  );
};

// 전체 댓글 목록 조회
export const useEntireBoardComments = ({
  page,
  boardType,
}: {
  page: number;
  boardType?: EBoardType;
}) => {
  const {
    data: { content: boardComments, totalElements: total } = {
      content: [],
      totalElements: 0,
    },
  } = useQuery(
    ['community', 'comments', boardType, page],
    () =>
      api.get<IAPIPageableResponse<IEntireBoardComment[]>>(
        `/v1/comments?${qs.stringify({
          boardType: boardType?.toUpperCase(),
          page,
          size: 10,
        })}`,
      ),
    { select: (res) => res.data.result },
  );

  return useMemo(() => ({ boardComments, total }), [boardComments, total]);
};

// 특정 게시글 댓글 조회
export const useBoardComments = ({
  boardId,
  page,
}: {
  boardId?: number;
  page: number;
}) => {
  const {
    data: { content: boardComments, totalElements: total } = {
      content: [],
      totalElements: 0,
    },
  } = useQuery(
    ['community', 'comments', boardId, page],
    () =>
      api.get<IAPIPageableResponse<IBoardComment[]>>(
        `/v1/boards/${boardId}/comments?${qs.stringify({ page, size: 10 })}`,
      ),
    { select: (res) => res.data.result },
  );

  return useMemo(() => ({ boardComments, total }), [boardComments, total]);
};

// 댓글 삭제
export const useDeleteBoardComments = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteBoardComments, isLoading } = useMutation(
    (boardCommentIds: number[]) => {
      return api.delete(`/v1/comments`, {
        data: boardCommentIds,
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['community', 'comments']);
      },
    },
  );

  return useMemo(
    () => ({
      deleteBoardComments,
      isLoading,
    }),
    [deleteBoardComments, isLoading],
  );
};

// 댓글의 답글 조회
export const useCommentReplies = ({
  boardId,
  boardCommentId,
  enabled,
}: {
  boardId: number;
  boardCommentId: number;
  enabled?: boolean;
}) => {
  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
    ['community', 'comments', 'replies', boardId, boardCommentId],
    ({ pageParam }) =>
      api.get<IAPIPageableResponse<IBoardReply[]>>(
        `/v1/boards/${boardId}/comments/${boardCommentId}?${qs.stringify({
          page: pageParam,
          size: 10,
        })}`,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, pages) => {
        return lastPage.data.result.totalElements >= pages.length * 10 + 1
          ? pages.length + 1
          : undefined;
      },
    },
  );

  const replies = useMemo(() => {
    return data
      ? data?.pages
          ?.map((page) => page.data.result.content)
          .reduce((acc, cur) => acc.concat(cur))
      : [];
  }, [data]);

  return useMemo(
    () => ({
      replies,
      fetchNextPage,
      hasNextPage,
      isFetching,
    }),
    [replies, isFetching, hasNextPage, fetchNextPage],
  );
};

// 게시물 공지사항
export const useBoardNotice = ({
  boardType,
  boardNoticeId,
}: {
  boardType?: EBoardType;
  boardNoticeId?: number;
}) => {
  const queryClient = useQueryClient();
  const { data: boardNotice = null } = useQuery(
    ['community', 'notice', boardType],
    () =>
      api.get<IAPIResponse<IBoardNotice>>(
        `/v1/board-notice?${qs.stringify({
          boardType: boardType?.toUpperCase(),
        })}`,
      ),
    { select: (res) => res.data.result, enabled: boardType ? true : false },
  );

  // 게시물 공지사항 상세 조회
  const { data: boardNoticeDetail } = useQuery(
    ['comunity', 'notice', boardNoticeId],
    () =>
      api.get<IAPIResponse<IBoardNotice>>(`/v1/board-notices/${boardNoticeId}`),
    {
      select: (res) => res.data.result,
      enabled: boardNoticeId ? true : false,
    },
  );

  // 게시물 공지사항 추가
  const { mutate: addBoardNotice, isLoading: addBoardLoading } = useMutation(
    (boardNotice: IBoardNotice) => {
      const { attaches, boardType, ...restNotice } = boardNotice;
      return api.post('/v1/board-notice', {
        ...restNotice,
        boardType: boardType.toUpperCase(),
        attachIds: attaches.map(({ attachId }) => attachId),
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['community', 'notice']);
      },
    },
  );

  // 게시물 공지사항 수정
  const { mutate: updateBoardNotice, isLoading: updateBoardLoading } =
    useMutation(
      (newNotice: IBoardNotice) => {
        const { attaches, boardType, ...restNotice } = newNotice;
        if (boardNotice === null) {
          throw new Error('Invalid notice');
        }
        const attachIds = attaches.map(({ attachId }) => attachId);
        const isAttachesUpdated =
          boardNotice.attaches.length !== attaches.length ||
          boardNotice.attaches.some(
            ({ attachId }) => !attachIds.includes(attachId),
          );
        const updatingNotice = {
          ...getUpdatingObject(restNotice, boardNotice),
          ...(isAttachesUpdated && { attachIds }),
          boardType: boardType.toUpperCase(),
        };
        if (Object.keys(updatingNotice).length === 0) {
          throw new Error(NO_NEED_TO_UPDATE);
        }
        return api.patch(
          `/v1/board-notices/${boardNotice.boardNoticeId}`,
          updatingNotice,
        );
      },
      {
        onSuccess: () => {
          queryClient.refetchQueries(['community', 'notice']);
        },
        onError: (error: { message: string }) => {
          if (typeof error.message === 'string') {
            message.warn(error.message);
          } else {
            console.error(error);
          }
        },
      },
    );

  // 게시물 공지사항 삭제
  const { mutate: deleteBoardNotice, isLoading: deleteLoading } = useMutation(
    (boardNoticeId: number) =>
      api.delete<IAPIResponse<null>>(`/v1/board-notices/${boardNoticeId}`),
  );

  return {
    boardNotice,
    boardNoticeDetail,
    addBoardNotice,
    addBoardLoading,
    updateBoardNotice,
    updateBoardLoading,
    deleteBoardNotice,
    deleteLoading,
  };
};

// 게시판 관리자 글쓰기
export const useBoardAdmin = () => {
  // 게시글 추가
  const { mutate: addBoardAdmin, isLoading: addLoading } = useMutation(
    ({
      boardType,
      ...rest
    }: {
      title: string;
      content: string;
      attachIds?: number[];
      boardType: EBoardType;
    }) =>
      api.post<IAPIResponse<null>>('/v1/community/boards', {
        boardType: boardType.toUpperCase(),
        ...rest,
      }),
  );

  // 게시글 수정
  const { mutate: updateBoardAdmin, isLoading: updateLoading } = useMutation(
    ({
      boardId,
      boardType,
      ...rest
    }: {
      boardId: number;
      title: string;
      content: string;
      attachIds?: number[];
      boardType: EBoardType;
    }) =>
      api.patch<IAPIResponse<null>>(`/v1/community/boards/${boardId}`, {
        boardType: boardType.toUpperCase(),
        ...rest,
      }),
  );

  return {
    addBoardAdmin,
    addLoading,
    updateBoardAdmin,
    updateLoading,
  };
};
