import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { AdDataType, EPeriodRangeType, IPeriod } from 'type/stats';
import { useAdStats } from 'core/query/stats';
import { Typography, PageSizeSelect } from 'components/ui';

const Container = styled.div``;

const TableWrapper = styled.div`
  padding: 24px 32px 48px;
  background-color: #fff;

  .ant-table-thead > tr > th {
    background-color: #eff8f3;
  }
`;

interface IBannerColumns {
  statisticsDate: string;
  pb001Code?: string;
  allCount: number;
  sessionCount: number;
  communityCount: number;
}

const pageSizeOptions = [
  { label: '10개씩 보기', value: 10 },
  { label: '20개씩 보기', value: 20 },
  { label: '30개씩 보기', value: 30 },
];

const BannerAdChartTable = ({
  title,
  companyId,
  period,
  periodType,
  dataType,
  advertisingType,
  style,
}: {
  title: string;
  companyId: number;
  period: IPeriod;
  periodType: EPeriodRangeType;
  dataType: AdDataType;
  advertisingType: 'BANNER' | 'MATERIAL';
  style?: React.CSSProperties;
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const { tableLabels, datasets, isLoading } = useAdStats({
    advertisingCompanyId: companyId,
    advertisingType,
    dataType,
    period,
    periodType,
    page,
    size: pageSize,
    chartType: 'RAW',
  });
  const handlePageSize = (size: number) => {
    setPageSize(size);
    setPage(1);
  };
  const columns: ColumnsType<IBannerColumns> = [
    {
      title: '일자',
      align: 'center',
      dataIndex: 'statisticsDate',
      render: (_, { statisticsDate }) => (
        <Typography.Text type="b2" color="SLATEGRAY70">
          {statisticsDate}
        </Typography.Text>
      ),
    },
    ...(periodType !== EPeriodRangeType.MONTH
      ? ([
          {
            title: '세션&커뮤니티 배너 코드',
            align: 'center',
            dataIndex: 'pb001Code',
            render: (_, { pb001Code }) => (
              <Typography.Text type="b2" color="SLATEGRAY50">
                {pb001Code || '-'}
              </Typography.Text>
            ),
          },
        ] as ColumnsType<IBannerColumns>)
      : []),
    {
      title: '총합',
      align: 'center',
      dataIndex: 'allCount',
      render: (_, { allCount }) => (
        <Typography.Text type="b2" color="PRIMARYGREEN60" bold>
          {allCount ?? '-'}
        </Typography.Text>
      ),
    },
    {
      title: '세션 배너',
      align: 'center',
      dataIndex: 'sessionCount',
      render: (_, { sessionCount }) => (
        <Typography.Text type="b2" color="SLATEGRAY70" bold>
          {sessionCount ?? '-'}
        </Typography.Text>
      ),
    },
    {
      title: '커뮤니티 배너',
      align: 'center',
      dataIndex: 'communityCount',
      render: (_, { communityCount }) => (
        <Typography.Text type="b2" color="SLATEGRAY70" bold>
          {communityCount ?? '-'}
        </Typography.Text>
      ),
    },
  ];

  const tableData = useMemo(
    () =>
      tableLabels
        .map((label, index) => ({
          statisticsDate: label,
          ...(periodType !== EPeriodRangeType.MONTH && {
            pb001Code: datasets[0].datalabels[index],
          }),
          allCount: datasets[0].data[index],
          sessionCount: datasets[1].data[index],
          communityCount: datasets[2].data[index],
        }))
        .sort(
          (a, b) =>
            new Date(b.statisticsDate).getTime() -
            new Date(a.statisticsDate).getTime(),
        ),

    [tableLabels, datasets, periodType],
  );

  return (
    <Container style={style}>
      <Typography.Text type="h2" gutter={{ bottom: 16 }} color="BLACK20">
        {title}
      </Typography.Text>
      <TableWrapper>
        <PageSizeSelect
          pageSize={pageSize}
          onChange={handlePageSize}
          options={pageSizeOptions}
        />
        <Table
          style={{ width: '100%' }}
          loading={isLoading}
          dataSource={tableData}
          pagination={{
            pageSize,
            current: page,
            onChange: setPage,
            total: 0,
          }}
          columns={columns}
          rowKey="statisticsDate"
        />
      </TableWrapper>
    </Container>
  );
};

export default BannerAdChartTable;
