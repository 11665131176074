import { useCallback, useMemo } from 'react';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';

import { IAPIPageableResponse, IAPIResponse } from 'type/common';
import {
  IAdBannerDetail,
  IAdBannerListItem,
  IAdCompanyListItem,
  IAdCompanyRegisterParams,
  IAdInquiry,
  IAdProduct,
  IAdvertisingProductCount,
  IPurchaseAdvertising,
} from 'type/ad';
import api from 'util/api';
import { useNewAdvertisingAffiliationInquiryKey } from 'util/hooks/useNotifications';

const queryKeys = {
  adBannerListItemsKey: 'ad/banner/list',
  adBannerDetailKey: 'ad/banner/detail',
  adCalendarCountsKey: 'ad/adCalendarCounts',
  adOngoingProductsKey: 'ad/banner/ongoingProducts',
  adCompaniesKey: 'ad/advertising-companies',
  adBannerProduct: 'ad/banner/product',
  adBannerCompanyList: 'ad/banner/companyList',
};

const generateAdInquiriesQueryKey = ({
  page,
  companyName,
}: {
  page: number;
  companyName: string;
}) => [`ad/inquiries`, page, companyName];

export const useAdInquiries = (
  params: {
    page: number;
    companyName: string;
  } = {
    page: 1,
    companyName: '',
  },
) => {
  const queryClient = useQueryClient();

  const {
    data: { content: adInquiries = [], totalElements: total } = {
      content: [],
      totalElements: 0,
    },
    isLoading,
  } = useQuery(
    generateAdInquiriesQueryKey(params),
    () =>
      api.get<IAPIPageableResponse<IAdInquiry[]>>(
        `/v1/advertising-affiliation-inquiries`,
        {
          params: { ...params, size: 10 },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  // HINT: 미지정일 경우 businessAdmin, businessManagerName null 전달
  const updateBusinessManagerCache = useCallback(
    (
      index: number,
      businessAdminId: number | null,
      businessManagerName: string | null,
    ) => {
      queryClient.setQueryData<
        AxiosResponse<IAPIPageableResponse<IAdInquiry[]>> | undefined
      >(generateAdInquiriesQueryKey(params), (res) => {
        if (res) {
          const content = res.data.result.content;
          try {
            content[index].businessAdminId = businessAdminId;
            content[index].businessManagerName = businessManagerName;
          } catch {}
        }

        return res;
      });
    },
    [queryClient, params],
  );

  return {
    adInquiries,
    updateBusinessManagerCache,
    total,
    isLoading,
  };
};

export const useAdInquiry = (adInquiryId = 0) => {
  const { data: adInquiry, isLoading } = useQuery(
    [`ad/inquiry`, adInquiryId],
    () =>
      api.get<IAPIResponse<IAdInquiry>>(
        `/v1/advertising-affiliation-inquiries/${adInquiryId}`,
      ),
    {
      select: (res) => res.data.result,
      enabled: adInquiryId > 0,
    },
  );

  return { adInquiry, isLoading };
};

export const useUpdateAdInquiryMemo = () => {
  const queryClient = useQueryClient();

  const { mutate: updateAdInquiryMemo, isLoading } = useMutation(
    ({ adInquiryId, memo }: { adInquiryId: number; memo: string }) => {
      return api.put(
        `/v1/advertising-affiliation-inquiries/${adInquiryId}/memo`,
        { memo },
      );
    },
    {
      onSuccess: (_, { adInquiryId, memo }) => {
        message.success('저장되었습니다.');
        if (!adInquiryId) return;

        // HINT: refetch 받는 경우 저장 후 약 1초 뒤 로딩 화면이 표시되는 불편함이 있어,
        // 캐시를 수동으로 업데이트함
        queryClient.setQueryData<
          AxiosResponse<IAPIResponse<IAdInquiry>> | undefined
        >(['ad/inquiry', adInquiryId], (res) => {
          if (res) {
            res.data.result.adminMemo = memo;
          }

          return res;
        });
      },
    },
  );

  return { updateAdInquiryMemo, isLoading };
};

export const useUpdateAdInquiryBusinessManager = () => {
  const queryClient = useQueryClient();

  const { mutate: updateAdInquiryBusinessManager, isLoading } = useMutation(
    ({
      adInquiryId,
      adminId,
    }: {
      adInquiryId: number;
      adminId: number | null;
    }) =>
      api.put(
        `/v1/advertising-affiliation-inquiries/${adInquiryId}/business-manager`,
        { adminId },
      ),
    {
      onSuccess: () => {
        message.success('변경되었습니다.');
        queryClient.refetchQueries(useNewAdvertisingAffiliationInquiryKey);
      },
    },
  );

  return { updateAdInquiryBusinessManager, isLoading };
};

export const useAdCompanyListItems = ({ page }: { page: number }) => {
  const { data } = useQuery(
    [queryKeys.adCompaniesKey, page],
    () =>
      api.get<IAPIPageableResponse<IAdCompanyListItem[]>>(
        `/v1/advertising-companies`,
        {
          params: {
            page,
            size: 10,
          },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return {
    adCompanyListItems: data?.content || [],
    totalElements: data?.totalElements,
  };
};

export const useCheckBizNumberIsDuplicated = () => {
  const { mutate, isLoading } = useMutation((bizNumber: string) =>
    api.get<IAPIResponse<boolean>>(
      `/v1/advertising-companies/biz-number-duplication`,
      {
        params: {
          bizNumber,
        },
      },
    ),
  );

  const checkSampleIsDuplicated = _.debounce(mutate, 100);

  return {
    checkSampleIsDuplicated,
    isLoading,
  };
};

export const useRegisterAdCompany = () => {
  const queryClient = useQueryClient();
  const { mutate: registerAdCompany, isLoading } = useMutation(
    (params: IAdCompanyRegisterParams) =>
      api.post('/v1/advertising-companies', params),
    {
      onSuccess: () =>
        queryClient.refetchQueries(queryKeys.adCompaniesKey, { active: true }),
    },
  );

  return {
    registerAdCompany,
    isLoading,
  };
};

export const useDeleteAdCompany = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (companyId: number) => api.delete(`/v1/advertising-companies/${companyId}`),
    {
      onSuccess: () =>
        queryClient.refetchQueries(queryKeys.adCompaniesKey, { active: true }),
    },
  );

  return {
    mutate,
  };
};

export const useAdBannerOptions = () => {
  const { data: adCompany } = useQuery(
    [queryKeys.adBannerCompanyList],
    () =>
      api.get<IAPIResponse<IAdCompanyListItem[]>>(`/v1/advertising-companies`),
    {
      select: (res) => res.data.result,
    },
  );

  const adCompanySelectOptions = useMemo(
    () =>
      adCompany?.map((item) => ({
        label: `${item.companyName} ${item.bizNumber}`,
        value: item.companyId,
      })),
    [adCompany],
  );

  const { data: adProduct } = useQuery(
    [queryKeys.adBannerProduct],
    () =>
      api.get<IAPIResponse<IAdProduct[]>>(`/v1/advertising-products`, {
        params: { advertisingType: 'BANNER' },
      }),
    { select: (res) => res.data.result, staleTime: Number.MAX_VALUE },
  );

  const adProductSelectOptions = useMemo(
    () =>
      adProduct?.map(({ productId, productName }) => ({
        label: productName,
        value: productId,
      })),
    [adProduct],
  );

  return {
    adCompany,
    adCompanySelectOptions,
    adProductSelectOptions,
  };
};

export const useAdBannerDetail = (
  advertisingCompanyPurchaseProductId?: number,
) => {
  const queryClient = useQueryClient();

  const { data: adBannerDetail } = useQuery(
    [queryKeys.adBannerDetailKey, advertisingCompanyPurchaseProductId],
    () =>
      api.get<IAPIResponse<IAdBannerDetail>>(
        `/v1/advertising-banner-products/${advertisingCompanyPurchaseProductId}`,
      ),
    {
      select: (res) => res.data.result,
      enabled: typeof advertisingCompanyPurchaseProductId !== 'undefined',
    },
  );

  const { mutate: registerAdBanner, isLoading: registerAdBannerLoading } =
    useMutation(
      (params: FormData) =>
        api.post<IAPIResponse<null>>(`/v1/advertising-banner-products`, params),
      {
        onSuccess: () => {
          message.success('등록되었습니다.');
          queryClient.refetchQueries(queryKeys.adBannerListItemsKey, {
            active: true,
          });
        },
        onError: (error: { response: { data: { code: string } } }) => {
          if (error.response.data.code === 'ACPP002') {
            message.warn('같은 날짜에는 최대 1개의 광고만 등록이 가능합니다.');
          }
        },
      },
    );

  const { mutate: updateAdBanner, isLoading: updateAdBannerLoading } =
    useMutation(
      ({
        companyPurchaseProductId,
        params,
      }: {
        companyPurchaseProductId: number;
        params: FormData;
      }) =>
        api.post<IAPIResponse<null>>(
          `/v1/advertising-banner-products/${companyPurchaseProductId}`,
          params,
        ),
      {
        onSuccess: () => {
          message.success('변경되었습니다.');
          queryClient.refetchQueries(queryKeys.adBannerListItemsKey, {
            active: true,
          });
          queryClient.refetchQueries(queryKeys.adCalendarCountsKey, {
            active: true,
          });
          queryClient.refetchQueries(queryKeys.adOngoingProductsKey, {
            active: true,
          });
        },
        onError: (error: { response: { data: { code: string } } }) => {
          if (error.response.data.code === 'ACPP002') {
            message.warn('같은 날짜에는 최대 1개의 광고만 등록이 가능합니다.');
          }
        },
      },
    );

  return {
    adBannerDetail,
    registerAdBanner,
    updateAdBanner,
    isLoading: registerAdBannerLoading || updateAdBannerLoading,
  };
};

export const useAdCalendarCounts = ({
  startDate = '',
  endDate = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  advertisingType,
}: {
  startDate?: string;
  endDate?: string;
  advertisingType: 'BANNER' | 'MATERIAL';
}) => {
  const { data: adCalendarCounts = [], isLoading } = useQuery(
    [queryKeys.adCalendarCountsKey, startDate, endDate],
    () =>
      api.get<IAPIResponse<IAdvertisingProductCount[]>>(
        '/v1/advertising-product-counts',
        {
          params: {
            advertisingType,
            startDate,
            endDate,
          },
        },
      ),
    {
      select: (res) => res.data.result,
      enabled: startDate !== '' && endDate !== '',
    },
  );

  return {
    adCalendarCounts,
    isLoading,
  };
};

export const useAdBannerListItems = ({
  page,
  searchValue,
}: {
  page: number;
  searchValue: string;
}) => {
  const queryClient = useQueryClient();

  const {
    data: { content: adBannerListItems = [], totalElements: total = 0 } = {},
    isLoading,
  } = useQuery(
    [queryKeys.adBannerListItemsKey, page, searchValue],
    () =>
      api.get<IAPIPageableResponse<IAdBannerListItem[]>>(
        `/v1/advertising-banner-products`,
        {
          params: {
            page,
            size: 10,
            companyName: searchValue,
            // HINT: 코스브릿지에서는 productCode가 PB001만 있음. 나중에 타입이 추가되면 파라미터로 입력받아 처리 필요
            productCode: 'PB001',
          },
        },
      ),
    { select: (res) => res.data.result },
  );

  const { mutate: toggleDisplay } = useMutation(
    ({ companyPurchaseProductId, isDisplay }: Partial<IAdBannerListItem>) =>
      api.put<IAPIResponse<null>>(
        `/v1/advertising-banner-products/${companyPurchaseProductId}/display`,
        { isDisplay: !isDisplay },
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries([queryKeys.adBannerListItemsKey, page]);
        message.success('변경되었습니다.');
      },
    },
  );

  return { adBannerListItems, isLoading, total, toggleDisplay };
};

export const useAdOngoingProducts = ({ date }: { date: string }) => {
  const { data: adOngoingProducts = [], isLoading } = useQuery(
    [queryKeys.adOngoingProductsKey, date],
    () =>
      api.get<IAPIResponse<IPurchaseAdvertising[]>>(
        // HINT: 코스브릿지에서는 productCode가 PB001만 있음. 나중에 타입이 추가되면 파라미터로 입력받아 처리 필요
        `/v1/advertising-products/PB001/purchase-advertisings`,
        {
          params: {
            date,
          },
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  return {
    adOngoingProducts,
    isLoading,
  };
};
