import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  IBeautyFairCountryListItem,
  IBeautyFairListItem,
  IBeautyFairParams,
  IBeautyFairSchedulesDetail,
  IBeautyFairSearchParams,
  IBusinessSupportDetail,
  IBusinessSupportListItem,
  IBusinessSupportSearchParams,
} from 'type/business';
import { IAPIPageableResponse, IAPIResponse } from 'type/common';
import api from 'util/api';

const queryKeys = {
  getBusinessSupport: 'business/getBusinessSupport',
  getBusinessSupportDetail: 'business/getBusinessSupportDetail',
  getBeautyFair: 'business/getBeautyFair',
  getBeautyFairDetail: 'business/getBeautyFairDetail',
  getBeautyFairCountry: 'business/getBeautyFairCountry',
};

// 지원 사업
export const useBusinessSupport = (params: IBusinessSupportSearchParams) => {
  const queryClient = useQueryClient();
  const [emptyArray] = useState<IBusinessSupportListItem[]>([]);

  const { data, isLoading: getLoading } = useQuery(
    [queryKeys.getBusinessSupport, params],
    () =>
      api.get<IAPIPageableResponse<IBusinessSupportListItem[]>>(
        '/v1/admin/business-supports',
        {
          params,
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const { mutate: changeBusinessSupportDisplay, isLoading: changeLoading } =
    useMutation(
      (params: { businessSupportIds: number[]; isDisplay: boolean }) =>
        api.patch<IAPIResponse<null>>(
          `/v1/admin/business-supports/display`,
          params,
        ),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.getBusinessSupport);
        },
      },
    );

  return {
    businessSupportListItems: data?.content || emptyArray,
    total: data?.totalElements || 0,
    isLoading: getLoading || changeLoading,
    changeBusinessSupportDisplay,
  };
};

export const useBusinessSupportDetail = (businessSupportId: number) => {
  const { data: businessSupportDetail } = useQuery(
    [queryKeys.getBusinessSupportDetail, businessSupportId],
    () =>
      api.get<IAPIResponse<IBusinessSupportDetail>>(
        `/v1/admin/business-supports/${businessSupportId}`,
      ),
    {
      select: (res) => res.data.result,
    },
  );
  return { businessSupportDetail };
};

// 뷰티 박람회
export const useBeautyFair = (params: IBeautyFairSearchParams) => {
  const queryClient = useQueryClient();
  const [emptyArray] = useState<IBeautyFairListItem[]>([]);

  const { data, isLoading: getLoading } = useQuery(
    [queryKeys.getBeautyFair, params],
    () =>
      api.get<IAPIPageableResponse<IBeautyFairListItem[]>>(
        '/v1/beauty-fair/schedules',
        {
          params,
        },
      ),
    {
      select: (res) => res.data.result,
    },
  );

  const { mutate: changeBeautyFairDisplay, isLoading: changeLoading } =
    useMutation(
      (params: { beautyFairScheduleIds: number[]; isDisplay: boolean }) =>
        api.put<IAPIResponse<null>>(
          `/v1/beauty-fair/schedules/display`,
          params,
        ),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.getBeautyFair);
        },
      },
    );

  return {
    beautyFairListItems: data?.content || emptyArray,
    total: data?.totalElements || 0,
    isLoading: getLoading || changeLoading,
    changeBeautyFairDisplay,
  };
};

export const useBeautyFairCountry = () => {
  const [emptyArray] = useState<IBeautyFairCountryListItem[]>([]);
  const { data: beautyFairCountries = emptyArray, isLoading } = useQuery(
    [queryKeys.getBeautyFairCountry],
    () =>
      api.get<IAPIResponse<IBeautyFairCountryListItem[]>>(
        `/v1/beauty-fair/countries`,
      ),
    {
      select: (res) => res.data.result,
    },
  );
  return { beautyFairCountries, getLoading: isLoading };
};

export const useAddBeautyFair = () => {
  const queryClient = useQueryClient();

  const { mutate: addBeautyFair, isLoading } = useMutation(
    (params: IBeautyFairParams) =>
      api.post<IAPIResponse<null>>(`/v1/beauty-fair/schedules`, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getBeautyFair);
      },
    },
  );

  return { addBeautyFair, addLoading: isLoading };
};

export const useGetBeautyFairDetail = ({
  beautyFairScheduleId,
}: {
  beautyFairScheduleId?: number;
}) => {
  const { data: beautyFairDetail, isLoading } = useQuery(
    [queryKeys.getBeautyFairDetail, beautyFairScheduleId],
    () =>
      api.get<IAPIResponse<IBeautyFairSchedulesDetail>>(
        `/v1/beauty-fair/schedules/${beautyFairScheduleId}`,
      ),
    {
      select: (res) => res.data.result,
      enabled: beautyFairScheduleId !== undefined,
    },
  );

  return { beautyFairDetail, isLoading };
};

export const useUpdateBeautyFair = ({
  beautyFairScheduleId,
}: {
  beautyFairScheduleId?: number;
}) => {
  const queryClient = useQueryClient();

  const { mutate: updateBeautyFair, isLoading } = useMutation(
    (params: IBeautyFairSchedulesDetail) =>
      api.patch<IAPIResponse<null>>(
        `/v1/beauty-fair/schedules/${beautyFairScheduleId}`,
        params,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getBeautyFair);
      },
    },
  );

  return { updateBeautyFair, updateLoading: isLoading };
};
