import { Route, Routes } from 'react-router-dom';

import path from 'shared/path';
import AdRoutes from './AdRoutes';
import CommunityRoutes from './CommunityRoutes';
import BusinessRoutes from './BusinessRoutes';
import MemberRoutes from './MemberRoutes';
import SessionRoutes from './SessionRoutes';
import CSRoutes from './CSRoutes';
import Report from 'components/Report/Report';
import StatsRoutes from './StatsRoutes';

const RootRoutes = () => {
  return (
    <Routes>
      <Route path={`${path.session.root}/*`} element={<SessionRoutes />} />
      <Route path={`${path.ad.root}/*`} element={<AdRoutes />} />
      <Route path={`${path.stats.root}/*`} element={<StatsRoutes />} />
      <Route path={`${path.community.root}/*`} element={<CommunityRoutes />} />
      <Route path={`${path.business.root}/*`} element={<BusinessRoutes />} />
      <Route path={`${path.member.root}/*`} element={<MemberRoutes />} />
      <Route path={`${path.report.root}/*`} element={<Report />} />
      <Route path={`${path.cs.root}/*`} element={<CSRoutes />} />
    </Routes>
  );
};

export default RootRoutes;
