import { Route, Routes } from 'react-router-dom';

import BannerAdCompanyList from 'components/stats/BannerAdCompanyList';
import BannerAdChartPage from 'pages/BannerAdChartPage';

const StatsRoutes = () => {
  return (
    <Routes>
      <Route path="/banner-ad" element={<BannerAdCompanyList />} />
      <Route path="/banner-ad/:companyId" element={<BannerAdChartPage />} />
    </Routes>
  );
};

export default StatsRoutes;
