import { useEffect } from 'react';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import SockJS from 'sockjs-client';
import { AxiosResponse } from 'axios';
import StompJS from 'stompjs';
import { useRecoilValue } from 'recoil';
import produce from 'immer';

import { authState } from 'core/store/auth';
import api from 'util/api';
import { IAPIResponse } from 'type/common';
import { INewAlarmExists } from 'type/notification';

export const useNewClinicalEstimateRequestKey =
  'new-clinical-trial-estimate-request';
export const useNewAdvertisingAffiliationInquiryKey =
  '/v1/admin/alarm/new-advertising-affiliation-inquiry';

export const useNewExists = () => {
  const { data: hasNewAdInquiry } = useQuery(
    [useNewAdvertisingAffiliationInquiryKey],
    () => api.get('/v1/badge/advertising-affiliation-inquiries'),
    {
      select: (res) => res.data.result,
    },
  );

  return {
    hasNewAdInquiry,
  };
};

export const NotificationSubscription = () => {
  const queryClient = useQueryClient();
  const auth = useRecoilValue(authState);

  useEffect(() => {
    if (!auth?.accessToken) return;

    const socket = new SockJS(
      `${process.env.REACT_APP_SOCKET_HOST}/api/v1/socket/connection`,
    );

    const client = StompJS.over(socket);
    const subscribeNewAlarm = generateNewAlarmSubscriber({
      client,
      queryClient,
      accessToken: auth.accessToken,
    });

    if (process.env.NODE_ENV !== 'development') {
      client.debug = () => undefined;
    }

    client.connect(
      {
        accessToken: auth.accessToken,
      },
      () => {
        // HINT: 광고 및 제휴 문의
        subscribeNewAlarm({
          apiUrl: `/api/v1/socket/subscribe/badge/advertising-affiliation-inquiry`,
          queryKey: useNewAdvertisingAffiliationInquiryKey,
        });
      },
    );

    return () => {
      client.disconnect(() => undefined, {
        accessToken: auth.accessToken,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.accessToken]);

  return null;
};

const generateNewAlarmSubscriber =
  ({
    client,
    queryClient,
    accessToken,
  }: {
    client: StompJS.Client;
    queryClient: QueryClient;
    accessToken: string;
  }) =>
  ({ apiUrl, queryKey }: { apiUrl: string; queryKey: string }) => {
    client.subscribe(
      apiUrl,
      ({ body }) => {
        const result = JSON.parse(body) as INewAlarmExists;

        queryClient.setQueryData(
          queryKey,
          (draftNotificationsResponse: object) =>
            produce(
              draftNotificationsResponse,
              (proxy: AxiosResponse<IAPIResponse<boolean>>) => {
                proxy.data.result = result.isNewAlarmExists;
              },
            ),
        );
      },
      {
        accessToken,
      },
    );
  };
