import { useCallback } from 'react';
import styled from '@emotion/styled';
import { Button, Form, Input, message, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { requiredRule } from 'shared/rule';
import { IAdCompanyRegisterParams } from 'type/ad';
import {
  useCheckBizNumberIsDuplicated,
  useRegisterAdCompany,
} from 'core/query/ad';

const Divider = styled.div`
  position: relative;
  border-top: 1px solid #f0f0f0;
  padding: 10px 0px;
  height: 52px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-form {
    padding-top: 24px;
    & > * {
      padding: 0px 24px;
    }
  }
`;

const AdCompanyRegisterModal = ({ onClose }: { onClose: () => void }) => {
  const [form] = Form.useForm<IAdCompanyRegisterParams>();
  const { checkSampleIsDuplicated } = useCheckBizNumberIsDuplicated();
  const { registerAdCompany, isLoading } = useRegisterAdCompany();

  const handleClickDeposit = useCallback(
    (formValue: IAdCompanyRegisterParams) => {
      registerAdCompany(formValue, {
        onSuccess: () => {
          message.success('등록되었습니다.');
          onClose();
        },
      });
    },
    [onClose, registerAdCompany],
  );

  return (
    <StyledModal
      width={572}
      title="광고 진행 회사 추가"
      visible
      footer={null}
      onCancel={onClose}
      maskClosable={false}
    >
      <Form form={form} onFinish={handleClickDeposit}>
        <Form.Item
          label="회사(소속)명 : "
          labelCol={{ span: 5 }}
          name="companyName"
          rules={[
            requiredRule,
            {
              max: 50,
              message: '최대 50자',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          required
          label="사업자등록번호"
          name="bizNumber"
          normalize={(value) => {
            if (value.length > 12) return value.substring(0, 12);
            if (/[^0-9-]/.test(value)) return value.replace(/[^0-9-]/, '');
            if (value.slice(-1) === '-') return value;
            return value
              .replace(/[-]/g, '')
              .replace(/^(\d{3})(\d{1,})/, '$1-$2')
              .replace(/^(\d{3}-\d{2})(\d{1,})/, '$1-$2');
          }}
          rules={[
            requiredRule,
            {
              pattern: /^\d{3}-\d{2}-\d{5}$/,
              message: '올바르지 않은 사업자등록번호 형식',
            },
            {
              validator: (_, value) => {
                if (value) {
                  return new Promise((resolve, reject) => {
                    checkSampleIsDuplicated(value, {
                      onSuccess: (res) => {
                        if (res.data.result) {
                          reject('중복된 사업자등록증');
                        }
                        resolve('');
                      },
                    });
                  });
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="회사소개 : "
          labelCol={{ span: 5 }}
          name="companyIntroduction"
          rules={[
            {
              max: 250,
              message: '최대 250자',
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Divider>
          <Button
            type="primary"
            loading={isLoading}
            style={{ position: 'absolute', top: 10, right: 24 }}
            htmlType="submit"
          >
            저장
          </Button>
        </Divider>
      </Form>
    </StyledModal>
  );
};

export default AdCompanyRegisterModal;
