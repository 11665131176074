import { DatePicker } from 'antd';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

const SessionOpenDatePicker = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (date: string) => void;
}) => {
  return (
    <DatePicker
      value={value ? moment(value) : undefined}
      format={DATE_FORMAT}
      disabledDate={(date) => date.isBefore(moment().subtract(1, 'days'))}
      onChange={(_, date) => {
        onChange && onChange(date);
      }}
      style={{ width: 180 }}
    />
  );
};

export default SessionOpenDatePicker;
