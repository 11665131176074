import axios from 'axios';
import api from './api';

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const base64ToFile = (base64String: string, filename: string) => {
  const arr = base64String.split(',');
  const mime = arr?.[0].match(/:(.*?);/)?.[1] || '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getResponseHeaderValueByKey = ({
  target,
  key,
  seperator = ';',
}: {
  target: string;
  key: string;
  seperator?: string;
}) => {
  if (target) {
    const re = new RegExp(`(?<=${key}=).*(?=${seperator})?`, 'gi');
    const result = target.match(re);

    if (result && result.length > 0) {
      return result[0];
    }
  }

  return null;
};

export const getFilenameFromUrl = (url: string, excludeSuffix = false) => {
  if (
    !url ||
    !url.includes('/') ||
    (!url.startsWith('/files') && !url.includes('.'))
  ) {
    throw new Error('Invalid URL');
  }
  return decodeURIComponent(
    url.slice(
      url.lastIndexOf('/') + 1,
      excludeSuffix ? url.lastIndexOf('.') : undefined,
    ),
  );
};

export const downloadFile = async (
  file: File | string,
  name?: string,
  options?: {
    useClient?: boolean;
  },
) => {
  if (file instanceof File) {
    const path = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = path;
    link.download = file.name;
    link.click();
    link.remove();
  } else {
    let response;

    if (file.startsWith('/files') || options?.useClient) {
      response = await api.get(`${process.env.REACT_APP_API_HOST}${file}`, {
        responseType: 'blob',
      });
    } else {
      response = await axios.get(file, {
        responseType: 'blob',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
    }

    const filenameFromHeader = getResponseHeaderValueByKey({
      target: response.headers['content-disposition'],
      key: 'filename',
    });

    const filename = name || filenameFromHeader || getFilenameFromUrl(file);

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(response.data);
    a.download = filename;
    a.click();
  }
};

export const downloadFileFromUrl = async (
  fileUrl: string,
  filename: string,
) => {
  const link = document.createElement('a');
  const response = await axios.get(fileUrl, { responseType: 'blob' });
  link.href = window.URL.createObjectURL(response.data);
  link.download = filename;
  link.click();
  link.remove();
};

export const urlToFile = async (
  url: string,
  filename: string,
  options?: {
    useOldS3Path?: boolean;
  },
) => {
  let newUrl = url;

  if (options?.useOldS3Path) {
    newUrl = url.replace(/s3\.([^/]+)\.com\/([^/]+)/, '$2.s3.$1.com');
  }
  const res = await axios.get<Blob>(newUrl, {
    responseType: 'blob',
  });
  return new File([res.data], filename);
};
