import { SearchOutlined } from '@ant-design/icons';
import { Input, InputProps } from 'antd';
import { MutableRefObject, useEffect, useRef } from 'react';

import { theme } from 'styles/theme';

interface ISearchInputProps extends InputProps {
  defaultValue?: string;
  onSearch: (keyword: string) => void;
  inputRef?: MutableRefObject<Input | null>;
}

const SearchInput = ({
  defaultValue = '',
  onSearch,
  inputRef: externalInputRef,
  ...restProps
}: ISearchInputProps) => {
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (defaultValue !== inputRef.current?.input.value) {
      inputRef.current?.setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!externalInputRef) return;

    externalInputRef.current = inputRef.current;
  }, [externalInputRef]);

  return (
    <Input
      ref={inputRef}
      suffix={
        <SearchOutlined
          style={{ cursor: 'pointer', color: theme.colors.GRAY70 }}
          onClick={() => onSearch(inputRef.current?.input.value || '')}
        />
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSearch(inputRef.current?.input.value || '');
        }
      }}
      {...restProps}
    />
  );
};

export default SearchInput;
