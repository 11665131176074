import styled from '@emotion/styled';
import { Button, Modal, Table, Typography } from 'antd';

import { useReports, useWithdrawReport } from 'core/query/report';
import { reportTypeMap } from 'shared/consts';
import { EReportKind, EReportStatus, EReportType } from 'type/common';

const Container = styled.div``;

const ReportListModalContent = ({
  id,
  reportKind,
  title,
}: {
  id: number;
  reportKind: EReportKind;
  title: string;
}) => {
  const reports = useReports({ id, reportKind });
  const withdrawReport = useWithdrawReport();
  const handleWithdrawReport = (reportId: number) => {
    withdrawReport({ reportId, reportKind });
  };
  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 8 }}>
        {title}
      </Typography.Title>
      <Typography.Text strong>
        신고수 {reports.filter((report) => report.status === 'NORMAL').length}
      </Typography.Text>
      <Table
        rowKey="reportId"
        dataSource={reports}
        size="small"
        showHeader={false}
        style={{ marginTop: 8 }}
        pagination={false}
        columns={[
          { dataIndex: 'reportUsername' },
          {
            align: 'center',
            render: (_, { reportType, reportReason }) =>
              reportType !== EReportType.ETC ? (
                <Typography.Text>{reportTypeMap[reportType]}</Typography.Text>
              ) : (
                <Button
                  size="small"
                  onClick={() => {
                    Modal.success({ icon: null, content: reportReason });
                  }}
                >
                  사유 보기
                </Button>
              ),
          },
          {
            align: 'right',
            render: (_, { reportId, status, withdrawDt }) =>
              status === EReportStatus.NORMAL ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleWithdrawReport(reportId)}
                >
                  신고 철회
                </Button>
              ) : (
                withdrawDt?.slice(0, 10)
              ),
          },
        ]}
      />
    </Container>
  );
};

export default ReportListModalContent;
