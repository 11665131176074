import styled from '@emotion/styled';

import { EPeriodRangeType, IPeriod } from 'type/stats';
import BannerAdChartTable from './BannerAdChartTable';

const Container = styled.div``;

const BannerAdChartTableList = ({
  companyId,
  period,
  periodType,
}: {
  companyId: number;
  period: IPeriod;
  periodType: EPeriodRangeType;
}) => {
  return (
    <Container>
      <BannerAdChartTable
        companyId={companyId}
        title="유저 노출 횟수"
        period={period}
        periodType={periodType}
        advertisingType="BANNER"
        dataType="exposureCount"
      />
      <BannerAdChartTable
        style={{ marginTop: 24 }}
        companyId={companyId}
        title="구좌별 클릭 수"
        period={period}
        periodType={periodType}
        advertisingType="BANNER"
        dataType="clickCount"
      />
      <BannerAdChartTable
        style={{ marginTop: 24 }}
        companyId={companyId}
        title="노출대비 클릭 수"
        period={period}
        periodType={periodType}
        advertisingType="BANNER"
        dataType="rate"
      />
    </Container>
  );
};

export default BannerAdChartTableList;
