import { useMemo, useState } from 'react';
import { Button, Modal, Table, Typography } from 'antd';
import styled from '@emotion/styled';
import { ColumnsType } from 'antd/lib/table';

import { useAdOngoingProducts } from 'core/query/ad';
import { theme } from 'styles/theme';
import { IPurchaseAdvertising } from 'type/ad';
import Flex from 'components/ui/Flex';
import AdBannerUpdateModal from './AdBannerModal';

const Divider = styled.div`
  border-top: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-form {
    padding-top: 24px;
    & > * {
      padding: 0px 24px;
    }
  }
`;

const ModalBody = styled.div`
  padding: 16px 24px 40px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  height: 32px;
  color: #fff;
  background-color: ${theme.colors.PRIMARY};
  border-color: ${theme.colors.PRIMARY};
  margin-top: 10px;
  margin-right: 24px;
  margin-bottom: 14px;
`;

export interface IAdOngoingCompanyListModalProps {
  date: string; // HINT: YYYY-MM-DD
  onClose?: () => void;
}

const AdOngoingCompanyListModal = ({
  date,
  onClose,
}: IAdOngoingCompanyListModalProps) => {
  const [
    advertisingCompanyPurchaseProductId,
    setAdvertisingCompanyPurchaseProductId,
  ] = useState<number | null>(null);
  const { adOngoingProducts } = useAdOngoingProducts({
    date,
  });

  const columns: ColumnsType<IPurchaseAdvertising> = useMemo(
    () => [
      {
        title: 'No.',
        align: 'center',
        render: (_, __, index) => adOngoingProducts.length - index,
      },
      {
        title: '회사(소속)명',
        align: 'center',
        width: '60%',
        render: (_, { companyName, companyPurchaseProductId }) => (
          <Typography.Link
            underline
            onClick={() => {
              setAdvertisingCompanyPurchaseProductId(companyPurchaseProductId);
            }}
          >
            {companyName}
          </Typography.Link>
        ),
      },
      {
        title: '광고 기간',
        align: 'center',
        render: (_, { startDt, endDt }) => `${startDt} ~ ${endDt}`,
      },
    ],
    [adOngoingProducts],
  );

  return (
    <StyledModal
      width={728}
      title={
        <Flex justify="space-between">
          <Typography.Text>광고 중인 회사 목록</Typography.Text>
          <Typography.Text>{date}</Typography.Text>
        </Flex>
      }
      visible
      footer={null}
      closable={false}
      maskClosable={false}
    >
      {advertisingCompanyPurchaseProductId && (
        <AdBannerUpdateModal
          advertisingCompanyPurchaseProductId={
            advertisingCompanyPurchaseProductId
          }
          onClose={() => {
            setAdvertisingCompanyPurchaseProductId(null);
          }}
        />
      )}
      <ModalBody>
        <Table
          size="small"
          columns={columns}
          dataSource={adOngoingProducts}
          pagination={false}
          rowKey="companyPurchaseProductId"
        />
      </ModalBody>
      <Divider>
        <StyledButton onClick={onClose}>확인</StyledButton>
      </Divider>
    </StyledModal>
  );
};

export default AdOngoingCompanyListModal;
