import styled from '@emotion/styled';
import { Button, Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';

import { requiredRule } from 'shared/rule';
import { ISessionCSManager, ISessionCSManagerForm } from 'type/session';
import ProfileImageInput from './ProfileImageInput';

const Container = styled.div``;

const SessionCSManagerForm = ({
  sessionCSManager,
  onSubmit,
}: {
  sessionCSManager: ISessionCSManager | null;
  onSubmit: (sessionCsManager: ISessionCSManagerForm) => void;
}) => {
  const [form] = useForm<ISessionCSManagerForm>();
  useEffect(() => {
    if (sessionCSManager) {
      form.setFieldsValue({
        ...sessionCSManager,
        profileImage: sessionCSManager.profileImageUrl,
      });
    }
  }, [sessionCSManager, form]);
  return (
    <Container>
      <Form form={form} colon={false} onFinish={onSubmit}>
        <Form.Item
          label="담당자 사진"
          name="profileImage"
          rules={[requiredRule]}
          valuePropName="profileImage"
        >
          <ProfileImageInput />
        </Form.Item>
        <Form.Item label="담당자 이름" name="name" rules={[requiredRule]}>
          <Input maxLength={20} />
        </Form.Item>
        <Row justify="center">
          <Col>
            <Button type="primary" htmlType="submit" size="large">
              저장
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default SessionCSManagerForm;
