import { Route, Routes } from 'react-router-dom';

import AdInquiryList from 'components/ad/adInquiry/AdInquiryList';
import AdBanner from 'components/ad/adBanner/AdBanner';
import AdCompanyList from 'components/ad/adCompany/AdCompanyList';

const AdRoutes = () => {
  return (
    <Routes>
      <Route path="/inquiry" element={<AdInquiryList />} />
      <Route path="/ongoing" element={<AdCompanyList />} />
      <Route path="/banner" element={<AdBanner />} />
    </Routes>
  );
};

export default AdRoutes;
