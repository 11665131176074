import { SyncOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Col, Modal, Row, Select, Table, Typography } from 'antd';
import { useState } from 'react';
import { QueryClientProvider, useQueryClient } from 'react-query';

import SearchInput from 'components/ui/SearchInput';
import { EMemberInquirySearchType, EMemberInquiryStatus } from 'type/member';
import { theme } from 'styles/theme';
import MemberInquiry from './MemberInquiry';
import { getModalContainer } from 'util/modal';
import { useMemberInquiries } from 'core/query/member';

const Container = styled.div``;

const MemberInquiryList = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState<EMemberInquirySearchType>(
    EMemberInquirySearchType.TITLE,
  );
  const [searchKeyword, setSearchKeyword] = useState('');
  const search = (value: string) => {
    setSearchKeyword((prev) => {
      if (prev != value) {
        setPage(1);
      }
      return value;
    });
  };
  const {
    memberInquiriesInfo: { content: memberInquires, totalElements },
    refetch,
  } = useMemberInquiries({
    page,
    searchType,
    searchKeyword,
  });
  const handleShowMemberInquiry = (memberInquiryId: number) => {
    const modal = Modal.info({
      title: '문의 상세 보기',
      width: 1000,
      closable: true,
      content: (
        <QueryClientProvider client={queryClient}>
          <MemberInquiry
            memberInquiryId={memberInquiryId}
            onAnswer={() => {
              refetch();
              modal.destroy();
            }}
          />
        </QueryClientProvider>
      ),
      getContainer: getModalContainer,
    });
  };
  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        1:1 문의
      </Typography.Title>
      <Row style={{ marginBottom: 8 }} justify="space-between">
        <Col>
          <Button icon={<SyncOutlined />} onClick={() => refetch()}>
            새로 고침
          </Button>
        </Col>
        <Col>
          <Row gutter={4}>
            <Col>
              <Select
                value={searchType}
                options={[
                  { label: '제목', value: EMemberInquirySearchType.TITLE },
                  {
                    label: '세션 타이틀',
                    value: EMemberInquirySearchType.SESSION,
                  },
                  { label: '작성자', value: EMemberInquirySearchType.NAME },
                ]}
                style={{ width: 110 }}
                onChange={setSearchType}
              />
            </Col>
            <Col>
              <SearchInput defaultValue={searchKeyword} onSearch={search} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        size="small"
        dataSource={memberInquires}
        rowKey="memberInquiryId"
        pagination={{
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: totalElements,
          showSizeChanger: false,
        }}
        columns={[
          {
            title: '문의 번호',
            dataIndex: 'memberInquiryId',
            align: 'center',
          },
          {
            title: '제목',
            align: 'center',
            render: (_, { memberInquiryId, title }) => (
              <Typography.Link
                onClick={() => handleShowMemberInquiry(memberInquiryId)}
              >
                {title}
              </Typography.Link>
            ),
          },
          {
            title: '작성자',
            dataIndex: 'name',
            align: 'center',
          },
          {
            title: '작성 일시',
            align: 'center',
            render: (_, { registerDt }) => registerDt.slice(0, 16),
          },
          {
            title: '상태',
            dataIndex: 'status',
            align: 'center',
            render: (status) => {
              switch (status) {
                case EMemberInquiryStatus.READY:
                  return (
                    <Typography.Text type="danger">답변 대기</Typography.Text>
                  );
                case EMemberInquiryStatus.DONE:
                  return (
                    <Typography.Text type="success">답변 완료</Typography.Text>
                  );
                default:
                  return (
                    <Typography.Text style={{ color: theme.colors.GRAY70 }}>
                      삭제
                    </Typography.Text>
                  );
              }
            },
          },
        ]}
      />
    </Container>
  );
};

export default MemberInquiryList;
