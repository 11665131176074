import { Select } from 'antd';
import Flex from './Flex';

interface IPageSizeSelectProps {
  pageSize: number;
  onChange: (pageSize: number) => void;
  options?: {
    label: string;
    value: number;
  }[];
}

export const pageSizeOptions = [
  { label: '10개씩 보기', value: 10 },
  { label: '30개씩 보기', value: 30 },
  { label: '50개씩 보기', value: 50 },
];

const PageSizeSelect = ({
  pageSize,
  onChange,
  options = pageSizeOptions,
}: IPageSizeSelectProps) => {
  return (
    <Flex justify="end" style={{ marginBottom: 16 }}>
      <Select value={pageSize} options={options} onChange={onChange} />
    </Flex>
  );
};

export default PageSizeSelect;
