import { Route, Routes } from 'react-router-dom';

import NoticeList from 'components/cs/NoticeList';
import FaqList from 'components/cs/FaqList';

const CSRoutes = () => {
  return (
    <Routes>
      <Route path="/notice" element={<NoticeList />} />
      <Route path="/faq" element={<FaqList />} />
    </Routes>
  );
};

export default CSRoutes;
