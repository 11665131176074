import { Input } from 'antd';
import { useEffect, useState } from 'react';

const parseNumber = (type: 'cellPhone' | 'phone', number?: string) => {
  if (!number) return '';

  if (type === 'cellPhone') {
    return number
      .replace(/-/g, '')
      .replace(/^(\d{3})(\d{1,})/, '$1-$2')
      .replace(/^(\d{3})-(\d{4})(\d{1,})/, '$1-$2-$3');
  } else {
    return number.replace(/-/g, '');
  }
};

const PhoneNumberInput = ({
  value,
  placeholder,
  style,
  onChange,
  type = 'cellPhone',
}: {
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (value: string) => void;
  type?: 'cellPhone' | 'phone';
}) => {
  const [number, setNumber] = useState('');
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!onChange) return;
    const newValue = e.target.value;
    onChange(newValue.replace(/-|[^0-9]/g, ''));
  };
  useEffect(() => {
    setNumber(parseNumber(type, value));
  }, [value, type]);
  return (
    <Input
      value={number}
      placeholder={placeholder}
      maxLength={type === 'cellPhone' ? 13 : 11}
      onChange={handleChange}
      style={style}
    />
  );
};

export default PhoneNumberInput;
