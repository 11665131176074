import styled from '@emotion/styled';
import { Button, Descriptions, Modal } from 'antd';

import { useBusinessSupportDetail } from 'core/query/business';

const StyledModal = styled(Modal)`
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    padding: 12px !important;
  }
`;

const BusinessSupportDetailModal = ({
  onClose,
  businessSupportId,
}: {
  onClose: () => void;
  businessSupportId: number;
}) => {
  const { businessSupportDetail } = useBusinessSupportDetail(businessSupportId);

  if (!businessSupportDetail) return null;

  const {
    title,
    competentMinistry,
    applicationPeriodStartDate,
    applicationPeriodEndDate,
    supportArea,
    registerDt,
    executingOrganization,
    businessOverview,
  } = businessSupportDetail;

  return (
    <StyledModal
      width={800}
      title="지원사업 상세"
      maskClosable
      visible
      footer={
        <Button key="submit" type="primary" onClick={onClose}>
          확인
        </Button>
      }
      onCancel={onClose}
    >
      <Descriptions
        column={1}
        bordered
        size="middle"
        labelStyle={{ width: 100 }}
      >
        <Descriptions.Item label="지원사업 명">
          {title || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="소관부처">
          {competentMinistry || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="모집기간">
          {applicationPeriodStartDate && applicationPeriodEndDate
            ? `${applicationPeriodStartDate} - ${applicationPeriodEndDate}`
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="사업분야">
          {supportArea || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="등록일">
          {registerDt || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="수행기관">
          {executingOrganization || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="사업개요">
          {businessOverview || '-'}
        </Descriptions.Item>
      </Descriptions>
    </StyledModal>
  );
};

export default BusinessSupportDetailModal;
