import { useEffect, useState } from 'react';
import { LinkOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Typography,
  Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from '@emotion/styled';
import moment, { Moment } from 'moment';

import { requiredRule } from 'shared/rule';
import { useAdBannerOptions, useAdBannerDetail } from 'core/query/ad';
import { createFormData } from 'util/form';
import { fileToBase64 as toBase64, urlToFile } from 'util/file';
import Flex from 'components/ui/Flex';

const StyledImg = styled.img`
  max-width: 100%;
  height: 80px;
  margin-top: 16px;
`;

const Divider = styled(Flex)`
  align-items: center;
  justify-content: end;
  border-top: 1px solid #f0f0f0;
  padding: 10px 0px;
  height: 52px;
`;

const LinkInputContainer = styled.div`
  position: relative;
`;

export interface IAdBannerForm {
  companyId: number;
  productId: number;
  eventUrl: string;
  adPeriod: [Moment, Moment];
  memo: string;
}

const AdBannerForm = ({
  onClose,
  advertisingCompanyPurchaseProductId,
}: {
  onClose: () => void;
  advertisingCompanyPurchaseProductId?: number;
}) => {
  const [form] = Form.useForm<IAdBannerForm>();
  const [adPCBannerFile, setAdPCBannerFile] = useState<File | null>(null);
  const [previewPCImage, setPreviewPCImage] = useState('');
  const [previewMobileImage, setPreviewMobileImage] = useState('');
  const [adMobileBannerFile, setAdMobileBannerFile] = useState<File | null>(
    null,
  );

  const [linkUrl, setLinkUrl] = useState('');

  const { adCompany, adCompanySelectOptions, adProductSelectOptions } =
    useAdBannerOptions();

  const { adBannerDetail, registerAdBanner, updateAdBanner, isLoading } =
    useAdBannerDetail(advertisingCompanyPurchaseProductId);

  const handleSubmit = ({
    companyId,
    eventUrl,
    adPeriod,
    memo,
  }: {
    companyId: number;
    eventUrl: string;
    adPeriod: [Moment, Moment];
    memo: string;
  }) => {
    if (!adPCBannerFile) {
      message.warn('PC 배너 파일을 업로드 해주세요.');

      return;
    }
    if (!adMobileBannerFile) {
      message.warn('모바일 배너 파일을 업로드 해주세요.');

      return;
    }

    const [startDate, endDate] = adPeriod.map((date) =>
      date.format('YYYY-MM-DD'),
    );

    // HINT: 코스브릿지에서는 하나의 상품만 있어, productId를 0번 인덱스 값으로 고정.
    const productId =
      (adProductSelectOptions || []).length > 0
        ? (adProductSelectOptions || [])[0].value
        : undefined;
    if (!productId) return;

    if (!adBannerDetail) {
      const formData = createFormData({
        bannerFile: adPCBannerFile,
        mobileBannerFile: adMobileBannerFile,
        companyId,
        productId,
        eventUrl,
        startDate,
        endDate,
        memo,
      });

      registerAdBanner(formData, {
        onSuccess: () => {
          onClose();
        },
      });

      return;
    }

    if (
      eventUrl !== adBannerDetail.eventUrl ||
      startDate !== adBannerDetail.startDt ||
      endDate !== adBannerDetail.endDt ||
      memo !== adBannerDetail.memo ||
      'uid' in adPCBannerFile ||
      'uid' in adMobileBannerFile
    ) {
      const formData = createFormData({
        bannerFile: adPCBannerFile,
        mobileBannerFile: adMobileBannerFile,
        companyId,
        productId: adBannerDetail.productId,
        eventUrl,
        startDate,
        endDate,
        memo,
      });
      updateAdBanner(
        {
          params: formData,
          companyPurchaseProductId: adBannerDetail.companyPurchaseProductId,
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
      return;
    }

    message.warn('수정할 내역이 없습니다.');
  };

  useEffect(() => {
    if (adBannerDetail) {
      form.setFieldsValue({
        companyId: adBannerDetail.companyId,
        eventUrl: adBannerDetail.eventUrl,
        adPeriod: [
          moment(adBannerDetail.startDt),
          moment(adBannerDetail.endDt),
        ],
        memo: adBannerDetail.memo || '',
      });

      setLinkUrl(adBannerDetail.eventUrl);

      const convert = async () => {
        const pcFile = await urlToFile(
          adBannerDetail.fileUrl,
          adBannerDetail.filename,
          { useOldS3Path: true },
        );

        const mobileFile = await urlToFile(
          adBannerDetail.mobileFileUrl,
          adBannerDetail.mobileFilename,
          { useOldS3Path: true },
        );

        const filePcImg = await toBase64(pcFile);
        setPreviewPCImage(filePcImg);
        setAdPCBannerFile(pcFile);

        const fileMobileImg = await toBase64(mobileFile);
        setPreviewMobileImage(fileMobileImg);
        setAdMobileBannerFile(mobileFile);
      };

      convert();
    }
  }, [adBannerDetail, form]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        memo: '',
      }}
    >
      <Form.Item
        label="회사(소속)명"
        labelCol={{ span: 5 }}
        name="companyId"
        rules={[requiredRule]}
      >
        {!adBannerDetail ? (
          <Select
            showSearch
            filterOption={(keyword, option) =>
              option?.label.toLowerCase().includes(keyword.toLowerCase()) ||
              false
            }
            placeholder="회사(소속)명 검색"
            options={adCompanySelectOptions}
          />
        ) : (
          <Typography.Text strong>
            {
              adCompany?.find(
                (item) => item.companyId === adBannerDetail.companyId,
              )?.companyName
            }
          </Typography.Text>
        )}
      </Form.Item>
      <Form.Item labelCol={{ span: 5 }} label="PC 배너" required>
        <Upload
          style={{ width: '100%' }}
          accept=".jpg,.jpeg,.png"
          itemRender={() => null}
          beforeUpload={async (file) => {
            if (file.size > 500 * 1024) {
              return message.warn(
                '배너 파일의 최대 사이즈는 500KB 이하입니다.',
              );
            }

            const fileImg = await toBase64(file);
            setPreviewPCImage(fileImg);
            setAdPCBannerFile(file);
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>파일 업로드</Button>
          {adPCBannerFile && (
            <Typography.Text style={{ marginLeft: 16 }}>
              {adPCBannerFile.name}
            </Typography.Text>
          )}
        </Upload>
        {previewPCImage && <StyledImg alt="example" src={previewPCImage} />}
      </Form.Item>
      <Form.Item
        labelCol={{ span: 5 }}
        label="모바일 배너"
        required
        style={{ width: '100%' }}
      >
        <Upload
          accept=".jpg,.jpeg,.png"
          itemRender={() => null}
          beforeUpload={async (file) => {
            if (file.size > 500 * 1024) {
              return message.warn(
                '배너 파일의 최대 사이즈는 500KB 이하입니다.',
              );
            }

            const fileImg = await toBase64(file);
            setPreviewMobileImage(fileImg);
            setAdMobileBannerFile(file);
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>파일 업로드</Button>
          {adMobileBannerFile && (
            <Typography.Text style={{ marginLeft: 16 }}>
              {adMobileBannerFile.name}
            </Typography.Text>
          )}
        </Upload>
        {previewMobileImage && (
          <StyledImg alt="example" src={previewMobileImage} />
        )}
      </Form.Item>
      <Form.Item
        label="링크 url"
        labelCol={{ span: 5 }}
        name="eventUrl"
        rules={[requiredRule]}
      >
        <LinkInputContainer>
          <Input value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} />
          {linkUrl && (
            <a
              href={linkUrl.includes('://') ? linkUrl : `//${linkUrl}`}
              rel="noreferrer"
              target="_blank"
            >
              <LinkOutlined
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: 10,
                  transform: 'translateY(-50%)',
                  opacity: 0.25,
                  cursor: 'pointer',
                }}
              />
            </a>
          )}
        </LinkInputContainer>
      </Form.Item>
      <Form.Item
        labelCol={{ span: 5 }}
        name="adPeriod"
        label="광고 기간"
        rules={[
          requiredRule,
          {
            validator: async (
              _,
              progressTime?: { startDt: string; endDt: string },
            ) => {
              if (!progressTime) return;
            },
          },
        ]}
      >
        <DatePicker.RangePicker style={{ width: 256 }} format="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item label="메모" labelCol={{ span: 5 }} name="memo">
        <TextArea />
      </Form.Item>
      <Divider>
        <Button onClick={onClose}>{!adBannerDetail ? '취소' : '확인'}</Button>
        <Button
          type="primary"
          loading={isLoading}
          style={{ marginLeft: 8 }}
          htmlType="submit"
        >
          {!adBannerDetail ? '등록' : '수정'}
        </Button>
      </Divider>
    </Form>
  );
};

export default AdBannerForm;
