import { Button, Card, Form, Input, message, Typography } from 'antd';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import AttachUpload from 'components/ui/AttachUpload';
import Editor from 'components/ui/Editor';
import { REQUIRED_FIELD } from 'shared/message';
import { requiredRule } from 'shared/rule';
import { EAttachType } from 'type/common';
import { useBoard, useBoardAdmin } from 'core/query/community';
import history from 'util/history';
import { Flex } from 'components/ui';
import { EBoardType } from 'type/board';

const Container = styled.div``;

export interface IBoardAdminForm {
  title: string;
  content: string;
  attaches: { attachId: number }[];
}

export const BoardAdminForm = ({ boardType }: { boardType: EBoardType }) => {
  const { id } = useParams<{ id?: string }>();
  const boardId = id ? Number(id) : undefined;
  const updateMode = boardId !== undefined;
  const [form] = Form.useForm<IBoardAdminForm>();

  const { board } = useBoard(boardId);
  const { addBoardAdmin, addLoading, updateBoardAdmin, updateLoading } =
    useBoardAdmin();

  const handleSubmit = (formValues: IBoardAdminForm) => {
    const { attaches, ...rest } = formValues;
    const newFormValues = {
      ...rest,
      attachIds: attaches.map(({ attachId }) => attachId),
      boardType,
    };

    if (!updateMode) {
      addBoardAdmin(newFormValues, {
        onSuccess: () => {
          message.success(`등록되었습니다.`);
          history.replace(`/community/${boardType}`);
        },
      });

      return;
    }

    updateBoardAdmin(
      { boardId, ...newFormValues },
      {
        onSuccess: () => {
          message.success(`수정되었습니다.`);
          history.replace(`/community/${boardType}/${boardId}`);
        },
      },
    );
  };

  useEffect(() => {
    if (updateMode && board) {
      form.setFieldsValue(board);
    }
  }, [updateMode, form, board]);

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        글쓰기
      </Typography.Title>
      <Card>
        <Container>
          <Form form={form} colon={false} onFinish={handleSubmit}>
            <Form.Item label="제목" name="title" rules={[requiredRule]}>
              <Input maxLength={100} />
            </Form.Item>
            <Form.Item
              label="내용"
              name="content"
              rules={[
                requiredRule,
                {
                  validator: async (_, value: string) => {
                    if (!value.replace('<p><br></p>', '')) {
                      throw new Error(REQUIRED_FIELD);
                    }
                  },
                },
              ]}
            >
              <Editor
                imageUploadType="BOARD_NOTICE"
                initialValue={updateMode ? board?.content : ''}
              />
            </Form.Item>
            <Form.Item
              label="첨부파일 (최대 10MB까지)"
              name="attaches"
              valuePropName="attaches"
              initialValue={[]}
            >
              <AttachUpload attachType={EAttachType.NOTICE} maxCount={10} />
            </Form.Item>
            <Flex justify="end" gap={8}>
              <Button onClick={() => history.back()}>취소</Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={addLoading || updateLoading}
              >
                {updateMode ? '수정' : '글쓰기'}
              </Button>
            </Flex>
          </Form>
        </Container>
      </Card>
    </Container>
  );
};

export default BoardAdminForm;
