import {
  ChangeEvent,
  useState,
  useMemo,
  useLayoutEffect,
  useCallback,
} from 'react';
import styled from '@emotion/styled';
import { Select, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { IAdInquiry } from 'type/ad';
import {
  useAdInquiries,
  useAdInquiry,
  useUpdateAdInquiryMemo,
  useUpdateAdInquiryBusinessManager,
} from 'core/query/ad';
import { useAdminUsers } from 'core/query/user';
import SearchInput from 'components/ui/SearchInput';
import Flex from 'components/ui/Flex';
import AdInquiryListDetail from './AdInquiryListDetail';

const AdInquiryListContainer = styled.div`
  tbody .ant-table-row {
    cursor: pointer;
  }

  .ant-table-expand-icon-col {
    width: 0px;
  }

  .ant-table-row-expand-icon-cell {
    max-width: 0px;
    padding: 0px;
    overflow: hidden;
  }
`;

const UNSELECTED = -1;

const changePhoneFormat = (phone?: string) => {
  if (!phone) return '-';

  return phone.includes('-')
    ? phone
    : phone.replace(/^(\d{3})(\d{4})(\d*)$/, '$1-$2-$3');
};

const AdInquiryList = () => {
  const [page, setPage] = useState(1);
  const [selectedRowAdId, setSelectedRowAdId] = useState<number | undefined>();
  const [searchValue, setSearchValue] = useState('');
  const [memo, setMemo] = useState('');
  const { adInquiry: selectedAdInquiry } = useAdInquiry(selectedRowAdId);
  const { adInquiries, updateBusinessManagerCache, total } = useAdInquiries({
    page,
    companyName: searchValue,
  });
  const adminUsers = useAdminUsers();
  const { updateAdInquiryMemo, isLoading } = useUpdateAdInquiryMemo();
  const { updateAdInquiryBusinessManager } =
    useUpdateAdInquiryBusinessManager();

  const salesManagerOptions = useMemo(
    () => [
      {
        value: UNSELECTED,
        label: '미지정',
      },
      ...adminUsers.map((user) => ({
        value: user.adminMemberId,
        label: user.name,
      })),
    ],
    [adminUsers],
  );

  const handleMemoValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMemo(e.target.value);
  };

  const handleSearch = (keyword: string) => {
    setSelectedRowAdId(undefined);
    setSearchValue(keyword);
    setPage(1);
  };

  const handleMemoSave = () => {
    if (!selectedRowAdId) return;

    updateAdInquiryMemo({
      adInquiryId: selectedRowAdId,
      memo,
    });
  };

  const handleBusinessManagerChange = useCallback(
    (adInquiryId: number, index: number) => (adminId: number) => {
      let businessAdminId: number | null = null;
      let businessManagerName: string | null = null;

      // HINT: 미지정이 아닐 때
      if (adminId !== UNSELECTED) {
        const selectedAdminUser = adminUsers.find(
          (u) => u.adminMemberId === adminId,
        );
        if (!selectedAdminUser) return;

        businessAdminId = selectedAdminUser.adminMemberId;
        businessManagerName = selectedAdminUser.name;
      }

      updateAdInquiryBusinessManager(
        {
          adInquiryId,
          adminId: businessAdminId,
        },
        {
          onSuccess: () => {
            // HINT: 캐시를 업데이트하여 조회 API 발생없이 사용자에게 업데이트된 내용을 보여줍니다.
            updateBusinessManagerCache(
              index,
              businessAdminId,
              businessManagerName,
            );
          },
        },
      );
    },
    [adminUsers, updateAdInquiryBusinessManager, updateBusinessManagerCache],
  );

  // HINT: 선택된 행의 memo 값으로 변경, memo값 변경전에 paint가 되면
  // 기존의 memo값에 따라 저장 버튼이 활성화->비활성화로 변하는 애니메이션이
  // 있을 수 있어 useLayoutEffect를 사용
  useLayoutEffect(() => {
    setMemo(selectedAdInquiry?.adminMemo || '');
  }, [selectedAdInquiry]);

  const columns: ColumnsType<IAdInquiry> = [
    {
      title: 'No.',
      align: 'center',
      render: (_, __, index) => adInquiries.length - index,
    },
    {
      title: '회사(소속)명',
      dataIndex: 'companyName',
      align: 'center',
      width: '35%',
    },
    {
      title: '담당자',
      dataIndex: 'managerName',
      align: 'center',
    },
    {
      title: '이메일',
      dataIndex: 'managerEmail',
      align: 'center',
      width: '30%',
    },
    {
      title: '영업담당자 배정',
      align: 'center',
      render: (
        _,
        { businessAdminId, advertisingAffiliationInquiryId },
        index,
      ) => (
        <Select
          style={{ width: 120, textAlign: 'left' }}
          value={businessAdminId ?? UNSELECTED}
          options={salesManagerOptions}
          onClick={(e) => e.stopPropagation()}
          onChange={handleBusinessManagerChange(
            advertisingAffiliationInquiryId,
            index,
          )}
        />
      ),
    },
    {
      title: '휴대폰 번호',
      dataIndex: 'managerPhone',
      align: 'center',
      render: (_, { managerPhone }) => changePhoneFormat(managerPhone),
    },
    {
      align: 'center',
      render: (_, { advertisingAffiliationInquiryId }) =>
        advertisingAffiliationInquiryId === selectedRowAdId ? (
          <UpOutlined />
        ) : (
          <DownOutlined />
        ),
    },
  ];

  return (
    <AdInquiryListContainer>
      <Typography.Title style={{ fontSize: 18 }}>
        광고 및 제휴 문의
      </Typography.Title>
      <Flex justify="flex-end">
        <div style={{ width: 'fit-content' }}>
          <SearchInput
            defaultValue={searchValue}
            onSearch={handleSearch}
            placeholder="회사(소속)명 검색"
            maxLength={50}
          />
        </div>
      </Flex>
      <Table
        dataSource={adInquiries}
        columns={columns}
        pagination={{
          current: page,
          total,
          onChange: setPage,
        }}
        rowKey="advertisingAffiliationInquiryId"
        style={{ marginTop: 16 }}
        onRow={({ advertisingAffiliationInquiryId }) => ({
          onClick: () => {
            // HINT: 동일한 행 클릭시 선택된 행 id를 undefined로 변경하여 접게함
            setSelectedRowAdId((draft) =>
              draft === advertisingAffiliationInquiryId
                ? undefined
                : advertisingAffiliationInquiryId,
            );
          },
        })}
        expandable={{
          expandedRowRender: () => (
            <AdInquiryListDetail
              description={selectedAdInquiry?.inquiryContent}
              onSubmit={handleMemoSave}
              initialMemo={selectedAdInquiry?.adminMemo ?? ''}
              memo={memo}
              onMemoChange={handleMemoValue}
              isLoading={isLoading}
            />
          ),
          // HINT: 선택된 하나의 행만을 확장
          expandedRowKeys:
            selectedRowAdId === undefined ? undefined : [selectedRowAdId],
        }}
      />
    </AdInquiryListContainer>
  );
};

export default AdInquiryList;
