import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Row, Col, Typography } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useMemo, useState } from 'react';

import { downloadFileFromUrl, urlToFile } from 'util/file';

const Container = styled.div`
  padding: 2px 4px;
`;

const mega = 10 ** 6;
const kilo = 10 ** 3;

const BoardUploadItem = ({ attach: { name, url } }: { attach: UploadFile }) => {
  const [size, setSize] = useState(0);
  const formattedSize = useMemo(() => {
    if (size > mega) {
      return (size / mega).toFixed(2) + 'MB';
    }
    if (size > kilo) {
      return (size / kilo).toFixed(2) + 'KB';
    }
    return size.toFixed(2) + 'B';
  }, [size]);
  useEffect(() => {
    if (!url) return;
    const convert = async () => {
      const file = await urlToFile(url, name);
      setSize(file.size);
    };
    convert();
  }, [name, url]);
  return (
    <Container>
      <Row gutter={32}>
        <Col>
          <Row gutter={8}>
            <Col>
              <PaperClipOutlined />
            </Col>
            <Col>
              <Typography.Text>{name}</Typography.Text>
            </Col>
            <Col>{formattedSize}</Col>
          </Row>
        </Col>
        <Col>
          {url && (
            <DownloadOutlined
              style={{ cursor: 'pointer' }}
              onClick={() => downloadFileFromUrl(url, name)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default BoardUploadItem;
