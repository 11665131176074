import { Select } from 'antd';

import { useBusinessTypes } from 'core/query/company';

const BusinessTypeSelection = ({
  value,
  onChange,
  style,
}: {
  value?: number[];
  onChange?: (value: number[]) => void;
  style?: React.CSSProperties;
}) => {
  const businessTypes = useBusinessTypes();
  return (
    <Select
      mode="multiple"
      value={value}
      options={businessTypes.map(({ businessTypeId, businessTypeName }) => ({
        label: businessTypeName,
        value: businessTypeId,
      }))}
      onChange={onChange}
      style={style}
    />
  );
};

export default BusinessTypeSelection;
