import { Route, Routes } from 'react-router-dom';

import SessionOpen from 'components/session/SessionOpen';
import OngoingSessionList from 'components/session/OngoingSessionList';
import DoneSessionList from 'components/session/DoneSessionList';
import SessionHostCompanyList from 'components/session/SessionHostCompanyList';

const SessionRoutes = () => {
  return (
    <Routes>
      <Route path="/open" element={<SessionOpen />} />
      <Route path="/ongoing" element={<OngoingSessionList />} />
      <Route path="/done" element={<DoneSessionList />} />
      <Route path="/hostCompany" element={<SessionHostCompanyList />} />
    </Routes>
  );
};

export default SessionRoutes;
