import axios from 'axios';
import { message } from 'antd';
import qs from 'qs';

import path from 'shared/path';
import history from './history';

const baseURL = `${process.env.REACT_APP_API_HOST}/api`;
const fileSizeArray = [
  {
    size: '10485760',
    message: '파일 첨부 용량이 초과되었습니다 (최대 10MB)',
  },
];

const api = axios.create({
  baseURL,
});

export default api;

api.interceptors.response.use(
  (response) => {
    const { error } = response.data;

    if (error === null || error === undefined || error.code === '0') {
      return response;
    }

    throw error;
  },
  async (error) => {
    console.error('[RESPONSE ERROR]', error);
    const originalRequest = error.config;

    // HINT : 파일 용량 초과 에러 (413)
    if (error.response.status === 413) {
      const fileErrorMessage =
        fileSizeArray.find(({ size }) =>
          error.response.data.error.message.includes(size),
        )?.message || '파일 첨부 용량이 초과되었습니다.';

      message.warning(fileErrorMessage);
      return Promise.reject(error);
    }
    if (
      error.response &&
      !originalRequest._retry &&
      error.response.status === 401
    ) {
      originalRequest._retry = true;
      const storedRefreshToken = sessionStorage.getItem('app.refreshToken');
      if (!storedRefreshToken) {
        clearToken();
        history.replace(`${path.login}?reason=expire`);
        return Promise.reject(error);
      }
      clearToken();
      try {
        const {
          data: { result },
        } = await api.post(
          '/v1/reissue/access-token',
          qs.stringify({
            refreshToken: storedRefreshToken,
          }),
        );
        const { token, refreshToken } = result;
        setToken(token, refreshToken);
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return api(originalRequest);
      } catch (error) {
        history.replace(`${path.login}?reason=expire`);
        Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export const setToken = (accessToken: string, refreshToken: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  sessionStorage.setItem('app.refreshToken', refreshToken);
};

export const clearToken = () => {
  delete api.defaults.headers.common['Authorization'];
  sessionStorage.removeItem('app.refreshToken');
};
