import { Descriptions, Typography } from 'antd';
import { useMember } from 'core/query/member';
import { theme } from 'styles/theme';
import { EReportKind } from 'type/common';
import { EMemberCareerDuration } from 'type/member';
import useShowReports from 'util/hooks/useShowReports';

const careerDurationMap = {
  [EMemberCareerDuration.NEWBIE]: '경력 없음',
  [EMemberCareerDuration.UNDER_ONE]: '1년 미만',
  [EMemberCareerDuration.ONE_FIVE]: '1년~5년',
  [EMemberCareerDuration.FIVE_TEN]: '5년~10년',
  [EMemberCareerDuration.TEN_TWENTY]: '10년~20년',
  [EMemberCareerDuration.MORE_TWENTY]: '20년 이상',
};

const MemberDetail = ({ memberId }: { memberId: number }) => {
  const member = useMember(memberId);
  const showReportList = useShowReports();
  if (member === null) return null;
  const {
    username,
    name,
    businessTypeName,
    careerDuration,
    cellPhone,
    companyName,
    companyDepartmentName,
    companyPositionName,
    email,
    memberInterests,
    reportCount = 1,
    nickname,
    signUpPath,
  } = member;

  const handleShowReportList = () => {
    showReportList({
      id: memberId,
      reportKind: EReportKind.MEMBER,
      title: username,
    });
  };
  return (
    <Descriptions
      bordered
      column={2}
      size="small"
      labelStyle={{ textAlign: 'center' }}
    >
      <Descriptions.Item label="아이디" span={2}>
        {username}
      </Descriptions.Item>
      <Descriptions.Item label="이름">{name}</Descriptions.Item>
      <Descriptions.Item label="닉네임">{nickname || '-'}</Descriptions.Item>
      <Descriptions.Item label="휴대폰 번호">{cellPhone}</Descriptions.Item>
      <Descriptions.Item label="이메일">{email}</Descriptions.Item>
      <Descriptions.Item label="직업군">{businessTypeName}</Descriptions.Item>
      <Descriptions.Item label="경력">
        {careerDurationMap[careerDuration]}
      </Descriptions.Item>
      <Descriptions.Item label="회사명">{companyName || '-'}</Descriptions.Item>
      <Descriptions.Item label="부서명">
        {companyDepartmentName || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="직급" span={2}>
        {companyPositionName || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="관심 키워드" span={2}>
        {memberInterests.join(' / ') || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="가입 경로" span={2}>
        {signUpPath || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="신고수" span={2}>
        <Typography.Text
          style={{ color: theme.colors.PRIMARY, cursor: 'pointer' }}
          onClick={() => handleShowReportList()}
        >
          {reportCount}
        </Typography.Text>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default MemberDetail;
