export interface IAPIError {
  code: string;
  message: string;
}

export interface IAPIResponse<TData> {
  errors: IAPIError;
  result: TData;
}

export interface IAPIPageableResponse<TData> {
  errors: IAPIError;
  result: {
    content: TData;
    totalElements: number;
  };
}

export interface IInterest {
  interestId: number;
  name: string;
}

export enum EReportType {
  ADVERTISEMENT = 'ADVERTISEMENT',
  PRIVATE = 'PRIVATE',
  EXPLETIVE = 'EXPLETIVE',
  DISPUTE = 'DISPUTE',
  ETC = 'ETC',
}
export interface IReport {
  reportId: number;
  reportUsername: string;
  reportNickname: string;
  reportMemberId: number;
  reportType: EReportType;
  reportReason: string | null;
  registerDt: string;
  status: EReportStatus;
  withdrawDt: string | null;
}

export enum EReportStatus {
  NORMAL = 'NORMAL',
  WITHDRAW = 'WITHDRAW',
}

export enum EAttachType {
  BOARD = 'BOARD',
  BOARD_NOTICE = 'BOARD_NOTICE',
  NOTICE = 'NOTICE',
  FAQ = 'FAQ',
}

export interface IAttach {
  attachId: number;
  filename: string;
  uploadFileUrl: string;
}

export enum EReportKind {
  BOARD = 'board',
  BOARD_COMMENT = 'board-comment',
  MEMBER = 'member',
}
