import { useEffect, useState } from 'react';
import { Button, Card, Form, Input, message, Select, Typography } from 'antd';
import styled from '@emotion/styled';

import AttachUpload from 'components/ui/AttachUpload';
import Editor from 'components/ui/Editor';
import { REQUIRED_FIELD } from 'shared/message';
import { requiredRule } from 'shared/rule';
import { EAttachType } from 'type/common';
import { EBoardType, IBoardNotice } from 'type/board';
import { useBoardNotice } from 'core/query/community';
import history from 'util/history';
import { Flex } from 'components/ui';

const Container = styled.div``;

interface IBoardFormHistoryState {
  boardType: EBoardType;
  mode: 'update' | 'add';
}

export const BoardNoticeForm = () => {
  const historyState = history.location.state as IBoardFormHistoryState | null;
  const [boardType, setBoardType] = useState<EBoardType>(
    historyState?.boardType as EBoardType,
  );
  const [form] = Form.useForm<IBoardNotice>();
  const {
    boardNotice,
    addBoardNotice,
    addBoardLoading,
    updateBoardNotice,
    updateBoardLoading,
  } = useBoardNotice({
    boardType,
  });

  const handleSubmitNotice = (boardNotice: IBoardNotice) => {
    if (historyState?.mode === 'add') {
      addBoardNotice(boardNotice, {
        onSuccess: () => {
          message.success(`공지사항이 등록되었습니다.`);
          history.replace(`/community/${boardType}`);
        },
      });
    } else {
      updateBoardNotice(boardNotice, {
        onSuccess: () => {
          message.success(`공지사항이 수정되었습니다.`);
          history.replace(`/community/${boardType}`);
        },
      });
    }
  };

  useEffect(() => {
    if (boardNotice && historyState?.mode === 'update') {
      const { boardType: _, ...restBoardNotice } = boardNotice;
      form.setFieldsValue(restBoardNotice);
    }
  }, [form, boardNotice, historyState]);

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        공지사항 {historyState?.mode === 'add' ? '작성' : '수정'}
      </Typography.Title>
      <Card>
        <Container>
          <Form
            form={form}
            colon={false}
            onFinish={handleSubmitNotice}
            initialValues={{
              ['boardType']: boardType,
            }}
          >
            <Form.Item name="boardType">
              <Select
                disabled={historyState?.mode === 'update' ? true : false}
                onChange={setBoardType}
                options={[
                  { label: '브릿지 이야기', value: EBoardType.ORIGINAL },
                  { label: '자료 모아보기', value: EBoardType.PROMOTION },
                  { label: '수다공간', value: EBoardType.FREE },
                  { label: '궁금해요', value: EBoardType.QNA },
                  { label: '익명 게시판', value: EBoardType.ANONYMOUS },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item label="제목" name="title" rules={[requiredRule]}>
              <Input maxLength={100} />
            </Form.Item>
            <Form.Item
              label="내용"
              name="content"
              rules={[
                requiredRule,
                {
                  validator: async (_, value: string) => {
                    if (!value.replace('<p><br></p>', '')) {
                      throw new Error(REQUIRED_FIELD);
                    }
                  },
                },
              ]}
            >
              <Editor
                initialValue={
                  historyState?.mode === 'update' ? boardNotice?.content : ''
                }
                imageUploadType="BOARD_NOTICE"
              />
            </Form.Item>
            <Form.Item
              label="첨부파일 (최대 10MB까지)"
              name="attaches"
              valuePropName="attaches"
              initialValue={[]}
            >
              <AttachUpload attachType={EAttachType.NOTICE} maxCount={10} />
            </Form.Item>
            <Flex justify="end" gap={8}>
              <Button onClick={() => history.back()}>취소</Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={addBoardLoading || updateBoardLoading}
              >
                {historyState?.mode === 'add' ? '공지 작성' : '수정하기'}
              </Button>
            </Flex>
          </Form>
        </Container>
      </Card>
    </Container>
  );
};

export default BoardNoticeForm;
