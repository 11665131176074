import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Form, Input, message, Modal, Radio, Select, Tabs } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/es/form/Form';

import { Flex, Typography } from 'components/ui';
import SearchInput from 'components/ui/SearchInput';
import { IBusinessSupportListItem } from 'type/business';
import { useBusinessSupport } from 'core/query/business';
import BusinessSupportDetailModal from './BusinessSupportDetailModal';
import { generateFormRules } from 'shared/rule';

const Container = styled.div`
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }
`;

export enum EBusinessSupportTab {
  OPENED = 'openedBusinessSupportList',
  CLOSED = 'closeddBusinessSupportList',
}

export enum EBusinessSupportWithinDay {
  ALL = 'ALL',
  TODAY = '0',
  RECENT_THREE = '2',
  RECENT_SEVEN = '6',
}

export enum EBusinessSupportSearchType {
  TITLE = 'title',
  COMPETENT_MINISTRY = 'competentMinistry',
  AI_SCORE = 'aiScore',
}

const filterOptions: { label: string; value: EBusinessSupportWithinDay }[] = [
  { label: '전체', value: EBusinessSupportWithinDay.ALL },
  { label: '오늘', value: EBusinessSupportWithinDay.TODAY },
  { label: '최근 3일', value: EBusinessSupportWithinDay.RECENT_THREE },
  { label: '최근 7일', value: EBusinessSupportWithinDay.RECENT_SEVEN },
];

const searchTypeOptions: {
  label: string;
  value: EBusinessSupportSearchType;
}[] = [
  { label: '지원사업명', value: EBusinessSupportSearchType.TITLE },
  { label: '소관부처', value: EBusinessSupportSearchType.COMPETENT_MINISTRY },
  { label: '스코어', value: EBusinessSupportSearchType.AI_SCORE },
];

const BusinessSupport = () => {
  const searchInputRef = useRef<Input>(null);
  const [form] = useForm<{ searchValue: string }>();
  const [businessSupportId, setBusinessSupportId] = useState<number>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [searchType, setSearchType] = useState<EBusinessSupportSearchType>(
    EBusinessSupportSearchType.TITLE,
  );
  const [searchedType, setSearchedType] = useState<EBusinessSupportSearchType>(
    EBusinessSupportSearchType.TITLE,
  );
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState<EBusinessSupportTab>(
    EBusinessSupportTab.OPENED,
  );
  const [withinDay, setWithinDay] = useState<EBusinessSupportWithinDay>(
    EBusinessSupportWithinDay.ALL,
  );

  const {
    businessSupportListItems,
    total,
    isLoading,
    changeBusinessSupportDisplay,
  } = useBusinessSupport({
    page,
    size: 10,
    isDisplay: tab === EBusinessSupportTab.OPENED,
    ...(searchValue && { [searchedType]: searchValue }),
    ...(withinDay !== EBusinessSupportWithinDay.ALL && { withinDay }),
  });

  const handleChangeSearchType = (searchType: EBusinessSupportSearchType) => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
      searchInputRef.current.setValue('');
    }

    setSearchType(searchType);
  };

  const handleSearch = ({ searchValue }: { searchValue: string }) => {
    setPage(1);
    setSearchedType(searchType);
    setSearchValue(searchValue);
  };

  const handleChangeDisplay = () => {
    if (selectedIds.length === 0) {
      return message.warn('공고를 선택해주세요.');
    }

    const modalContent =
      tab === EBusinessSupportTab.OPENED
        ? '정말로 내리시겠습니까?'
        : '정말로 올리시겠습니까?';

    Modal.confirm({
      content: modalContent,
      onOk: () =>
        changeBusinessSupportDisplay(
          {
            businessSupportIds: selectedIds,
            isDisplay: tab !== EBusinessSupportTab.OPENED,
          },
          {
            onSuccess: () => {
              message.success('수정되었습니다.');
              setSelectedIds([]);
            },
          },
        ),
    });
  };

  const columns: ColumnsType<IBusinessSupportListItem> = [
    {
      title: '지원사업명',
      width: 800,
      render: (_, { businessSupportId, title }) => (
        <Typography.Text
          type="s2"
          regular
          onClick={() => setBusinessSupportId(businessSupportId)}
        >
          {title}
        </Typography.Text>
      ),
    },
    {
      title: '소관부처',
      align: 'center',
      width: 189,
      render: (_, { competentMinistry }) => competentMinistry,
    },
    {
      title: '모집기간',
      align: 'center',
      width: 258,
      render: (
        _,
        {
          applicationPeriodStartDate,
          applicationPeriodEndDate,
          applicationPeriodKind,
        },
      ) =>
        applicationPeriodStartDate && applicationPeriodEndDate
          ? `${applicationPeriodStartDate} ~ ${applicationPeriodEndDate}`
          : applicationPeriodKind,
    },
    {
      title: '등록일',
      align: 'center',
      width: 144,
      render: (_, { registerDt }) => registerDt,
    },
    {
      title: '조회수',
      align: 'center',
      width: 105,
      render: (_, { viewCount }) => viewCount || 0,
    },
    {
      title: '스코어',
      align: 'center',
      width: 100,
      render: (_, { aiScore }) => aiScore || '-',
    },
  ];

  return (
    <Container>
      {businessSupportId && (
        <BusinessSupportDetailModal
          businessSupportId={businessSupportId}
          onClose={() => {
            setBusinessSupportId(undefined);
          }}
        />
      )}
      <Flex justify="space-between">
        <Typography.Text type="h3" bold style={{ alignSelf: 'flex-end' }}>
          지원사업
        </Typography.Text>
        <Tabs
          moreIcon={null}
          activeKey={tab}
          onChange={(key) => {
            setTab(key as typeof tab);
            setPage(1);
          }}
        >
          <Tabs.TabPane tab="업로드 공고" key="openedBusinessSupportList" />
          <Tabs.TabPane
            tab="내린 / 스코어 미만 공고"
            key="closedBusinessSupportList"
          />
        </Tabs>
      </Flex>
      <Flex justify="space-between" gap={8} style={{ marginTop: 24 }}>
        <Flex gap={8}>
          <Button onClick={handleChangeDisplay}>
            {tab === EBusinessSupportTab.OPENED ? '공고 내리기' : '공고 올리기'}
          </Button>
        </Flex>
        <Flex gap={8}>
          <Radio.Group
            options={filterOptions}
            value={withinDay}
            onChange={(e) => {
              setWithinDay(e.target.value);
              setPage(1);
            }}
            optionType="button"
          />
          <Select
            value={searchType}
            options={searchTypeOptions}
            onChange={handleChangeSearchType}
            style={{ width: 120 }}
          />
          <Form
            form={form}
            onFinish={handleSearch}
            initialValues={{
              searchValue,
            }}
          >
            <Form.Item
              name="searchValue"
              rules={generateFormRules({
                ...(searchType === EBusinessSupportSearchType.TITLE && {
                  maxLength: 100,
                }),
                ...(searchType ===
                  EBusinessSupportSearchType.COMPETENT_MINISTRY && {
                  onlyKorean: true,
                  maxLength: 10,
                }),
                ...(searchType === EBusinessSupportSearchType.AI_SCORE && {
                  onlyNumber: true,
                  maxLength: 3,
                }),
              })}
            >
              <SearchInput
                inputRef={searchInputRef}
                onSearch={form.submit}
                placeholder="검색"
                style={{ width: 216 }}
              />
            </Form.Item>
          </Form>
        </Flex>
      </Flex>
      <Table
        dataSource={businessSupportListItems}
        pagination={{
          showSizeChanger: false,
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: total,
        }}
        rowSelection={{
          columnWidth: 60,
          selectedRowKeys: selectedIds,
          onChange: (keys) => {
            setSelectedIds(keys.map((key) => Number(key)));
          },
        }}
        loading={isLoading}
        columns={columns}
        rowKey="businessSupportId"
        style={{ marginTop: 4 }}
      />
    </Container>
  );
};

export default BusinessSupport;
