import styled from '@emotion/styled';
import { Document, Page, pdfjs } from 'react-pdf';

import { useSessionApplicantDocument } from 'core/query/session';
import { downloadFile } from 'util/file';
import { Button, Col, Row } from 'antd';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div``;

const SessionApplicantDocument = ({ sessionId }: { sessionId: number }) => {
  const sessionApplicantDocument = useSessionApplicantDocument(sessionId);
  if (sessionApplicantDocument === null) return null;
  return (
    <Container>
      <Document file={sessionApplicantDocument} onLoadError={console.error}>
        <Page pageNumber={1} scale={1.5} />
      </Document>
      <Row gutter={16} justify="end">
        <Col>
          <Button
            type="primary"
            onClick={() => downloadFile(sessionApplicantDocument)}
          >
            다운로드
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SessionApplicantDocument;
