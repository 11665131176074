import { message } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NO_NEED_TO_UPDATE } from 'shared/message';

import { IAPIPageableResponse, IAPIResponse } from 'type/common';
import { IFaq, INotice } from 'type/cs';
import api from 'util/api';
import { getUpdatingObject } from 'util/form';

export const useNotices = (page: number) => {
  const {
    data: { content: notices, totalElements: total } = {
      content: [],
      totalElements: 0,
    },
  } = useQuery(
    ['notices', page],
    () =>
      api.get<IAPIPageableResponse<INotice[]>>(
        `/v1/notices?page=${page}&size=10`,
      ),
    { select: (res) => res.data.result },
  );
  return useMemo(() => ({ notices, total }), [notices, total]);
};

export const useNotice = (noticeId?: number) => {
  const queryClient = useQueryClient();
  const { data: notice = null } = useQuery(
    ['cs', 'notice', noticeId],
    () => api.get<IAPIResponse<INotice>>(`/v1/notices/${noticeId}`),
    {
      select: (res) => res.data.result,
      enabled: typeof noticeId !== 'undefined',
    },
  );

  const { mutate: addNotice, isLoading: addLoading } = useMutation(
    (notice: INotice) => {
      const { attaches, ...restNotice } = notice;
      return api.post('/v1/notice', {
        ...restNotice,
        attachIds: attaches.map(({ attachId }) => attachId),
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['notices']);
      },
    },
  );

  const { mutate: updateNotice, isLoading: updateLoading } = useMutation(
    (newNotice: INotice) => {
      const { attaches, ...restNotice } = newNotice;
      if (notice === null) {
        throw new Error('Invalid notice');
      }
      const attachIds = attaches.map(({ attachId }) => attachId);
      const isAttachesUpdated =
        notice.attaches.length !== attaches.length ||
        notice.attaches.some(({ attachId }) => !attachIds.includes(attachId));
      const updatingNotice = {
        ...getUpdatingObject(restNotice, notice),
        ...(isAttachesUpdated && { attachIds }),
      };
      if (Object.keys(updatingNotice).length === 0) {
        throw new Error(NO_NEED_TO_UPDATE);
      }
      return api.patch(`/v1/notices/${noticeId}`, updatingNotice);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['notices']);
      },
      onError: (error: { message: string }) => {
        if (typeof error.message === 'string') {
          message.warn(error.message);
        } else {
          console.error(error);
        }
      },
    },
  );

  return useMemo(
    () => ({ notice, addNotice, addLoading, updateNotice, updateLoading }),
    [notice, addNotice, addLoading, updateNotice, updateLoading],
  );
};

export const useDeleteNotices = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteNotices, isLoading } = useMutation(
    (noticeIds: number[]) => api.delete('/v1/notices', { data: noticeIds }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('notices');
      },
    },
  );

  return useMemo(
    () => ({ deleteNotices, isLoading }),
    [deleteNotices, isLoading],
  );
};

export const useFaqs = (page: number) => {
  const {
    data: { content: faqs, totalElements: total } = {
      content: [],
      totalElements: 0,
    },
  } = useQuery(
    ['faqs', page],
    () =>
      api.get<IAPIPageableResponse<IFaq[]>>(`/v1/faqs?page=${page}&size=10`),
    { select: (res) => res.data.result },
  );
  return useMemo(() => ({ faqs, total }), [faqs, total]);
};

export const useFaq = (faqId?: number) => {
  const queryClient = useQueryClient();
  const { data: faq = null } = useQuery(
    ['cs', 'faq', faqId],
    () => api.get<IAPIResponse<IFaq>>(`/v1/faqs/${faqId}`),
    {
      select: (res) => res.data.result,
      enabled: typeof faqId !== 'undefined',
    },
  );

  const { mutate: addFaq, isLoading: addLoading } = useMutation(
    (faq: IFaq) => api.post('/v1/faq', faq),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['faqs']);
      },
    },
  );

  const { mutate: updateFaq, isLoading: updateLoading } = useMutation(
    (newFaq: IFaq) => {
      if (faq === null) {
        throw new Error('Invalid faq');
      }

      const updatingFaq = getUpdatingObject(newFaq, faq) || {};
      if (Object.keys(updatingFaq).length === 0) {
        throw new Error(NO_NEED_TO_UPDATE);
      }
      return api.patch(`/v1/faqs/${faqId}`, updatingFaq);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['faqs']);
      },
      onError: (error: { message: string }) => {
        if (typeof error.message === 'string') {
          message.warn(error.message);
        } else {
          console.error(error);
        }
      },
    },
  );

  return useMemo(
    () => ({ faq, addFaq, addLoading, updateFaq, updateLoading }),
    [faq, addFaq, addLoading, updateFaq, updateLoading],
  );
};

export const useDeleteFaqs = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteFaqs, isLoading } = useMutation(
    (faqIds: number[]) => api.delete('/v1/faqs', { data: faqIds }),
    {
      onSuccess: () => {
        queryClient.refetchQueries('faqs');
      },
    },
  );

  return useMemo(() => ({ deleteFaqs, isLoading }), [deleteFaqs, isLoading]);
};
