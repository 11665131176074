import { FileExcelOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  Button,
  Col,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { useState } from 'react';
import { QueryClientProvider, useQueryClient } from 'react-query';

import SearchInput from 'components/ui/SearchInput';
import { EMemberSearchType } from 'type/member';
import MemberDetail from './MemberDetail';
import { useBusinessTypes } from 'core/query/company';
import { useMemberListExcel, useMembers } from 'core/query/member';

const Container = styled.div``;

const MemberList = () => {
  const queryClient = useQueryClient();
  const businessTypes = useBusinessTypes();
  const [page, setPage] = useState(1);
  const [businessTypeId, setBusinessTypeId] = useState<number | undefined>();
  const handleChangeBusinessType = (e: RadioChangeEvent) => {
    setBusinessTypeId(e.target.value);
  };
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchType, setSearchType] = useState<EMemberSearchType>(
    EMemberSearchType.USERNAME,
  );
  const {
    membersInfo: { content: members, totalElements },
  } = useMembers({
    page,
    businessTypeId,
    searchType,
    searchKeyword,
  });
  const { downlaodMemberList, isLoading: isExcelLoading } =
    useMemberListExcel();
  const showMemberDetail = (memberId: number) => {
    Modal.info({
      title: '회원 정보',
      closable: true,
      width: 620,
      content: (
        <QueryClientProvider client={queryClient}>
          <MemberDetail memberId={memberId} />
        </QueryClientProvider>
      ),
    });
  };

  const handleDownloadExcel = () => {
    downlaodMemberList();
  };

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        회원 정보
      </Typography.Title>
      <Row style={{ marginBottom: 8 }} justify="space-between">
        <Col>
          <Button
            icon={<FileExcelOutlined />}
            onClick={handleDownloadExcel}
            loading={isExcelLoading}
          >
            엑셀 다운로드
          </Button>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <Radio.Group
                optionType="button"
                value={businessTypeId}
                onChange={handleChangeBusinessType}
              >
                <Radio.Button>전체</Radio.Button>
                {businessTypes.map(({ businessTypeId, businessTypeName }) => (
                  <Radio.Button key={businessTypeId} value={businessTypeId}>
                    {businessTypeName}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Col>
            <Col>
              <Row gutter={4}>
                <Col>
                  <Select
                    value={searchType}
                    options={[
                      { label: '아이디', value: EMemberSearchType.USERNAME },
                      { label: '이름', value: EMemberSearchType.NAME },
                      { label: '이메일', value: EMemberSearchType.EMAIL },
                      {
                        label: '휴대폰 번호',
                        value: EMemberSearchType.CELL_PHONE,
                      },
                      { label: '회사명', value: EMemberSearchType.COMPANY },
                      { label: '부서명', value: EMemberSearchType.DEPARTMENT },
                      {
                        label: '가입경로',
                        value: EMemberSearchType.SIGN_UP_PATH,
                      },
                    ]}
                    style={{ width: 100 }}
                    onChange={setSearchType}
                  />
                </Col>
                <Col>
                  <SearchInput
                    defaultValue={searchKeyword}
                    onSearch={setSearchKeyword}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        size="small"
        dataSource={members}
        pagination={{
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: totalElements,
        }}
        columns={[
          { title: '번호', align: 'center', dataIndex: 'memberId' },
          {
            title: '아이디',
            align: 'center',
            render: (_, { memberId, username }) => (
              <Typography.Link onClick={() => showMemberDetail(memberId)}>
                {username}
              </Typography.Link>
            ),
          },
          { title: '이름', align: 'center', dataIndex: 'name' },
          {
            title: '이메일',
            align: 'center',
            dataIndex: 'email',
          },
          { title: '휴대폰 번호', align: 'center', dataIndex: 'cellPhone' },
          {
            title: '직업군',
            align: 'center',
            dataIndex: 'businessTypeName',
          },
          {
            title: '회사명',
            align: 'center',
            dataIndex: 'companyName',
          },
          {
            title: '부서명',
            align: 'center',
            dataIndex: 'departmentName',
          },
          {
            title: '가입 경로',
            align: 'center',
            dataIndex: 'signUpPath',
          },
          {
            title: '가입일',
            align: 'center',
            render: (_, { registerDt }) => (
              <Typography.Text>{registerDt.slice(0, 10)}</Typography.Text>
            ),
          },
        ]}
        rowKey="memberId"
      />
    </Container>
  );
};

export default MemberList;
