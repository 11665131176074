import { Radio, RadioChangeEvent } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import BannerAdChart from 'components/stats/BannerAdChart';
import { Flex, Typography } from 'components/ui';
import { useAdCompany } from 'core/query/stats';
import { EPeriodRangeType, IPeriod } from 'type/stats';
import PageTemplate from './PageTemplate';
import { ArrowLeftOutlined } from '@ant-design/icons';
import history from 'util/history';

const filterOptions: { label: string; value: 'GRAPH' | 'RAW' }[] = [
  { label: '그래프', value: 'GRAPH' },
  { label: '로우 데이터', value: 'RAW' },
];

const BannerAdChartPage = () => {
  const params = useParams<{ companyId: string }>();
  const companyId = Number(params.companyId);
  const adCompany = useAdCompany(companyId);
  const [periodType, setPeriodType] = useState(EPeriodRangeType.ALL);
  const [period, setPeriod] = useState<IPeriod>({
    from: '2022-07-01',
    to: moment().format('YYYY-MM-DD'),
  });
  const [filterOption, setFilterOption] = useState<'GRAPH' | 'RAW'>('GRAPH');
  const handleSearchPeriod = (
    periodType: EPeriodRangeType,
    period: IPeriod,
  ) => {
    setPeriodType(periodType);
    setPeriod(period);
  };
  const refresh = () => {
    setPeriodType(EPeriodRangeType.ALL);
    setPeriod({
      from: '2022-07-01',
      to: moment().format('YYYY-MM-DD'),
    });
  };
  const handleChangeFilter = (e: RadioChangeEvent) => {
    setFilterOption(e.target.value);
    setPeriodType(EPeriodRangeType.ALL);
    setPeriod({
      from: '2022-07-01',
      to: moment().format('YYYY-MM-DD'),
    });
  };

  return (
    <PageTemplate
      back
      title={
        <Flex align="center" gap={16}>
          <ArrowLeftOutlined style={{ fontSize: 16 }} onClick={history.back} />
          <Typography.Text style={{ fontSize: 20 }} bold>
            {adCompany?.companyName} | {adCompany?.firstAdvertisingStartDate}{' '}
            (최초 광고 시작일)
          </Typography.Text>
          <Radio.Group
            style={{ height: 32 }}
            options={filterOptions}
            value={filterOption}
            onChange={handleChangeFilter}
            optionType="button"
            buttonStyle="solid"
          />
        </Flex>
      }
    >
      <BannerAdChart
        period={period}
        periodType={periodType}
        refresh={refresh}
        onSearchPeriod={handleSearchPeriod}
        filterOption={filterOption}
        bizNumber={adCompany?.bizNumber}
      />
    </PageTemplate>
  );
};

export default BannerAdChartPage;
