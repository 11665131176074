import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { Button, Col, Comment, Divider, message, Row, Typography } from 'antd';

import { useDeleteBoardComments } from 'core/query/community';
import { boardStatusMap, itemTypeMap } from 'shared/consts';
import { theme } from 'styles/theme';
import { EBoardStatus, EDeleteItemType, IBoardReply } from 'type/board';
import { EReportKind } from 'type/common';
import useDeleteItems from 'util/hooks/useDeleteItems';
const BoardReplyContainer = styled.li``;

const BoardReplyItem = styled(Comment)``;

const DeleteButton = styled(Button)`
  padding: 2px 5px;
  height: auto;
  font-size: 12px;
`;

const BoardReply = ({
  avatarTypeMap,
  item: {
    memberId,
    username,
    nickname,
    avatarType,
    reportCount,
    content,
    registerDt,
    targetMemberId,
    targetMemberNickname,
    boardCommentId,
    status,
  },
  showMemberDetail,
  showReports,
}: {
  avatarTypeMap: {
    GREEN: EmotionJSX.Element;
    BLUE: EmotionJSX.Element;
    RED: EmotionJSX.Element;
    PURPLE: EmotionJSX.Element;
    YELLOW: EmotionJSX.Element;
    BROWN: EmotionJSX.Element;
  };
  item: IBoardReply;
  showMemberDetail: (memberId: number) => void;
  showReports: ({
    id,
    reportKind,
    title,
  }: {
    id: number;
    reportKind: EReportKind;
    title: string;
  }) => void;
}) => {
  const deleteItems = useDeleteItems();
  const { deleteBoardComments } = useDeleteBoardComments();
  return (
    <BoardReplyContainer>
      <Divider />
      <BoardReplyItem
        avatar={avatarTypeMap[avatarType]}
        author={
          <Row gutter={8}>
            <Col>
              <Typography.Link onClick={() => showMemberDetail(memberId)}>
                {username} ({nickname ? nickname : '-'})
              </Typography.Link>
            </Col>
            <Col>
              <Typography.Text>
                신고수{' '}
                <Typography.Link
                  onClick={() =>
                    showReports({
                      id: boardCommentId,
                      reportKind: EReportKind.BOARD_COMMENT,
                      title: '',
                    })
                  }
                >
                  {reportCount}
                </Typography.Link>
              </Typography.Text>
            </Col>
          </Row>
        }
        content={
          <Row gutter={8}>
            {targetMemberId && (
              <Col>
                <Typography.Link
                  onClick={() => showMemberDetail(targetMemberId)}
                >{`@${targetMemberNickname}`}</Typography.Link>
              </Col>
            )}

            <Col>
              <Typography.Text
                style={
                  status === EBoardStatus.ADMIN_DELETE ||
                  status === EBoardStatus.DELETE
                    ? { textDecoration: 'line-through', whiteSpace: 'pre-line' }
                    : { whiteSpace: 'pre-line' }
                }
              >
                {content}
              </Typography.Text>
            </Col>
            {status !== EBoardStatus.NORMAL && (
              <Col>
                <Typography.Text style={{ color: theme.colors.RED50 }}>
                  {`(${boardStatusMap[status]})`}
                </Typography.Text>
              </Col>
            )}
          </Row>
        }
        datetime={registerDt?.slice(0, 10)}
      >
        {(status === EBoardStatus.NORMAL || status === EBoardStatus.BLOCK) && (
          <Row justify="end">
            <Col>
              <DeleteButton
                type="primary"
                onClick={() =>
                  deleteItems({
                    itemType: EDeleteItemType.COMMENT,
                    deleteMutate: () =>
                      deleteBoardComments([boardCommentId], {
                        onSuccess: () => {
                          message.success(
                            `${
                              itemTypeMap[EDeleteItemType.REPLY]
                            }이 삭제되었습니다.`,
                          );
                        },
                      }),
                    selectedItemIds: [boardCommentId],
                  })
                }
              >
                삭제
              </DeleteButton>
            </Col>
          </Row>
        )}
      </BoardReplyItem>
    </BoardReplyContainer>
  );
};

export default BoardReply;
