import { StarFilled, StarOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Col, Row, Typography } from 'antd';
import ReactRating from 'react-rating';

import { theme } from 'styles/theme';
import { ESessionReviewStatus, ISessionReview } from 'type/session';

const Container = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid #ddd;
`;

const SessionReview = ({
  sessionReview: {
    sessionReviewId,
    description,
    rating,
    registerDt,
    status,
    sessionApplicant,
  },
  onDelete,
}: {
  sessionReview: ISessionReview;
  onDelete: (sessionReviewId: number) => void;
}) => {
  const isDeleted = status === ESessionReviewStatus.DELETE;

  return (
    <Container>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Row>
            <Typography.Text style={{ fontSize: '14px', marginRight: 8 }}>
              {sessionApplicant.name}
            </Typography.Text>
            <Typography.Text style={{ fontSize: '14px' }}>
              {sessionApplicant.cellPhone.replace(
                /^(\d{3})(\d{4})(\d*)$/,
                '$1-$2-$3',
              )}
            </Typography.Text>
          </Row>
          <Row>
            <Col>
              <ReactRating
                readonly
                initialRating={rating}
                fullSymbol={
                  <StarFilled style={{ color: theme.colors.PRIMARY }} />
                }
                emptySymbol={
                  <StarOutlined style={{ color: theme.colors.PRIMARY }} />
                }
              />
            </Col>
            <Col>
              <Typography.Text strong style={{ marginLeft: 8 }}>
                {rating.toFixed(1)}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text
                style={{
                  color: theme.colors.GRAY70,
                  fontSize: 12,
                  marginLeft: 8,
                }}
              >
                | {registerDt}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        {!isDeleted && (
          <Col>
            <Button onClick={() => onDelete(sessionReviewId)}>삭제</Button>
          </Col>
        )}
      </Row>
      <Typography.Text
        style={{
          whiteSpace: 'pre-line',
          color: !isDeleted ? theme.colors.BLACK20 : theme.colors.GRAY70,
        }}
      >
        {!isDeleted
          ? description
          : '관리자에 의해 삭제 처리된 후기입니다. 세션 정보에는 표출되지 않습니다.'}
      </Typography.Text>
    </Container>
  );
};

export default SessionReview;
