import { Modal } from 'antd';
import { QueryClientProvider, useQueryClient } from 'react-query';
import MemberDetail from 'components/member/MemberList/MemberDetail';
import { useCallback } from 'react';

const useShowMemberDetail = () => {
  const queryClient = useQueryClient();

  const showMemberDetail = useCallback(
    (memberId: number) => {
      Modal.info({
        title: '회원 정보',
        closable: true,
        width: 620,
        content: (
          <QueryClientProvider client={queryClient}>
            <MemberDetail memberId={memberId} />
          </QueryClientProvider>
        ),
      });
    },
    [queryClient],
  );

  return showMemberDetail;
};

export default useShowMemberDetail;
