import { Table, Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';

import { useAdBannerListItems } from 'core/query/ad';
import { IAdBannerListItem } from 'type/ad';
import Switch from 'components/ui/Switch';
import AdBannerUpdateModal from './AdBannerModal';

const AdBannerList = ({
  page,
  onChangePage,
  searchValue,
}: {
  page: number;
  onChangePage: Dispatch<SetStateAction<number>>;
  searchValue: string;
}) => {
  const [
    advertisingCompanyPurchaseProductId,
    setAdvertisingCompanyPurchaseProductId,
  ] = useState<number | null>(null);
  const { adBannerListItems, isLoading, total, toggleDisplay } =
    useAdBannerListItems({
      page,
      searchValue,
    });

  const columns: ColumnsType<IAdBannerListItem> = [
    {
      title: '미리보기',
      align: 'center',
      width: '10%',
      render: (_, { fileUrl }) => (
        <img width={120} height={32.5} src={fileUrl} alt="미리보기" />
      ),
    },
    {
      title: '회사(소속)명',
      align: 'center',
      width: '34%',
      render: (_, { companyName, companyPurchaseProductId }) => (
        <Typography.Link
          underline
          onClick={() => {
            setAdvertisingCompanyPurchaseProductId(companyPurchaseProductId);
          }}
        >
          {companyName}
        </Typography.Link>
      ),
    },
    {
      title: '등록일',
      align: 'center',
      width: '14%',
      render: (_, { registerDt }) => registerDt.slice(0, 10),
    },
    {
      title: '광고 기간',
      align: 'center',
      width: '22%',
      render: (_, { startDt, endDt }) => `${startDt} ~ ${endDt}`,
    },
    {
      title: '배너 코드',
      align: 'center',
      width: '10%',
      dataIndex: 'bannerCode',
    },
    {
      title: '광고 On/Off',
      align: 'center',
      width: '10%',
      render: (_, { companyPurchaseProductId, isDisplay }) => (
        <Switch
          active={isDisplay}
          onChange={() => {
            toggleDisplay({
              companyPurchaseProductId,
              isDisplay,
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      {advertisingCompanyPurchaseProductId && (
        <AdBannerUpdateModal
          advertisingCompanyPurchaseProductId={
            advertisingCompanyPurchaseProductId
          }
          onClose={() => {
            setAdvertisingCompanyPurchaseProductId(null);
          }}
        />
      )}
      <Table
        style={{ marginTop: 16, width: '100%' }}
        loading={isLoading}
        columns={columns}
        dataSource={adBannerListItems}
        pagination={{
          current: page,
          onChange: onChangePage,
          total,
        }}
        rowKey="companyPurchaseProductId"
      />
    </>
  );
};

export default AdBannerList;
