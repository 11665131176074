import { useState } from 'react';
import { Button, Radio, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { EAdBannerFilterOption } from 'type/ad';
import SearchInput from 'components/ui/SearchInput';
import Flex from 'components/ui/Flex';
import AdBannerList from './AdBannerList';
import AdBannerCalendar from './AdBannerCalendar';
import AdBannerRegisterModal from './AdBannerModal';

const filterOptions: { label: string; value: EAdBannerFilterOption }[] = [
  { label: '리스트', value: EAdBannerFilterOption.LIST },
  { label: '캘린더', value: EAdBannerFilterOption.CALENDAR },
];

const Container = styled(Flex)``;

const AdBanner = () => {
  const [adBannerRegisterModalVisible, setAdBannerRegisterModalVisible] =
    useState(false);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterOption, setFilterOption] = useState<EAdBannerFilterOption>(
    EAdBannerFilterOption.LIST,
  );

  const handleSearch = (searchValue: string) => {
    setPage(1);
    setSearchValue(searchValue);
  };

  return (
    <Container dir="column">
      {adBannerRegisterModalVisible && (
        <AdBannerRegisterModal
          onClose={() => {
            setAdBannerRegisterModalVisible(false);
          }}
        />
      )}
      <Typography.Title style={{ fontSize: 18 }}>배너 광고</Typography.Title>
      <Flex justify="flex-end" style={{ width: '100%' }}>
        <Radio.Group
          options={filterOptions}
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value)}
          style={{ marginRight: 8 }}
          optionType="button"
          buttonStyle="solid"
        />
        {filterOption === EAdBannerFilterOption.LIST && (
          <>
            <SearchInput
              defaultValue={searchValue}
              onSearch={handleSearch}
              style={{ width: 188, marginRight: 8 }}
            />
          </>
        )}
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setAdBannerRegisterModalVisible(true);
          }}
        >
          새로운 광고 등록
        </Button>
      </Flex>
      {filterOption === EAdBannerFilterOption.LIST ? (
        <AdBannerList
          page={page}
          onChangePage={setPage}
          searchValue={searchValue}
        />
      ) : (
        <AdBannerCalendar />
      )}
    </Container>
  );
};

export default AdBanner;
