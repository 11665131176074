import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Input, message, Modal, Select } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { Flex, Typography } from 'components/ui';
import SearchInput from 'components/ui/SearchInput';
import { EBeautyFairStatus, IBeautyFairListItem } from 'type/business';
import { useBeautyFair } from 'core/query/business';
import DeletedBeautyFairModal from './DeletedBeautyFairModal';
import BeautyFairAddModal from './BeautyFairAddModal';

const Container = styled.div`
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }
`;

export enum EBeautyFairSearchType {
  TITLE = 'titleKeyword',
  REGION = 'regionName',
}

const searchTypeOptions: {
  label: string;
  value: EBeautyFairSearchType;
}[] = [
  { label: '박람회명', value: EBeautyFairSearchType.TITLE },
  { label: '지역 (국문)', value: EBeautyFairSearchType.REGION },
];

const BeautyFair = () => {
  const searchInputRef = useRef<Input>(null);
  const [deletedBeautyFairModalVisible, setDeletedBeautyFairModalVisible] =
    useState(false);
  const [beautyFairAddModalData, setBeautyFairAddModalData] = useState<{
    visible: boolean;
    beautyFairScheduleId?: number;
  }>({
    visible: false,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [searchType, setSearchType] = useState<EBeautyFairSearchType>(
    EBeautyFairSearchType.TITLE,
  );
  const [searchedType, setSearchedType] = useState<EBeautyFairSearchType>(
    EBeautyFairSearchType.TITLE,
  );
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);

  const { beautyFairListItems, changeBeautyFairDisplay, isLoading, total } =
    useBeautyFair({
      page,
      size: 10,
      status: EBeautyFairStatus.NORMAL,
      ...(searchValue && { [searchedType]: searchValue }),
    });

  const handleChangeSearchType = (searchType: EBeautyFairSearchType) => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
      searchInputRef.current.setValue('');
    }

    setSearchType(searchType);
  };

  const handleSearch = (searchValue: string) => {
    setPage(1);
    setSearchedType(searchType);
    setSearchValue(searchValue);
  };

  const handleDeleteBeautyFair = () => {
    if (selectedIds.length === 0) {
      return message.warn('삭제할 일정을 선택해주세요.');
    }

    Modal.confirm({
      content: '정말로 삭제하시겠습니까?',
      onOk: () =>
        changeBeautyFairDisplay(
          {
            beautyFairScheduleIds: selectedIds,
            isDisplay: false,
          },
          {
            onSuccess: () => {
              message.success('삭제되었습니다.');
              setSelectedIds([]);
            },
          },
        ),
    });
  };

  const columns: ColumnsType<IBeautyFairListItem> = [
    {
      title: '기간',
      width: 360,
      render: (
        _,
        {
          startDate,
          startDayOfWeek,
          endDate,
          endDayOfWeek,
          isExpectFair,
          beautyFairScheduleId,
        },
      ) => {
        const splitDate = startDate.split('-');
        const year = splitDate[0];
        const month = splitDate[1].replace(/^0(\d)$/, '$1');
        return (
          <div
            onClick={() =>
              setBeautyFairAddModalData({
                visible: true,
                beautyFairScheduleId,
              })
            }
            style={{ cursor: 'pointer' }}
          >
            <Typography.Text>
              {!startDate || (!endDate && '-')}
              {isExpectFair
                ? `${year}년 ${month}월 예정`
                : `${startDate}(${startDayOfWeek}) ~ ${endDate}(${endDayOfWeek})`}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: '박람회명',
      width: 800,
      render: (_, { title, subtitle, beautyFairScheduleId }) => (
        <div
          onClick={() =>
            setBeautyFairAddModalData({
              visible: true,
              beautyFairScheduleId,
            })
          }
          style={{ cursor: 'pointer' }}
        >
          <Typography.Text type="b2">{title}</Typography.Text>
          <Typography.Text type="small" style={{ color: '#00000073' }}>
            {subtitle}
          </Typography.Text>
        </div>
      ),
    },
    {
      title: '지역',
      width: 400,
      render: (
        _,
        { countryName, cityName, fairVenueName, beautyFairScheduleId },
      ) => (
        <div
          onClick={() =>
            setBeautyFairAddModalData({
              visible: true,
              beautyFairScheduleId,
            })
          }
          style={{ cursor: 'pointer' }}
        >
          <Typography.Text type="b2">
            {countryName} {cityName}
          </Typography.Text>
          <Typography.Text type="small" style={{ color: '#00000073' }}>
            {fairVenueName}
          </Typography.Text>
        </div>
      ),
    },
  ];

  return (
    <Container>
      {deletedBeautyFairModalVisible && (
        <DeletedBeautyFairModal
          onClose={() => {
            setDeletedBeautyFairModalVisible(false);
          }}
          columns={columns}
        />
      )}
      {beautyFairAddModalData.visible && (
        <BeautyFairAddModal
          beautyFairScheduleId={beautyFairAddModalData.beautyFairScheduleId}
          onClose={() => {
            setBeautyFairAddModalData({
              visible: false,
            });
          }}
        />
      )}
      <Typography.Text type="h3" bold>
        뷰티 박람회 일정
      </Typography.Text>
      <Flex justify="space-between" gap={8} style={{ marginTop: 12 }}>
        <Flex gap={8}>
          <Button onClick={() => setDeletedBeautyFairModalVisible(true)}>
            삭제한 일정 목록
          </Button>
          <Button icon={<DeleteOutlined />} onClick={handleDeleteBeautyFair}>
            선택 삭제
          </Button>
        </Flex>
        <Flex gap={8}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setBeautyFairAddModalData({
                visible: true,
              });
            }}
          >
            박람회 일정 추가
          </Button>
          <Select
            value={searchType}
            options={searchTypeOptions}
            onChange={handleChangeSearchType}
            style={{ width: 120 }}
          />
          <SearchInput
            inputRef={searchInputRef}
            defaultValue={searchValue}
            onSearch={handleSearch}
            placeholder="검색"
            style={{ width: 216 }}
          />
        </Flex>
      </Flex>
      <Table
        bordered
        dataSource={beautyFairListItems}
        pagination={{
          showSizeChanger: false,
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: total,
        }}
        rowSelection={{
          columnWidth: 60,
          selectedRowKeys: selectedIds,
          onChange: (keys) => {
            setSelectedIds(keys.map((key) => Number(key)));
          },
        }}
        loading={isLoading}
        columns={columns}
        rowKey="beautyFairScheduleId"
        style={{ marginTop: 24 }}
      />
    </Container>
  );
};

export default BeautyFair;
