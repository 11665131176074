import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { useState } from 'react';

import { Flex } from 'components/ui';
import { theme } from 'styles/theme';
import { ISessionBannerImage } from 'type/session';

const Container = styled.div``;

const Header = styled.div`
  height: 48px;
`;
const Body = styled(Flex)`
  padding: 0 24px 24px;
`;
const Footer = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 10px 24px 14px;
  text-align: end;
`;

const SessionBannerImageSelectOption = styled.img<{ selected: boolean }>`
  width: 144px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: 0 0 4px 1px ${theme.colors.PRIMARY};
    `}
`;

const SessionImageSelectModalContent = ({
  defaultSessionBannerImage,
  sessionBannerImages,
  onSelect,
}: {
  defaultSessionBannerImage?: ISessionBannerImage;
  sessionBannerImages: ISessionBannerImage[];
  onSelect: (sessionBannerImageId: number) => void;
}) => {
  const [selectedSessionImage, setSelectedSessionImage] = useState<
    ISessionBannerImage | undefined
  >(defaultSessionBannerImage);
  const handleClickOption = (sessionBannerImage: ISessionBannerImage) => {
    setSelectedSessionImage(sessionBannerImage);
  };
  const handleSelect = () => {
    selectedSessionImage && onSelect(selectedSessionImage.sessionBannerImageId);
  };
  return (
    <Container>
      <Header />
      <Body gap={8} wrap="true">
        {sessionBannerImages.map((sessionBannerImage) => (
          <div
            key={sessionBannerImage.sessionBannerImageId}
            style={{ flex: '0 0 144px' }}
          >
            <SessionBannerImageSelectOption
              selected={
                sessionBannerImage.sessionBannerImageId ===
                selectedSessionImage?.sessionBannerImageId
              }
              src={sessionBannerImage.imageUrl}
              alt="session image"
              onClick={() => {
                handleClickOption(sessionBannerImage);
              }}
            />
          </div>
        ))}
      </Body>
      <Footer>
        <Button
          type="primary"
          disabled={!selectedSessionImage}
          onClick={handleSelect}
        >
          선택 완료
        </Button>
      </Footer>
    </Container>
  );
};

export default SessionImageSelectModalContent;
