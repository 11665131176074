import { Modal } from 'antd';
import { useCallback } from 'react';

import ReportListModalContent from 'components/Report/ReportListModalContent';
import { QueryClientProvider, useQueryClient } from 'react-query';
import { EReportKind } from 'type/common';

const useShowReports = () => {
  const queryClient = useQueryClient();
  const showReports = useCallback(
    ({
      id,
      reportKind,
      title,
    }: {
      id: number;
      reportKind: EReportKind;
      title: string;
    }) => {
      Modal.info({
        icon: null,
        width: 560,
        content: (
          <QueryClientProvider client={queryClient}>
            <ReportListModalContent
              id={id}
              reportKind={reportKind}
              title={title}
            />
          </QueryClientProvider>
        ),
        closable: true,
      });
    },
    [queryClient],
  );

  return showReports;
};

export default useShowReports;
