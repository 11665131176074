import { message } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IBoardCommentReport, IBoardReport } from 'type/board';
import {
  EReportKind,
  IAPIPageableResponse,
  IAPIResponse,
  IReport,
} from 'type/common';
import { IMemberReport } from 'type/member';
import api from 'util/api';

export const useBoardReports = (params: {
  page: number;
  searchType: 'titleOrContent' | 'writtenUsername';
  searchKeyword: string;
}) => {
  const { page, searchType, searchKeyword } = params;
  const {
    data: { content: boardReports, totalElements } = {
      content: [],
      totalElements: 0,
    },
  } = useQuery(
    ['report', 'board', params],
    () =>
      api.get<IAPIPageableResponse<IBoardReport[]>>('/v1/board-reports', {
        params: {
          page,
          size: 10,
          [searchType]: searchKeyword,
        },
      }),
    { select: (res) => res.data.result },
  );
  return useMemo(
    () => ({ boardReports, totalElements }),
    [boardReports, totalElements],
  );
};

export const useBoardCommentReports = (params: {
  page: number;
  searchType: 'content' | 'writtenUsername';
  searchKeyword: string;
}) => {
  const { page, searchType, searchKeyword } = params;
  const {
    data: { content: boardCommentReports, totalElements } = {
      content: [],
      totalElements: 0,
    },
  } = useQuery(
    ['report', 'board-comment', params],
    () =>
      api.get<IAPIPageableResponse<IBoardCommentReport[]>>(
        '/v1/board-comment-reports',
        {
          params: {
            page,
            size: 10,
            [searchType]: searchKeyword,
          },
        },
      ),
    { select: (res) => res.data.result },
  );
  return useMemo(
    () => ({ boardCommentReports, totalElements }),
    [boardCommentReports, totalElements],
  );
};

export const useBoardMemberReports = (params: {
  page: number;
  searchKeyword: string;
}) => {
  const { page, searchKeyword } = params;
  const {
    data: { content: memberReports, totalElements } = {
      content: [],
      totalElements: 0,
    },
  } = useQuery(
    ['report', 'member', params],
    () =>
      api.get<IAPIPageableResponse<IMemberReport[]>>('/v1/member-reports', {
        params: {
          page,
          size: 10,
          username: searchKeyword,
        },
      }),
    { select: (res) => res.data.result },
  );
  return useMemo(
    () => ({ memberReports, totalElements }),
    [memberReports, totalElements],
  );
};

export const useWithdrawReport = () => {
  const queryClient = useQueryClient();
  const { mutate: withdrawReport } = useMutation(
    async ({
      reportId,
      reportKind,
    }: {
      reportId: number;
      reportKind: EReportKind;
    }) => {
      await api.post(`/v1/${reportKind}-reports/${reportId}/withdrawal`);
      return Promise.resolve(reportKind);
    },
    {
      onSuccess: (reportKind) => {
        queryClient.refetchQueries(['report', reportKind]);
        queryClient.refetchQueries(['reports', reportKind]);
        queryClient.refetchQueries(['community', 'boards']);
        queryClient.refetchQueries(['community', 'comments']);
        message.success('철회되었습니다.');
      },
    },
  );
  return withdrawReport;
};

export const useReports = ({
  id,
  reportKind,
}: {
  id: number;
  reportKind: EReportKind;
}) => {
  const { data: reports = [] } = useQuery(
    ['reports', reportKind, id],
    () => api.get<IAPIResponse<IReport[]>>(`/v1/${reportKind}s/${id}/reports`),
    { select: (res) => res.data.result },
  );
  return reports;
};
