import styled from '@emotion/styled';
import { Button, Card, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { requiredRule } from 'shared/rule';
import logo from 'assets/logo.svg';
import { useLogin } from 'core/query/auth';
import { useSetRecoilState } from 'recoil';
import { isSignupState } from 'core/store/auth';

const Container = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto 24px;
  width: 100px;
`;

const Login = () => {
  const { login, isLoading } = useLogin();
  const setIsSignup = useSetRecoilState(isSignupState);
  const { reason } = useParams<{ reason: string }>();

  const handleLogin = ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    login({ username, password });
  };

  useEffect(() => {
    if (reason === 'expire') {
      message.info('로그인 세션이 만료되었습니다.');
    }
  }, [reason]);

  return (
    <Container>
      <Logo src={logo} alt="logo" />
      <Form onFinish={handleLogin}>
        <Form.Item name="username" rules={[requiredRule]}>
          <Input placeholder="아이디" maxLength={12} />
        </Form.Item>
        <Form.Item name="password" rules={[requiredRule]}>
          <Input.Password placeholder="비밀번호" />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          size="large"
          loading={isLoading}
          style={{ marginBottom: 8 }}
        >
          로그인
        </Button>
        <Button
          type="default"
          block
          size="large"
          onClick={() => setIsSignup(true)}
        >
          회원가입
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
