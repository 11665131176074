import { DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';

import { EPeriodRangeType, IPeriod } from 'type/stats';
import { Flex, PeriodRangeSelect } from 'components/ui';
import BannerAdChartGraph from './BannerAdChartGraph';
import BannerAdChartTableList from './BannerAdChartTableList';
import { useAdStatExcel } from 'core/query/stats';

const Container = styled.div``;

const BannerAdChart = ({
  period,
  periodType,
  refresh,
  onSearchPeriod,
  filterOption,
  bizNumber,
}: {
  period: IPeriod;
  periodType: EPeriodRangeType;
  refresh: () => void;
  onSearchPeriod: (periodType: EPeriodRangeType, period: IPeriod) => void;
  filterOption: 'GRAPH' | 'RAW';
  bizNumber?: string;
}) => {
  const params = useParams<{ companyId: string }>();
  const companyId = Number(params.companyId);
  const { getAdStatExcel, isLoading } = useAdStatExcel();

  return (
    <Container>
      <Flex justify="space-between" style={{ marginTop: 16, marginBottom: 24 }}>
        <Flex gap={8}>
          <Button icon={<SyncOutlined />} onClick={refresh}>
            새로고침
          </Button>
          <Button
            icon={<DownloadOutlined />}
            loading={isLoading}
            onClick={() =>
              getAdStatExcel({
                companyId,
                advertisingType: 'BANNER',
                bizNumber,
              })
            }
          >
            누적Data 다운로드
          </Button>
        </Flex>
        <PeriodRangeSelect onSearch={onSearchPeriod} type="AD" />
      </Flex>
      {filterOption === 'GRAPH' ? (
        <BannerAdChartGraph
          companyId={companyId}
          period={period}
          periodType={periodType}
        />
      ) : (
        <BannerAdChartTableList
          companyId={companyId}
          period={period}
          periodType={periodType}
        />
      )}
    </Container>
  );
};

export default BannerAdChart;
