export interface IBusinessSupportBasic {
  title: string;
  competentMinistry: string;
  applicationPeriodKind: string;
}

export interface IBusinessSupportParams extends IBusinessSupportBasic {
  supportArea: string;
  applicationPeriodStartDate?: string | null;
  applicationPeriodEndDate?: string | null;
  businessInfoRegisterDate: string;
  url: string;
  executingOrganization: string;
  businessOverview: string;
  isDisplay: boolean;
}

export interface IBusinessSupportListItem extends IBusinessSupportBasic {
  businessSupportId: number;
  applicationPeriodStartDate: string | null;
  applicationPeriodEndDate: string | null;
  viewCount: number;
  aiScore: number | null;
  registerDt: string;
}

export interface IBusinessSupportDetail extends IBusinessSupportBasic {
  businessSupportId: number;
  supportArea: string;
  businessInfoRegisterDate: string;
  applicationPeriodStartDate: string;
  applicationPeriodEndDate: string;
  url: string;
  likeCount: number;
  viewCount: number;
  executingOrganization: string;
  businessOverview: string;
  aiScore: number | null;
  lastStatusUpdateDt: string | null;
  registerDt: string;
  isDisplay: boolean;
}

export interface IBusinessSupportSearchParams {
  page: number;
  size: number;
  isDisplay?: boolean;
  title?: string;
  competentMinistry?: string;
  aiScore?: number;
  withinDay?: string;
}

export interface IBeautyFairParams {
  isExpectFair: boolean;
  startDate: string;
  endDate: string;
  title: string;
  subtitle: string;
  beautyFairCountryId?: number;
  cityName: string;
  fairVenueName: string;
  fairLink: string;
}

export interface IBeautyFairCountryListItem {
  beautyFairCountryId: number;
  countryName: string;
}

export enum EBeautyFairStatus {
  NORMAL = 'NORMAL',
  DELETE = 'DELETE',
}

export interface IBeautyFairSearchParams {
  page: number;
  size: number;
  status?: EBeautyFairStatus;
  titleKeyword?: string;
  regionName?: string;
}

export interface IBeautyFairListItem {
  beautyFairScheduleId: number;
  startDate: string;
  endDate: string;
  subtitle: string;
  title: string;
  fairLink: string;
  countryName: string;
  cityName: string;
  fairVenueName: string;
  startDayOfWeek: string;
  endDayOfWeek: string;
  isExpectFair: boolean;
}
export interface IBeautyFairSchedulesDetail {
  beautyFairScheduleId?: number;
  startDate: string;
  endDate: string;
  title: string;
  subtitle: string;
  beautyFairCountryId: number;
  cityName: string;
  fairVenueName: string;
  fairLink: string;
  isExpectFair: boolean;
  status?: string;
  collectDataMethod?: string;
}
