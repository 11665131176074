import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, message, Row, Table, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import {
  useDeleteBoardComments,
  useEntireBoardComments,
} from 'core/query/community';
import { theme } from 'styles/theme';
import {
  EBoardStatus,
  EBoardType,
  EDeleteItemType,
  IEntireBoardOrCommentHistoryState,
} from 'type/board';
import { boardStatusMap, itemTypeMap } from 'shared/consts';
import useShowMemberDetail from 'util/hooks/useShowMemberDetail';
import useShowReports from 'util/hooks/useShowReports';
import history from 'util/history';
import useDeleteItems from 'util/hooks/useDeleteItems';
import { EReportKind } from 'type/common';

const Container = styled.div`
  table tr.deleted {
    background-color: ${theme.colors.GRAY80};
    .deleted-comment-title {
      text-decoration: line-through;
    }
  }
`;

const BoardCommentContainer = styled.div``;

const EntireBoardCommentList = () => {
  const historyState = history.location
    .state as IEntireBoardOrCommentHistoryState | null;
  const [page, setPage] = useState(historyState?.boardCommentListPage || 1);
  const [selectedBoardCommentIds, setSelectedBoardCommentIds] = useState<
    number[]
  >([]);
  const { boardComments, total } = useEntireBoardComments({ page });
  const { deleteBoardComments } = useDeleteBoardComments();
  const showMemberDetail = useShowMemberDetail();
  const showReports = useShowReports();
  const deleteItems = useDeleteItems();

  useEffect(() => {
    history.replace('/community', {
      ...historyState,
      boardCommentListPage: page,
    });
  }, [page, historyState]);

  return (
    <Container>
      <Typography.Title style={{ fontSize: 18, marginBottom: 16 }}>
        전체 댓글
      </Typography.Title>
      <Row style={{ marginBottom: 8 }} justify="end">
        <Col>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              deleteItems({
                itemType: EDeleteItemType.COMMENT,
                deleteMutate: () =>
                  deleteBoardComments(selectedBoardCommentIds, {
                    onSuccess: () => {
                      message.success(
                        `${
                          itemTypeMap[EDeleteItemType.COMMENT]
                        }이 삭제되었습니다.`,
                      );
                      if (setSelectedBoardCommentIds) {
                        setSelectedBoardCommentIds([]);
                      }
                    },
                  }),
                selectedItemIds: selectedBoardCommentIds,
              });
            }}
          >
            선택 삭제
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={boardComments}
        pagination={{
          showSizeChanger: false,
          pageSize: 10,
          current: page,
          onChange: setPage,
          total: total,
        }}
        rowSelection={{
          columnWidth: 60,
          selectedRowKeys: selectedBoardCommentIds,
          onChange: (keys) =>
            setSelectedBoardCommentIds(keys.map((key) => Number(key))),
          getCheckboxProps: ({ status }) => ({
            disabled: status !== EBoardStatus.NORMAL,
          }),
        }}
        columns={[
          {
            title: '댓글',
            align: 'left',
            render: (
              _,
              {
                boardId,
                content,
                boardTitle,
                boardCommentCount,
                boardType,
                status,
              },
            ) => (
              <BoardCommentContainer>
                <Link to={`/community/${boardType.toLowerCase()}/${boardId}`}>
                  <Row gutter={8}>
                    <Col>
                      <Typography.Text
                        className={
                          status !== EBoardStatus.NORMAL
                            ? 'deleted-comment-title'
                            : ''
                        }
                        style={{
                          wordBreak: 'break-all',
                        }}
                      >
                        {content}
                      </Typography.Text>
                    </Col>
                    {status !== EBoardStatus.NORMAL && (
                      <Col
                        style={{
                          color: theme.colors.RED50,
                        }}
                      >
                        {`(${boardStatusMap[status]})`}
                      </Col>
                    )}
                  </Row>
                  <Row gutter={8}>
                    <Col>
                      <Typography.Text style={{ color: theme.colors.GRAY70 }}>
                        {boardTitle}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text style={{ color: theme.colors.PRIMARY }}>
                        [{boardCommentCount}]
                      </Typography.Text>
                    </Col>
                  </Row>
                </Link>
              </BoardCommentContainer>
            ),
          },
          {
            title: '유저 아이디',
            align: 'center',
            render: (_, { username, memberId }) => (
              <Typography.Link onClick={() => showMemberDetail(memberId)}>
                {username}
              </Typography.Link>
            ),
            width: 120,
          },
          {
            title: '유저 닉네임',
            align: 'center',
            render: (_, { nickname, memberId, boardType, anonymNickname }) => (
              <Typography.Link onClick={() => showMemberDetail(memberId)}>
                {boardType.toLowerCase() === EBoardType.ANONYMOUS
                  ? anonymNickname
                  : nickname || '-'}
              </Typography.Link>
            ),
            width: 120,
          },
          {
            title: '날짜',
            align: 'center',
            render: (_, { registerDt }) => registerDt.slice(0, 10),
            width: 120,
          },
          {
            title: '신고수',
            align: 'center',
            render: (_, { boardCommentId, content, reportCount }) => (
              <Button
                type="link"
                size="small"
                onClick={() =>
                  showReports({
                    id: boardCommentId,
                    reportKind: EReportKind.BOARD_COMMENT,
                    title: content,
                  })
                }
              >
                {reportCount}
              </Button>
            ),
            width: 80,
          },
        ]}
        rowKey="boardCommentId"
        onRow={({ status }) => ({
          className: status !== EBoardStatus.NORMAL ? 'deleted' : '',
        })}
      />
    </Container>
  );
};

export default EntireBoardCommentList;
