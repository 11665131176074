import { Button, Col, Image, Row, Upload } from 'antd';
import { useLayoutEffect, useState } from 'react';

import { fileToBase64 } from 'util/file';

const ProfileImageInput = ({
  profileImage,
  onChange,
}: {
  profileImage?: File | string;
  onChange?: (profileImage: File) => void;
}) => {
  const [image, setImage] = useState('');
  useLayoutEffect(() => {
    if (typeof profileImage === 'string') {
      setImage(profileImage);
    } else if (profileImage instanceof File) {
      const encodeFile = async (file: File) => {
        setImage(await fileToBase64(file));
      };
      encodeFile(profileImage);
    }
  }, [profileImage]);

  return (
    <Row gutter={16}>
      <Col>
        <Upload
          accept="image/jpg, image/png"
          itemRender={() => null}
          beforeUpload={(file) => {
            onChange && onChange(file);
            return false;
          }}
        >
          <Button>사진 업로드</Button>
        </Upload>
      </Col>
      {image && (
        <Col>
          <Image src={image} alt="profile-image" width={100} />
        </Col>
      )}
    </Row>
  );
};

export default ProfileImageInput;
