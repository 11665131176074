import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import koKR from 'antd/es/locale/ko_KR';
import moment from 'moment';
import { RecoilRoot } from 'recoil';
import 'moment/locale/ko';
import { QueryClient, QueryClientProvider } from 'react-query';

// Chart
import { registerables } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import App from './App';
import reportWebVitals from './reportWebVitals';
import 'index.less';

ReactChart.register(...registerables, ChartDataLabels);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

moment.updateLocale('ko', {
  relativeTime: {
    past: '%s',
    s: '방금',
    ss: '%s방금',
    m: '방금',
    mm: '%d분',
    h: '1시간 전',
    hh: '%d시간 전',
    d: '1일 전',
    dd: '%d일 전',
    M: '1달 전',
    MM: '%d달 전',
    y: '1년 전',
    yy: '%d년 전',
  },
});

ReactDOM.render(
  <ConfigProvider locale={koKR}>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </RecoilRoot>
  </ConfigProvider>,
  document.getElementById('root'),
);

reportWebVitals();
