import styled from '@emotion/styled';
import { PageHeader } from 'antd';

import history from 'util/history';

const Container = styled.div``;

const PageTemplate = ({
  title = '',
  children,
  back = false,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  back?: boolean;
}) => {
  return (
    <Container>
      {typeof title === 'string' ? (
        <PageHeader title={title} onBack={back ? history.back : undefined} />
      ) : (
        title
      )}
      {children}
    </Container>
  );
};

export default PageTemplate;
