import moment from 'moment';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Typography,
} from 'antd';

import { EPeriodRangeType, IPeriod } from 'type/stats';
import useUpdateEffect from 'core/hook/useUpdateEffect';

const DATE_FORMAT = 'YYYY-MM-DD';

interface IProps {
  reset?: boolean;
  label?: string;
  style?: React.CSSProperties;
  onSearch: (periodRangeType: EPeriodRangeType, { from, to }: IPeriod) => void;
  type?: 'BRAND' | 'AD';
}

const PeriodRangeSelectBlock = styled(Row)``;

const periodRangeTypeOptions: { label: string; value: EPeriodRangeType }[] = [
  { label: '전체 기간', value: EPeriodRangeType.ALL },
  { label: '월 선택', value: EPeriodRangeType.MONTH },
  { label: '일 선택', value: EPeriodRangeType.DATE },
];

const PeriodRangeSelect = ({
  reset = false,
  label = '기간별',
  style,
  onSearch,
  type = 'BRAND',
}: IProps) => {
  const [periodRangeType, setPeriodRangeType] = useState(
    periodRangeTypeOptions[0].value,
  );
  const [periodFrom, setPeriodFrom] = useState(moment());
  const [periodTo, setPeriodTo] = useState(moment());
  const handleClickSearch = () => {
    switch (periodRangeType) {
      case EPeriodRangeType.ALL: {
        return onSearch(periodRangeType, {
          from: type === 'BRAND' ? '2021-07-31' : '2022-07-01',
          to: moment().format(DATE_FORMAT),
        });
      }
      default: {
        return onSearch(periodRangeType, {
          from: periodFrom.startOf(periodRangeType).format(DATE_FORMAT),
          to: periodTo.endOf(periodRangeType).format(DATE_FORMAT),
        });
      }
    }
  };

  useUpdateEffect(() => {
    setPeriodFrom(moment());
    setPeriodTo(moment());
  }, [periodRangeType]);

  useEffect(() => {
    if (reset) {
      setPeriodRangeType(periodRangeTypeOptions[0].value);
      setPeriodFrom(moment());
      setPeriodTo(moment());
    }
  }, [reset]);

  return (
    <PeriodRangeSelectBlock
      align="middle"
      justify="center"
      gutter={8}
      style={style}
    >
      <Col>
        <Typography.Text type="secondary">{label}</Typography.Text>
      </Col>
      <Col>
        <Select
          options={periodRangeTypeOptions}
          value={periodRangeType}
          style={{ width: 140 }}
          onChange={setPeriodRangeType}
        />
      </Col>
      <Col>
        <Row gutter={4} align="middle">
          <Col>
            {periodRangeType === EPeriodRangeType.ALL ? (
              <Input
                value="전체"
                disabled
                style={{ width: 140, color: '#888' }}
              />
            ) : (
              <DatePicker
                picker={periodRangeType}
                value={periodFrom}
                style={{ width: 140 }}
                onChange={(date) => {
                  if (!date) return;
                  if (periodTo.isBefore(date)) {
                    return message.warn('이전 날짜를 선택해주세요.');
                  }

                  if (type === 'BRAND') {
                    if (date.isBefore('2021-07-31'))
                      return message.warn(
                        '2021년 7월 31일부터 조회 가능합니다.',
                      );
                  } else if (type === 'AD') {
                    // HINT : 광고 서비스 개발 시작일 부터 조회
                    if (date.isBefore('2022-07-01'))
                      return message.warn(
                        '2022년 7월 1일부터 조회 가능합니다.',
                      );
                  }
                  setPeriodFrom(date);
                }}
              />
            )}
          </Col>
          <Col>
            <Typography.Text type="secondary">부터</Typography.Text>
          </Col>
          <Col>
            {periodRangeType === EPeriodRangeType.ALL ? (
              <Input
                value="전체"
                disabled
                style={{ width: 140, color: '#888' }}
              />
            ) : (
              <DatePicker
                picker={periodRangeType}
                value={periodTo}
                style={{ width: 140 }}
                onChange={(date) => {
                  if (!date) return;
                  if (periodFrom.isAfter(date)) {
                    return message.warn('이후 날짜를 선택해주세요.');
                  }

                  if (type === 'BRAND') {
                    if (date.isBefore('2021-07-31'))
                      return message.warn(
                        '2021년 7월 31일부터 조회 가능합니다.',
                      );
                  } else if (type === 'AD') {
                    // HINT : 광고 서비스 개발 시작일 부터 조회
                    if (date.isBefore('2022-07-01'))
                      return message.warn(
                        '2022년 7월 1일부터 조회 가능합니다.',
                      );
                  }
                  setPeriodTo(date);
                }}
              />
            )}
          </Col>
          <Col>
            <Typography.Text type="secondary">까지</Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col style={{ paddingLeft: 8 }}>
        <Button type="primary" onClick={handleClickSearch}>
          조회
        </Button>
      </Col>
    </PeriodRangeSelectBlock>
  );
};

export default PeriodRangeSelect;
