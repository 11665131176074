import { Route, Routes } from 'react-router-dom';

import MemberList from 'components/member/MemberList';
import MemberInquiryList from 'components/member/MemberInquiryList/MemberInquiryList';

const MemberRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MemberList />} />
      <Route path="/member-inquiry" element={<MemberInquiryList />} />
    </Routes>
  );
};

export default MemberRoutes;
