import styled from '@emotion/styled';
import { Card, Col, Row, Tabs, Typography } from 'antd';
import { useMemo, useState } from 'react';
import BoardCommentReportList from './BoardCommentReportList';

import BoardReportList from './BoardReportList';
import MemberReportList from './MemberReportList';

const Container = styled.div`
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const Report = () => {
  const [tab, setTab] = useState<'board' | 'comment' | 'user'>('board');
  const reportList = useMemo(() => {
    switch (tab) {
      case 'board':
        return <BoardReportList />;
      case 'comment':
        return <BoardCommentReportList />;
      default:
        return <MemberReportList />;
    }
  }, [tab]);
  return (
    <Container>
      <Row justify="space-between">
        <Col>
          <Typography.Title style={{ fontSize: 18, marginBottom: 12 }}>
            신고 관리
          </Typography.Title>
        </Col>
        <Col>
          <Tabs
            activeKey={tab}
            onChange={(activeKey) => setTab(activeKey as typeof tab)}
            moreIcon={null}
          >
            <Tabs.TabPane key="board" tab="글 신고" />
            <Tabs.TabPane key="comment" tab="댓글 신고" />
            <Tabs.TabPane key="user" tab="유저 신고" />
          </Tabs>
        </Col>
      </Row>
      <Card>{reportList}</Card>
    </Container>
  );
};

export default Report;
