import styled from '@emotion/styled';
import { Layout } from 'antd';
import { Router } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useEffect, useLayoutEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';

import { isLoggedInState, isSignupState } from 'core/store/auth';
import history from 'util/history';
import { NotificationSubscription } from 'util/hooks/useNotifications';
import Login from 'components/auth/Login';
import Header from 'components/layout/Header';
import Navigator from 'components/layout/Navigator';
import RootRoutes from 'components/routes/RootRoutes';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import Signup from 'components/auth/Signup';
import { theme } from 'styles/theme';
import { useReissueLogin } from 'core/query/auth';

const AppContainer = styled(Layout)`
  padding-top: 64px;
`;

const BodyContainer = styled(Layout)`
  padding-left: 200px;
`;

const Content = styled(Layout.Content)`
  position: relative;
  padding: 40px;
  min-height: calc(100vh - 64px);
`;

function App() {
  const [historyState, setHistoryState] = useState({
    action: history.action,
    location: history.location,
  });
  const { reissueLogin, isReissueLoginLoading } = useReissueLogin();

  useLayoutEffect(() => history.listen(setHistoryState), []);
  const isLoggedIn = useRecoilValue(isLoggedInState);

  const isSignup = useRecoilValue(isSignupState);
  const refreshToken = sessionStorage.getItem('app.refreshToken');

  useEffect(() => {
    if (refreshToken) {
      reissueLogin(refreshToken);
    }
  }, [refreshToken, reissueLogin]);

  if (isReissueLoginLoading) return null;

  return (
    <>
      {!isLoggedIn ? (
        <AppContainer>
          <Content>{isSignup ? <Signup /> : <Login />}</Content>
        </AppContainer>
      ) : (
        <Router
          navigator={history}
          location={historyState.location}
          navigationType={historyState.action}
        >
          <NotificationSubscription />
          <ThemeProvider theme={theme}>
            <AppContainer>
              <LoadingIndicator />
              <Header />
              <BodyContainer>
                <Navigator />
                <Content>
                  <RootRoutes />
                </Content>
              </BodyContainer>
            </AppContainer>
          </ThemeProvider>
        </Router>
      )}
    </>
  );
}

export default App;
