const path = {
  root: '/',
  login: '/login',
  signup: '/signup',
  session: {
    root: '/session',
    open: '/session/open',
    ongoing: '/session/ongoing',
    done: '/session/done',
    hostCompany: '/session/hostCompany',
  },
  ad: {
    root: '/ad',
    inquiry: '/ad/inquiry',
    ongoing: '/ad/ongoing',
    banner: '/ad/banner',
  },
  stats: {
    root: '/stats',
    bannerAd: '/stats/banner-ad',
  },
  community: {
    root: '/community',
    original: '/community/original',
    promotion: '/community/promotion',
    qna: '/community/qna',
    free: '/community/free',
    anonymous: '/community/anonymous',
  },
  business: {
    root: '/business',
    businessSupport: '/business/business-support',
    beautyFair: '/business/beauty-fair',
  },
  member: {
    root: '/member',
    memberInquiry: '/member/member-inquiry',
  },
  report: {
    root: '/report',
  },
  cs: {
    root: '/cs',
    notice: '/cs/notice',
    faq: '/cs/faq',
  },
};

export default path;
