import { Button, Col, Modal, Row, Select, Table } from 'antd';

import SearchInput from 'components/ui/SearchInput';
import {
  ESessionHostCompanySearchType,
  ISessionHostCompany,
} from 'type/session';

const SessionHostCompanySelectModal = ({
  visible,
  page,
  onChangePage,
  searchType,
  onChangeSearchType,
  searchKeyword,
  onChangeSearchKeyword,
  sessionHostCompaniesInfo: { content: sessionHostCompanies, totalElements },
  onSelect,
  onClose,
}: {
  visible: boolean;
  page: number;
  onChangePage: (page: number) => void;
  searchType: ESessionHostCompanySearchType;
  onChangeSearchType: (searchType: ESessionHostCompanySearchType) => void;
  searchKeyword: string;
  onChangeSearchKeyword: (searchKeyword: string) => void;
  sessionHostCompaniesInfo: {
    totalElements: number;
    content: ISessionHostCompany[];
  };
  onSelect: (sessionHostCompanyId: number) => void;
  onClose: () => void;
}) => {
  return (
    <Modal
      visible={visible}
      title="세션 주최 회사 선택"
      width={920}
      footer={null}
      onCancel={onClose}
    >
      <Row justify="end" gutter={8} style={{ marginBottom: 16 }}>
        <Col>
          <Select
            value={searchType}
            options={[
              {
                label: '회사명',
                value: ESessionHostCompanySearchType.COMPANY_NAME,
              },
              {
                label: '대표자',
                value: ESessionHostCompanySearchType.COMPANY_NAME,
              },
            ]}
            style={{ width: 100 }}
            onChange={onChangeSearchType}
          />
        </Col>
        <Col>
          <SearchInput
            defaultValue={searchKeyword}
            onSearch={onChangeSearchKeyword}
          />
        </Col>
      </Row>
      <Table
        size="small"
        rowKey="sessionHostCompanyId"
        dataSource={sessionHostCompanies}
        pagination={{
          current: page,
          onChange: onChangePage,
          total: totalElements,
        }}
        columns={[
          { title: '회사명', align: 'center', dataIndex: 'companyName' },
          { title: '대표자', align: 'center', dataIndex: 'ceoName' },
          {
            title: '업종',
            align: 'center',
            render: (_, { businessTypes }) =>
              `${businessTypes[0].businessTypeName} ${
                businessTypes.length > 1 ? `외 ${businessTypes.length - 1}` : ''
              }`,
          },
          {
            align: 'center',
            render: (_, { sessionHostCompanyId }) => (
              <Button
                size="small"
                type="primary"
                onClick={() => onSelect(sessionHostCompanyId)}
              >
                선택
              </Button>
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default SessionHostCompanySelectModal;
