import { ChangeEventHandler } from 'react';
import styled from '@emotion/styled';
import { Button, Form, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import parser from 'react-html-parser';

import Flex from 'components/ui/Flex';

const AdInquiryListDetailContainer = styled.div`
  padding: 24px 24px 18px 62px;
`;

const AdInquiryListDetail = ({
  description = '',
  initialMemo = '',
  memo,
  onMemoChange,
  onSubmit,
  isLoading,
}: {
  description?: string;
  memo: string;
  initialMemo?: string;
  onMemoChange: ChangeEventHandler<HTMLTextAreaElement>;
  onSubmit: () => void;
  isLoading: boolean;
}) => {
  return (
    <AdInquiryListDetailContainer>
      <Flex columnGap={16} style={{ marginBottom: 24 }}>
        <label>내용:</label>
        <p style={{ whiteSpace: 'pre-wrap' }}>{parser(description)}</p>
      </Flex>
      <Form>
        <Form.Item style={{ margin: 0 }}>
          <TextArea
            rows={6}
            placeholder="메모를 입력해 주세요."
            autoSize={{ minRows: 4, maxRows: 4 }}
            value={memo}
            onChange={onMemoChange}
            maxLength={500}
          />
        </Form.Item>
        <Flex
          dir="column"
          align="flex-end"
          rowGap={10}
          style={{ marginTop: 2 }}
        >
          <Typography.Text type="secondary">
            {memo.length} / 500
          </Typography.Text>
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={memo === initialMemo}
            loading={isLoading}
          >
            저장
          </Button>
        </Flex>
      </Form>
    </AdInquiryListDetailContainer>
  );
};

export default AdInquiryListDetail;
