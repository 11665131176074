import { Modal } from 'antd';
import styled from '@emotion/styled';

import AdBannerForm from './AdBannerForm';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-form {
    padding-top: 24px;
    & > * {
      padding: 0px 24px;
    }
  }
`;

const AdBannerModal = ({
  onClose,
  advertisingCompanyPurchaseProductId,
}: {
  onClose: () => void;
  advertisingCompanyPurchaseProductId?: number;
}) => {
  return (
    <StyledModal
      width={600}
      title={
        !advertisingCompanyPurchaseProductId
          ? '새로운 광고 등록'
          : '등록 광고 수정'
      }
      visible
      footer={null}
      onCancel={onClose}
      maskClosable={false}
    >
      <AdBannerForm
        onClose={onClose}
        advertisingCompanyPurchaseProductId={
          advertisingCompanyPurchaseProductId
        }
      />
    </StyledModal>
  );
};

export default AdBannerModal;
