import { message, Modal } from 'antd';
import { useCallback } from 'react';
import { itemTypeMap } from 'shared/consts';

import { EDeleteItemType } from 'type/board';

const useDeleteItems = () => {
  const handleDeleteItems = useCallback(
    ({
      itemType,
      deleteMutate,
      selectedItemIds,
    }: {
      itemType: EDeleteItemType;
      deleteMutate: () => void;
      selectedItemIds: number[];
    }) => {
      if (selectedItemIds.length === 0) {
        return message.warn(`삭제할 ${itemTypeMap[itemType]}을 선택해 주세요.`);
      }
      Modal.confirm({
        title: '정말로 삭제하시겠습니까?',
        icon: null,
        onOk: () => {
          deleteMutate();
        },
      });
    },
    [],
  );

  return handleDeleteItems;
};

export default useDeleteItems;
